import { coins } from 'context/coins'

function PriceTable() {
    return (
        <table style={{ width: '100%' }}>
            <tr
                style={{
                    color: 'gray',
                    fontSize: 12,
                    fontWeight: 'normal !important',
                }}
            >
                <th align="left">Name</th>
                <th align="center">Last Price</th>
                <th align="center">Last changes</th>
                <th align="center">Market Cap</th>
            </tr>
            <div style={{ margin: 10 }} />
            {Object.values(coins)?.map((coin) => {
                return (
                    <>
                        <tr
                            style={{
                                color: 'gray',
                                fontSize: 14,
                                fontWeight: 'normal',
                            }}
                        >
                            <td style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                <img style={{ width: 20, borderRadius: 100 }} src={coin?.logo} />
                                <div style={{ margin: 5 }} />
                                <span>{coin?.name}</span>
                            </td>
                            <td align="center">${coin?.price}</td>
                            <td align="center" style={{ color: 'green' }}>
                                + {(Math.random() * 10).toFixed(1)} %
                            </td>
                            <td align="center">{(Math.random() * 10).toFixed(0)}B</td>
                        </tr>
                        <div style={{ margin: 10 }} />
                    </>
                )
            })}
        </table>
    )
}

export default PriceTable
