import { motion } from 'framer-motion'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SuiBox from 'components/SuiBox'

import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'

import Convert from './components/Convert'
import TypeWriter from './components/TypeWriter'
import Pricing from 'layouts/subscription/components/Pricing'
import Subscription from 'layouts/subscription'
import { useAuth } from '../../context/auth'
import useApi from 'hooks/useApi'
import SignIn from '../authentication/sign-in'
import { Box, Button, Skeleton, Typography, useMediaQuery } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { ArrowBack, ArrowForward, Home } from '@mui/icons-material'
import BundleComponent from 'components/BundleComponent'
import { useProfile } from 'context/profile'
import { mediaUrl } from 'utils/constant'

// const slides = [
//     {
//         title: 'Discover DigiArt Forever',
//         description: 'DigiArt Forever provides monthly 3D models, assets, brushes.',
//         link: '/bundles',
//         buttonText: 'VIEW BUNDLES',
//         url: "/slide-images/slide1.png",
//         mobileUrl: "/slide-images/slide-mobile1.png",
//     },
//     {
//         title: 'October 20, 2023 - October 30, 2023',
//         description: 'Join our exclusive Halloween 3D modeling contest and win great prizes.',
//         link: '/contests',
//         buttonText: 'VIEW DETAILS',
//         url: "/slide-images/slide2.png",
//         mobileUrl: "/slide-images/slide-mobile2.png",
//     },
//     {
//         title: 'Discover new Digital Book',
//         description: 'Renderwave\nColorpedia',
//         link: '/digital-books',
//         buttonText: 'ORDER NOW',
//         url: "/slide-images/slide3.png",
//         mobileUrl: "/slide-images/slide-mobile3.png",
//     },
// ]

function WidgetLanding() {
    const [auth, setAuth] = useAuth()
    const [sliderList, getSliderList] = useApi()
    const [latestBundles, getLatestBundles] = useApi()
    const [loginModal, setLoginModal] = useState(false)

    const isDownMd = useMediaQuery('(max-width: 900px)')

    const [modelItems, setModelItems] = useState([])
    const [modelId, setModelId] = useState(null)
    const [profile, getProfileEffect] = useProfile()

    useEffect(() => {
        if (profile) {
            if (!sliderList?.data?.data) getSliderList('/slider/')
            if (!latestBundles.data?.data) getLatestBundles('/products/product-list/', {
                param: {
                    size: 3,
                    page: 1,
                }
            })
        }
    }, [profile])

    if (!auth?.accessToken) {
        return <SignIn />
    }

    const handleShowModel = (models, model_id) => {
        setModelItems(models)
        if (model_id) {
            setModelId(model_id)
        }
    }

    const slides = sliderList?.data?.access ? [] : (sliderList?.data?.data ?? []);

    const LinkComponent = ({children, url}) => {
        if(url.startsWith('http')) {
            return <a href={url} target="_blank" rel="noreferrer">{children}</a>
        }
        else {
            return <Link to={url}>{children}</Link>
        }
    }

    return (
        <WidgetLandingLayout modelItems={modelItems} setModelItems={setModelItems} model_id={modelId}>
            <Carousel
                animation='slide'
                navButtonsAlwaysVisible={true}
                NextIcon={!isDownMd && <ArrowForward />}
                PrevIcon={!isDownMd && <ArrowBack />}
                navButtonsProps={{
                    style: {
                        backgroundColor: 'transparent',
                    }
                }}
                IndicatorIcon={<svg width={isDownMd ? 60 : 100} height="8" viewBox="0 0 120 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <rect width="120" height="8" rx="4" />
                </svg>
                }
                indicatorContainerProps={{
                    style: {
                        position: "absolute",
                        bottom: "50px",
                        zIndex: 999,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '12px',
                    }
                }}
                indicatorIconButtonProps={{
                    style: {
                        color: 'white',
                        opacity: .4,
                        '&:hover': {
                            color: 'white',
                            opacity: '.6 !important',
                        },
                    }
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        color: 'white',
                        opacity: 1,
                    }
                }}
            >
                {
                    slides.map((item, i) => <Box key={i} position="relative">
                        <img src={(isDownMd ? item.mobileImage : item.desktopImage)?.replace(
                            mediaUrl,
                            'https://digiartforever.com'
                        )} width="100%" height="auto" />
                        <Box position="absolute" left={isDownMd ? "0" : "50%"} top="50%" maxWidth={isDownMd ? "100%" : "36vw"} style={{ transform: isDownMd ? "translateY(-50%)" : "translateY(-50%) translateY(30px)", padding: isDownMd ? '24px' : '0', marginBottom: isDownMd ? 60 : 0 }}>
                            <Typography style={{ color: "white", fontSize: isDownMd ? "12px" : "1.2vw", fontWeight: 500, marginBottom: isDownMd ? 16 : 0 }} >{item.pretext}</Typography>
                            <Typography style={{ color: "white", fontSize: isDownMd ? "28px" : '2.25vw', fontWeight: 700, lineHeight: '1.2' }} >{item.maintext}</Typography>
                            <LinkComponent url={item.buttonLink} children={
                                <Button style={{ background: "white", marginTop: "20px", borderRadius: "40px", color: "black", padding: "12px 40px", display: "flex", alignItems: 'center' }}>
                                    {item.buttonLabel}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "4px" }}>
                                        <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </Button>
                            }>                                
                            </LinkComponent>
                        </Box>
                    </Box>)
                }
            </Carousel>
            <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 3,
                    position: 'relative',
                    maxWidth: '1350px',
                    margin: 'auto',

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={4} marginBottom={isDownMd ? 4 : 0}>
                    <Typography fontSize={32} fontWeight={700}>
                        Latest Bundles
                    </Typography>
                    <Link to="/bundles">
                        <Button style={{ textTransform: "uppercase", color: "black" }}>
                            View all
                            <ArrowForward style={{ marginLeft: "4px" }} />
                        </Button>
                    </Link>
                </Box>
                {latestBundles.loading && [0, 1].map(i => <Skeleton variant="rectangular" width="100%" height={300} key={i} style={{ margin: "24px 0", borderRadius: "8px" }} />)}
                {!latestBundles.loading && <>
                    {latestBundles.data?.data?.map((bundle, index) =>
                        <BundleComponent key={index} bundle={bundle} is_superuser={profile?.data?.data?.is_superuser} setModelItems={handleShowModel} />
                    )}
                </>}
                <Box sx={{ backgroundImage: !isDownMd && "url('gradient-btn.png')", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "160px", marginTop: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Link to="/bundles">
                        <Button style={{ background: "transparent", color: "black", gap: "12px", padding: "12px 40px", display: "flex", alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M6.4 1H2.6C2.03995 1 1.75992 1 1.54601 1.10899C1.35785 1.20487 1.20487 1.35785 1.10899 1.54601C1 1.75992 1 2.03995 1 2.6V6.4C1 6.96005 1 7.24008 1.10899 7.45399C1.20487 7.64215 1.35785 7.79513 1.54601 7.89101C1.75992 8 2.03995 8 2.6 8H6.4C6.96005 8 7.24008 8 7.45399 7.89101C7.64215 7.79513 7.79513 7.64215 7.89101 7.45399C8 7.24008 8 6.96005 8 6.4V2.6C8 2.03995 8 1.75992 7.89101 1.54601C7.79513 1.35785 7.64215 1.20487 7.45399 1.10899C7.24008 1 6.96005 1 6.4 1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.4 1H13.6C13.0399 1 12.7599 1 12.546 1.10899C12.3578 1.20487 12.2049 1.35785 12.109 1.54601C12 1.75992 12 2.03995 12 2.6V6.4C12 6.96005 12 7.24008 12.109 7.45399C12.2049 7.64215 12.3578 7.79513 12.546 7.89101C12.7599 8 13.0399 8 13.6 8H17.4C17.9601 8 18.2401 8 18.454 7.89101C18.6422 7.79513 18.7951 7.64215 18.891 7.45399C19 7.24008 19 6.96005 19 6.4V2.6C19 2.03995 19 1.75992 18.891 1.54601C18.7951 1.35785 18.6422 1.20487 18.454 1.10899C18.2401 1 17.9601 1 17.4 1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.4 12H13.6C13.0399 12 12.7599 12 12.546 12.109C12.3578 12.2049 12.2049 12.3578 12.109 12.546C12 12.7599 12 13.0399 12 13.6V17.4C12 17.9601 12 18.2401 12.109 18.454C12.2049 18.6422 12.3578 18.7951 12.546 18.891C12.7599 19 13.0399 19 13.6 19H17.4C17.9601 19 18.2401 19 18.454 18.891C18.6422 18.7951 18.7951 18.6422 18.891 18.454C19 18.2401 19 17.9601 19 17.4V13.6C19 13.0399 19 12.7599 18.891 12.546C18.7951 12.3578 18.6422 12.2049 18.454 12.109C18.2401 12 17.9601 12 17.4 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.4 12H2.6C2.03995 12 1.75992 12 1.54601 12.109C1.35785 12.2049 1.20487 12.3578 1.10899 12.546C1 12.7599 1 13.0399 1 13.6V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H6.4C6.96005 19 7.24008 19 7.45399 18.891C7.64215 18.7951 7.79513 18.6422 7.89101 18.454C8 18.2401 8 17.9601 8 17.4V13.6C8 13.0399 8 12.7599 7.89101 12.546C7.79513 12.3578 7.64215 12.2049 7.45399 12.109C7.24008 12 6.96005 12 6.4 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <Typography fontSize="14px" fontWeight="700">VIEW ALL BUNDLES</Typography>
                        </Button>
                    </Link>
                </Box>
            </SuiBox>
        </WidgetLandingLayout>
    )
}

export default WidgetLanding
