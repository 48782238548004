import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SuiBox from 'components/SuiBox'

import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'

import useApi from 'hooks/useApi'

import { Box, Button, CircularProgress, Skeleton, useMediaQuery } from '@mui/material'
import ContestComponent from 'components/ContestComponent'

function Contests() {
    const [liveContests, getLiveContests] = useApi()
    const [pastContests, getPastContests] = useApi()

    const isDownMd = useMediaQuery('(max-width: 900px)')

    useEffect(() => {
        getLiveContests('/contests/live-contests/')
        getPastContests('/contests/past-contests/')
    }, [])

    return (
        <WidgetLandingLayout>
            <Box height="120px" />
            <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 3,
                    position: 'relative',
                    maxWidth: '1350px',
                    margin: 'auto',

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={isDownMd ? 0 : 4} marginBottom={2}>
                    <Typography fontSize={32} fontWeight={700}>
                        Live contests
                    </Typography>
                </Box>
                {liveContests.loading ?
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        <CircularProgress />
                    </Box> :
                    liveContests?.data?.data?.map((contest, index) => <ContestComponent key={index} contest={contest} />)
                }
                <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={isDownMd ? 0 : 4} marginBottom={2}>
                    <Typography fontSize={32} fontWeight={700}>
                        Past contests
                    </Typography>
                </Box>
                {pastContests.loading ?
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        <CircularProgress />
                    </Box> :
                    pastContests?.data?.data?.map((contest, index) => <ContestComponent key={index} contest={contest} />)
                }
            </SuiBox>
        </WidgetLandingLayout>
    )
}

export default Contests
