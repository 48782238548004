import Typewriter from 'typewriter-effect'

import SuiButton from '../../../components/SuiButton'
import { Link } from 'react-router-dom'

function TypeWriter() {
    const actions = [
        {
            text: 'View',
            gradient: 'linear-gradient(45deg, rgb(145	74	250	), rgb(31	239	168	))',
        },
        {
            text: 'Build',
            gradient: 'linear-gradient(45deg, rgb(31, 31, 31), rgb(145, 145, 145))',
        },
        {
            text: 'Share',
            gradient: 'linear-gradient(45deg, rgb(46, 35, 196), rgb(20, 20, 112))',
        },
        {
            text: 'Learn',
            gradient: 'linear-gradient(45deg, rgb(40	160	124	), rgb(10	122	67	))',
        },
    ]

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', color: '#212529' }}>
                {/* <div
                    style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '12px',
                    }}
                >
                    A platform for
                </div> */}
                <div style={{ margin: 5 }} />
                <div style={{ minHeight: 120, display: 'flex', alignItems: 'center' }}>
                    <Typewriter
                        options={{
                            strings: actions?.map(
                                (coin) =>
                                    `<span style="font-family: Roboto; font-weight: bold; font-size: 45px; color: black; background-image: ${coin?.gradient}; -webkit-text-fill-color: transparent; -webkit-background-clip: text;">${coin?.text}</span>`
                            ),
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </div>
                <div style={{ margin: 5 }} />
                <div style={{ fontFamily: 'Silkscreen', fontSize: 40, marginTop: -50 }}>Pixel art</div>
                <div style={{ margin: 15 }} />
                {/* <Link to="/subscription">
                    <SuiButton
                        style={{
                            width: 'max-content',
                        }}
                        variant="gradient"
                        color="primary"
                    >
                        Get started →
                    </SuiButton>
                </Link> */}
            </div>
        </>
    )
}

export default TypeWriter
