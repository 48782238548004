

import { forwardRef, useEffect, useState } from "react";


// Custom styles for the SuiBadge
import { Box, Button, CircularProgress, Modal, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import MediaComponent from "components/MediaComponent";
import MediaItem from "components/MediaItem";
import useApi from 'hooks/useApi'
import { useSnackbar } from "notistack";
import { useHistory, useLocation, Link } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js';
import getStripe from "utils/stripePromise";
import { useProfile } from "context/profile";
import { AddShoppingCartOutlined, ShoppingBagOutlined } from "@mui/icons-material";
import { useCart } from "context/cart";

const BundleComponent = forwardRef(
  ({ children, bundle, is_superuser, hideButton = false, setModelItems, refreshPackages, ...rest }, ref) => {
    const [inRepoReq, addToRepo] = useApi()
    const [addCartItemState, addCartItem] = useApi()
    const [checkoutBundleState, checkoutBundle] = useApi()
    const [confirmCheckoutState, confirmCheckout] = useApi()
    const [inRepoState, setInRepoState] = useState(bundle.in_repo)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const location = useLocation();
    const [token, setToken] = useState(null)
    let searchParams = new URLSearchParams(location.search);
    const paramToken = searchParams.get('token')
    const [profile, getProfileEffect] = useProfile()
    const [openCart, setOpenCart] = useCart()

    useEffect(() => {
      if (profile?.data?.data) {
        setInRepoState(bundle.user?.filter(user => user.email == profile?.data?.data?.email).length > 0)
      }
    }, [profile])

    useEffect(() => {
      if (paramToken && paramToken != token) {
        setToken(paramToken)
      }
      history.push({ ...location, search: '' })
    }, [paramToken])

    useEffect(() => {
      if (token) {
        confirmCheckout('/subscription/confirm-checkout/', {
          method: 'POST',
          body: {
            token: token
          }
        })
      }
    }, [token])

    useEffect(() => {
      if (confirmCheckoutState.data?.ok) {
        getProfileEffect()
      }
    }, [confirmCheckoutState])

    // useEffect(() => {
    //   if (addCartItemState.data?.ok) {
    //     window.location.href = addCartItemState.data?.data?.redirect_url
    //   }
    // }, [addCartItemState])

    const { enqueueSnackbar } = useSnackbar()
    const isDownMd = useMediaQuery('(max-width: 900px)')

    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const handleDetailModalOpen = async (bundle) => {
      if (bundle.is_coming_soon || loading) return;
      if (inRepoState || is_superuser){
       addToBundle(bundle.id);
       return setDetailModalOpen(true)
      };
      if (!bundle.is_this_monthly_product) {
        if (bundle.cart_users?.filter(user => user.email === profile?.data?.data?.email)?.length) return setOpenCart(true)
        setLoading(true)
        // checkoutBundle('/subscription/create-checout/', {
        //   method: 'POST',
        //   body: {
        //     coupon: '',
        //     is_bundle: true,
        //     price_id: bundle.product_id,
        //     callbackUrl: `${window.location.origin}${location.pathname}`,
        //   },
        // })
        addCartItem('/accounts/cart-items/', {
          method: 'POST',
          body: {
            item_id: bundle.id,
            item_type: 'product'
          }
        })
      }
      else {
        addToBundle()
      }
    }

    useEffect(() => {
      if (addCartItemState?.data?.ok) {
        setOpenCart(true)
        setLoading(false)
        enqueueSnackbar('Bundle is added to cart successfully!', {
          variant: 'success',
        })
      }
    }, [addCartItemState])

    const handleDetailModalClose = () => {
      setDetailModalOpen(false);
    }

    const addToBundle = () => {
      try {
        addToRepo(`/products/add-to-repo/${bundle.id}/`)
        enqueueSnackbar('Added successfully!', {
          variant: 'success',
        })
        setInRepoState(true)
      } catch (err) {
        enqueueSnackbar('Error adding to repo', {
          variant: 'error',
        })
      }
    }

    const goToSubscription = (e) => {
      e.preventDefault()
      e.stopPropagation()
      history.push('/subscription')
    }

    const buttonComponent = () =>
      inRepoReq.loading ? (
        <CircularProgress color="primary" size={20} />
      ) : !bundle.is_coming_soon && (inRepoState ? <Box display="flex" alignItems="center" padding="8px 15px" width="195px" border="1px solid rgba(0, 0, 0, 0.20)" borderRadius={50} fontSize={12} fontWeight={600} style={{ opacity: ".4" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24" fill="none" style={{ marginRight: "6px" }}>
          <path d="M8 12L11 15L17 9M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        YOU OWN THIS BUNDLE
      </Box> : (bundle.is_this_monthly_product || is_superuser) ? <Button style={{ display: "flex", alignItems: "center", border: "1px solid rgba(0, 0, 0, 0.20)", borderRadius: "50px", color: "#436AF5", width: "250px", padding: "8px 15px" }}>
          ADD BUNDLE YOUR PROFILE
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "6px" }}>
          <path d="M12 13V7M9 10H15M19 21V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H9.8C8.11984 3 7.27976 3 6.63803 3.32698C6.07354 3.6146 5.6146 4.07354 5.32698 4.63803C5 5.27976 5 6.11984 5 7.8V21L12 17L19 21Z" stroke="#436AF5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </Button> : (!bundle.cart_users?.filter(user => user.email === profile?.data?.data?.email)?.length ? <Button disabled={loading} style={{ display: "flex", alignItems: "center", borderRadius: "50px", background: "#436AF5", color: "white", width: "195px", padding: "8px 15px", gap: "8px" }}>
        Add to cart
        <AddShoppingCartOutlined style={{ color: 'white' }} />
      </Button> : <Button disabled={loading} style={{ display: "flex", alignItems: "center", borderRadius: "50px", background: "#436AF5", color: "white", width: "195px", padding: "8px 15px", gap: "8px" }}>
        View carts
        <ShoppingBagOutlined style={{ color: 'white' }} />
      </Button>))

    const onViewModel = (model_id) => {
      const models = bundle.other_media?.filter(media => media.asset_type.media_type === '3D MODELS')?.reduce((medias, item) => medias.concat(item?.assets), [])
      setModelItems(models, model_id)
    }

    return (
      <>
        <Box
          sx={{ boxShadow: 1 }}
          {...rest}
          ownerState={{ children, bundle }}
          ref={ref}
          style={{ backgroundColor: bundle.is_coming_soon ? "transparent" : "white", cursor: bundle.is_coming_soon ? 'default' : 'pointer' }}
          display="flex"
          flexDirection={isDownMd ? 'column' : 'row'}
          justifyContent="space-between"
          marginY={isDownMd ? 1 : 8}
          padding={3}
          paddingBottom={0}
          borderRadius="8px"
          onClick={() => handleDetailModalOpen(bundle)}
        >

          <Box>
            {bundle.is_coming_soon && <Typography fontSize="12px" fontWeight={700} color="black" style={{ background: "#43F5A0", width: "98px" }} borderRadius="40px" padding="8px 16px" marginY="16px" >UPCOMING</Typography>}
            <Typography fontSize={28} fontWeight={700}>{bundle.title}</Typography>
            <Box display="flex" marginBottom={3}>
              <Typography fontSize={12} fontWeight={500} color="#00000099" marginRight={4}>{bundle.is_coming_soon ? 'COMING' : 'RELEASED'}</Typography>
              <Typography fontSize={12} fontWeight={500} textTransform="uppercase" >{moment(bundle.release_date).format("MMMM DD, YYYY")}</Typography>
            </Box>
            {!isDownMd && !hideButton && <Box>
              {buttonComponent()}
              {
                (!bundle.is_coming_soon && !inRepoState && !is_superuser) &&
                <Button onClick={goToSubscription} disabled={loading} style={{ display: "flex", justifyContent: 'center', alignItems: "center", borderRadius: "50px", background: "#436AF5", color: "white", width: "195px", margin: '8px 0', gap: "8px" }}>
                  SUBSCRIBE
                </Button>
              }
            </Box>}
          </Box>
          <Box display="flex" justifyContent={isDownMd ? "start" : "flex-end"} flex={1} flexWrap={isDownMd ? "nowrap" : "wrap"} overflow="auto" maxWidth={isDownMd ? '100%' : 900} gap="12px" marginLeft={isDownMd ? 0 : 2} style={{ transform: isDownMd ? 'translateY(0)' : "translateY(-60px)" }}>
            {bundle.other_media?.map((media, index) => (
              <MediaComponent key={index} media={media} />
            ))}
          </Box>
          {isDownMd && <Box marginBottom={3} marginTop={5} display="flex" justifyContent="flex-end" gap="8px">
            {buttonComponent()}
            {
              (!bundle.is_coming_soon && !inRepoState && !is_superuser) &&
              <Button onClick={goToSubscription} disabled={loading} style={{ display: "flex", justifyContent: 'center', alignItems: "center", borderRadius: "50px", background: "#436AF5", color: "white", width: "195px", gap: "8px", padding: "8px 0px" }}>
                SUBSCRIBE
              </Button>
            }
          </Box>}
        </Box>
        <Modal
          open={detailModalOpen}
          onClose={handleDetailModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: isDownMd ? '30px' : '0',
            right: '0',
            width: isDownMd ? '100%' : '640px',
            bgcolor: '#EDF3FA',
            p: isDownMd ? 2 : 8,
            bottom: '0',
            overflowY: 'auto',
          }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2" fontSize={28} fontWeight={700} >
                {bundle.title}
              </Typography>
              <Button style={{ padding: 0, minWidth: "auto" }} onClick={handleDetailModalClose} >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Button>
            </Box>
            <Box display="flex" marginBottom={3}>
              <Typography fontSize={12} fontWeight={500} color="#00000099" marginRight={4}>{bundle.is_coming_soon ? 'COMING' : 'RELEASED'}</Typography>
              <Typography fontSize={12} fontWeight={500} textTransform="uppercase" >{moment(bundle.release_date).format("MMMM DD, YYYY")}</Typography>
            </Box>
            <Box marginY={6}>
              {(bundle.other_media?.reduce((medias, item) => medias.concat(item?.assets?.map(ass => {
                ass.asset_type = item.asset_type;
                return ass;
              })), [])).map((media, index) => (
                <MediaItem key={index} media={media} onViewModel={() => onViewModel(media.value)} />
              ))}
            </Box>
          </Box>
        </Modal>
      </>
    )
  }
);

export default BundleComponent;
