import { motion } from 'framer-motion'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { Typography } from '@mui/material'
import SuiBox from 'components/SuiBox'
import SuiInput from 'components/SuiInput'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
// import Button from 'components/Button'
import Button from '@mui/material/Button'
import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'
import useApi from 'hooks/useApi'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useSnackbar } from 'notistack'

import { PayPalButtons, usePayPalScriptReducer, useScriptProviderContext } from '@paypal/react-paypal-js'
import { useHistory } from 'react-router-dom'

// Logos
import paypalLogo from 'assets/images/logos/paypal.png'
import stripeLogo from 'assets/images/logos/stripe.png'

function PaymentMethod() {
    const { enqueueSnackbar } = useSnackbar()
    // get params
    const { mode, id } = useParams()
    const [coupon, setCoupon] = useState('')
    const onCoupon = (e) => {
        console.log(e.target.value)
        setCoupon(e.target.value)
    }
    // Stripe payment
    const [checkout, CreateCheckout] = useApi()
    const [subscription, CreateSubscription] = useApi()
    const [buyProduct, BuyProductReq] = useApi()
    // paypal
    const [{ isPending }] = usePayPalScriptReducer()
    const [paypalsuccess, setPaypalsuccess] = useState(false)
    const history = useHistory()

    const handleClickPayment = () => {
        if (mode == 'subscription') {
            CreateCheckout('/subscription/create-checout/', {
                method: 'POST',
                body: {
                    coupon: coupon,
                    price_id: id,
                },
            })
        } else {
            BuyProductReq('/products/buy-product/', {
                method: 'POST',
                body: {
                    coupon: coupon,
                    product_id: id,
                },
            })
        }
    }
    const handleClickPaypal = () => {
        enqueueSnackbar('Paypal is not available yet', {
            variant: 'info',
        })
    }
    useEffect(() => {
        if (checkout?.data?.data?.redirect_url) {
            window.location = checkout?.data?.data?.redirect_url
        }
    }, [checkout])
    useEffect(() => {
        if (buyProduct?.data?.data?.redirect_url) {
            window.location = buyProduct?.data?.data?.redirect_url
        }
    }, [buyProduct])

    const handleApprovePayment = async (data, actions) => {
        console.log('approved data', data)
        CreateSubscription('/subscription/create-subscription/', {
            method: 'POST',
            body: {
                subscriptionId: data.subscriptionID
            },
        })
        setPaypalsuccess(true)
      }

    return (
        <WidgetLandingLayout>
            <div style={{ padding: 10 }} />
            <Grid>
                <Card>
                    <SuiBox p={4}>
                        <SuiBox py={3} textAlign="center">
                            <SuiBox mb={1}>
                                <Container maxWidth="sm">
                                    <Typography variant="overline" color="textSecondary">
                                        Do you have coupons?
                                    </Typography>
                                    <Typography variant="h3" component="h2" gutterBottom={true}>
                                        <Typography variant="h3" component="span" color="primary">
                                            Enter your coupon code
                                        </Typography>
                                    </Typography>
                                </Container>
                                <SuiBox mt={2}>
                                    <Container
                                        maxWidth="lg"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <SuiBox mt={2} maxWidth="800" style={{ width: 800 }}>
                                            <SuiInput
                                                size="large"
                                                type="text"
                                                placeholder="Coupon code"
                                                onChange={onCoupon}
                                            />
                                        </SuiBox>
                                    </Container>
                                </SuiBox>
                            </SuiBox>
                        </SuiBox>
                        <SuiBox py={3} textAlign="center">
                            <SuiBox mb={1}>
                                <Container maxWidth="sm">
                                    <Typography variant="overline" color="textSecondary"></Typography>
                                    <Typography variant="h3" component="h2" gutterBottom={true}>
                                        <Typography variant="h3" component="span" color="primary">
                                            Choose your payment method
                                        </Typography>
                                    </Typography>
                                </Container>
                            </SuiBox>
                        </SuiBox>
                        <SuiBox mb={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    {paypalsuccess ? (
                                        <Card
                                            sx={{
                                                '&:hover': {
                                                    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                                                },
                                                color: 'white !important',
                                                backgroundColor: 'green',
                                                minHeight: 350,
                                                textAlign: 'center',
                                                alignContent: 'center',
                                                justifyContent: 'center',
                                                fontSize: 25,
                                            }}
                                        >
                                            Your transaction was successfull!
                                            <Button
                                                onClick={() => {
                                                    history.push('/packages')
                                                }}
                                            >
                                                {' '}
                                                redirect to packages
                                            </Button>
                                        </Card>
                                    ) : (
                                        <Card
                                            sx={{
                                                '&:hover': {
                                                    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                                                },
                                                // backgroundColor: 'red',
                                                minHeight: 350,
                                            }}
                                            bgcolor={paypalsuccess ? 'green' : 'white'}
                                        >
                                            <Button style={{cursor: 'initial'}}>
                                                <SuiBox
                                                    p={4}
                                                    style={{
                                                        justifyContent: 'flex',
                                                        alignItems: 'center',
                                                        margin: '0px auto',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Typography variant="body1" textAlign="center">
                                                        pay with <h3 style={{ display: 'inline' }}>Paypal</h3>
                                                    </Typography>
                                                    <SuiBox m={1} mt={8} p={2} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column',
                                                        width: '100%'
                                                    }}>
                                                        <img src={paypalLogo} width="100" alt="parypal-logo" />
                                                        {/* <PayPalButton 
                                                            amount="10.00"
                                                            currency="USD"
                                                            onSuccess={(details, data) => console.log(details, data)}
                                                        /> */}
                                                        <SuiBox style={{width: 250}} mt={4}>
                                                        {isPending ? (
                                                            <CircularProgress />
                                                        ) : (
                                                            <PayPalButtons
                                                                className="paypal-button" 
                                                                createSubscription={(data, actions) => {
                                                                    return actions.subscription
                                                                        .create({
                                                                            plan_id: 'P-6X7163984D719374JMPZEBOQ',
                                                                            redirect_urls: {
                                                                                return_url:
                                                                                    'https://example.com/return',
                                                                                cancel_url:
                                                                                    'https://example.com/cancel',
                                                                            },
                                                                        })
                                                                        .then((orderId) => {
                                                                            return orderId
                                                                        })
                                                                }}
                                                                onApprove={handleApprovePayment}
                                                                fundingSource={'paypal'}
                                                                render={(props, actions) => (
                                                                    <img src={paypalLogo} width="180" alt="parypal-logo" 
                                                                      onClick={() => actions.onClick()}
                                                                    />
                                                                  )}
                                                                style={{color:'gold', tagline: false, label: 'subscribe'}}
                                                                />
                                                        )}
                                                        </SuiBox>
                                                    </SuiBox>
                                                    {/* <SuiBox m={1} p={2}>
                                                        <img src={paypalLogo} width="180" alt="parypal-logo" />
                                                    </SuiBox> */}
                                                </SuiBox>
                                            </Button>
                                        </Card>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Card
                                        sx={{
                                            '&:hover': {
                                                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                                            },
                                            // backgroundColor: 'red',
                                            minHeight: 350,
                                        }}
                                    >
                                        <Button onClick={handleClickPayment}>
                                            {checkout.loading || buyProduct.loading ? (
                                                <SuiBox
                                                    pt={20}
                                                    style={{
                                                        justifyContent: 'flex',
                                                        alignItems: 'center',
                                                        margin: '0px auto',
                                                    }}
                                                >
                                                    <CircularProgress />
                                                </SuiBox>
                                            ) : (
                                                <SuiBox
                                                    p={4}
                                                    style={{
                                                        justifyContent: 'flex',
                                                        alignItems: 'center',
                                                        margin: '0px auto',
                                                    }}
                                                >
                                                    <Typography variant="body1" textAlign="center">
                                                        pay with <h3 style={{ display: 'inline' }}>Stripe</h3>
                                                    </Typography>
                                                    <SuiBox my={8} p={2}>
                                                        <img src={stripeLogo} width="250" alt="parypal-logo" />
                                                    </SuiBox>
                                                </SuiBox>
                                            )}
                                        </Button>
                                    </Card>
                                </Grid>                                
                            </Grid>
                        </SuiBox>
                    </SuiBox>
                </Card>
            </Grid>
        </WidgetLandingLayout>
    )
}

export default PaymentMethod
