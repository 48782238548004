

import { forwardRef, useState } from "react";


// Custom styles for the SuiBadge
import { Box, Button, CircularProgress, Modal, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import MediaComponent from "components/MediaComponent";
import MediaItem from "components/MediaItem";
import useApi from 'hooks/useApi'
import SketchFab from "components/SketchFab";
import { Link } from "react-router-dom";
import { mediaUrl } from "utils/constant";

const ContestComponent = forwardRef(
  ({ children, contest, is_superuser, ...rest }, ref) => {
    const [inRepoReq, addToRepo] = useApi()
    const [inRepoState, setInRepoState] = useState(contest?.in_repo)

    const isDownMd = useMediaQuery('(max-width: 900px)')

    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const handleDetailModalOpen = () => setDetailModalOpen(true);
    const handleDetailModalClose = () => {
      setDetailModalOpen(false);
    }

    const addToBundle = (e) => {
      e.preventDefault()
      e.stopPropagation()
      try {
        addToRepo(`/products/add-to-repo/${contest.id}/`)
        enqueueSnackbar('Added successfully!', {
          variant: 'success',
        })
        setInRepoState(true)
      } catch (err) {
        enqueueSnackbar('Error adding to repo', {
          variant: 'error',
        })
      }
    }

    return (
      <Link to={"/contest/" + contest.id}>
        <Box
          sx={{ boxShadow: 1 }}
          {...rest}
          ownerState={{ children, contest }}
          ref={ref}
          style={{
            background: `url(${contest?.link?.replace(
              mediaUrl,
              'https://digiartforever.com'
            )})`, backgroundSize: "cover", backgroundPosition: "center"
          }}
          display="flex"
          flexDirection='column'
          justifyContent="space-between"
          marginY={isDownMd ? 4 : 8}
          padding={3}
          paddingBottom={0}
          borderRadius="8px"
          onClick={handleDetailModalOpen}
        >
          <Box>
            {contest.is_coming_soon && <Typography fontSize="12px" fontWeight={700} color="white" style={{ background: "#43F5A0", width: "98px" }} borderRadius="40px" padding="8px 16px" marginY="16px">UPCOMING</Typography>}
            <Typography fontSize={isDownMd ? 21 : 25} fontWeight={700} style={{ color: "white" }}>{contest.title}</Typography>
            <Box display="flex" marginBottom={3} alignItems="center" gap={isDownMd ? "12px" : "24px"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <Box>
                <Typography fontSize={isDownMd ? 10 : 12} fontWeight={500} textTransform="uppercase" style={{ color: 'white' }} >STARTS</Typography>
                <Typography fontSize={isDownMd ? 15 : 18} fontWeight={700} style={{ color: 'white' }}>{
                  moment(contest.start_at).format("MMM DD, YYYY")
                }</Typography>
              </Box>
              <Box>
                <Typography fontSize={isDownMd ? 10 : 12} fontWeight={500} textTransform="uppercase" style={{ color: 'white' }} >ENDS</Typography>
                <Typography fontSize={isDownMd ? 15 : 18} fontWeight={700} style={{ color: 'white' }}>{
                  moment(contest.end_at).format("MMM DD, YYYY")
                }</Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection={isDownMd ? 'column' : 'row'} alignItems="end" overflow="auto" gap="12px" marginBottom="24px">
            <Box maxWidth={isDownMd ? "100%" : "calc(80% - 450px)"} marginBottom={isDownMd ? '36px' : 0}>
              <Typography fontSize={15} fontWeight={400} style={{ color: 'white' }} >{contest?.brief ?? ''}</Typography>
            </Box>
            <Box display="flex" alignItems="start" gap="12px" flexDirection={isDownMd ? 'column' : 'row'} width={isDownMd ? "100%" : "auto"}>
              {contest.prizes?.map((prize, index) => (
                <Box width={isDownMd ? "100%" : "225px"} style={{ background: 'white' }} borderRadius="8px">
                  <Box padding="12px 16px">
                    <Typography fontSize={20} fontWeight={400} style={{ color: 'black' }}>{prize.title}</Typography>
                    <Typography fontSize={10} fontWeight={700} style={{ color: '#436AF5' }}>{prize.type.title}</Typography>
                  </Box>
                  {prize.user?.[0] && <Box style={{ background: '#EDF3FA', padding: '8px 16px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
                    <Typography fontSize={12} fontWeight={700} style={{ color: 'black' }}>{prize.user?.[0]?.business_name}</Typography>
                  </Box>}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Link>
    )
  }
);

export default ContestComponent;
