import { createContext, useState, useMemo, useContext } from 'react'

const languageContext = createContext()

export const langueges = window.langueges

export const LanguageProvider = (props) => {
    const [lang, setLang] = useState()

    useMemo(() => {
        try {
            const storageLang = localStorage.getItem('language')
            setLang(langueges[storageLang] || langueges[window.config.defaultLanguage])
        } catch (e) {
            enqueueSnackbar('There was a problem in setting languages.', {
                variant: 'error',
            })
        }
    }, [])

    useMemo(() => {
        localStorage.setItem('language', lang?.name)
    }, [lang])

    return (
        <languageContext.Provider
            value={[
                lang,
                (newLang) => {
                    setLang(langueges[newLang])
                },
            ]}
            {...props}
        />
    )
}

export const useLang = () => {
    return useContext(languageContext)
}
