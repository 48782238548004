import { createContext, useEffect, useContext, } from 'react'

import useApi from 'hooks/useApi'
import { useAuth } from './auth'
import { useSnackbar } from 'notistack'

const Profile = createContext()

function ProfileProvider({ ...props }) {
    const { enqueueSnackbar } = useSnackbar()
    const [auth] = useAuth()
    const [profile, getProfile] = useApi()

    const getProfileEffect = async () => {
        if (!auth?.accessToken) return
        try {
            await getProfile('/accounts/me/')
        } catch (e) {
            // enqueueSnackbar(e?.data?.errors || 'Something went wrong in getting the profile!', {
            //     variant: 'error',
            //     del
            // })
        }
    }

    useEffect(() => {
        getProfileEffect()
    }, [auth])

    return <Profile.Provider value={[profile, getProfileEffect]} {...props} />
}

function useProfile() {
    return useContext(Profile)
}

export {
    ProfileProvider,
    useProfile
}