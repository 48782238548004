import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.css"
import { Viewer } from "./Viewer";
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Fade from '@mui/material/Fade';
import Popover from '@mui/material/Popover';
import SelectedListItem from "../ListViewer"
import Slider from "@mui/material/Slider"
// icons
import CameraIcon from '@mui/icons-material/Camera';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ExposureIcon from '@mui/icons-material/Exposure';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import TextureIcon from '@mui/icons-material/Texture';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { SketchPicker } from 'react-color';
import { light } from "@mui/material/styles/createPalette";
import { Switch, Tab, Tabs, Typography, styled } from "@mui/material";
import SuiButton from "components/SuiButton";
import { CachedOutlined } from "@mui/icons-material";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#297DF6 !important',
    '&:hover': {
      backgroundColor: '#297DF6 !important',
    },
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: '#383E45 !important',
    border: 'none !important'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#297DF6 !important',
  },
}));

export default function SketchFab({ UID, ...props }) {
  const [uid, setUid] = useState(UID);

  useEffect(() => {
    setUid(UID);
  }, [UID])

  const apiRef = useRef(null);
  var material;
  var prevLightIntensity = 3;
  var prevExposure = 1;
  var isMyNodeHidden = false;
  var myMaterials;
  var myMaterialsAreBlue = false;
  var texturesAreEnabled = true;
  var ambientShadows = true;

  var light1Matrix;
  var light2Matrix;
  var light3Matrix;
  var sliderAmplifier = 100;

  var light1HelperNode = 34;
  var light2HelperNode = 34;
  var light3HelperNode = 34;

  const [lightNumber, setLightNumber] = useState(0)
  // states
  var texturesAreEnabled = true
  const [isLightEnabled, setIsLightEnabled] = useState(false);
  const [light0, setLight0] = useState(Array(16))
  const [light1, setLight1] = useState(Array(16))
  const [light2, setLight2] = useState(Array(16))
  const [lightX, setLightX] = useState(0)
  const [lightY, setLightY] = useState(0)
  const [lightZ, setLightZ] = useState(0)
  const [objColor, setObjColor] = React.useState([0, 0, 0]);
  const [lightColor, SetLightColor] = React.useState([0, 0, 0])
  const handleLightColorChange = (color, event) => {
    console.log("Color is :", "rgb(" + [color.rgb.r, color.rgb.g, color.rgb.b].toString() + ")");
    SetLightColor("rgb(" + [color.rgb.r, color.rgb.g, color.rgb.b].toString() + ")");
    changeLightColor([color.rgb.r / 255, color.rgb.g / 255, color.rgb.b / 255, 1], lightNumber);
  };
  const handleObjColorChange = (color, event) => {
    console.log("Color is :", "rgb(" + [color.rgb.r, color.rgb.g, color.rgb.b].toString() + ")");
    setObjColor("rgb(" + [color.rgb.r, color.rgb.g, color.rgb.b].toString() + ")");
    changeObjectColor([color.rgb.r / 255, color.rgb.g / 255, color.rgb.b / 255, 1]);
  };
  const [anchorElObjColor, setAnchorElObjColor] = React.useState(null);
  const [anchorElLightColor, setAnchorElLightColor] = React.useState(null);
  const openObj = Boolean(anchorElObjColor);

  const handleClickLightColor = (event) => {
    setAnchorElLightColor(event.currentTarget);
  };
  const handleClickObjColor = (event) => {
    setAnchorElObjColor(event.currentTarget);
  };

  const handleCloseObjColor = () => {
    setAnchorElObjColor(null);
  };

  const [bgColor, setBgColor] = React.useState([0, 0, 0]);
  const [showHelpModal, setShowHelpModal] = React.useState(true);

  const handleBgColorChange = (color, event) => {
    console.log("Color is :", "rgb(" + [color.rgb.r, color.rgb.g, color.rgb.b].toString() + ")");
    setBgColor("rgb(" + [color.rgb.r, color.rgb.g, color.rgb.b].toString() + ")");
    changeBgColor([color.rgb.r / 255, color.rgb.g / 255, color.rgb.b / 255, 1]);
  };
  const [anchorElBgColor, setAnchorElBgColor] = React.useState(null);
  const openBg = Boolean(anchorElBgColor);
  const openLightColor = Boolean(anchorElLightColor);
  const handleClickBgColor = (event) => {
    setAnchorElBgColor(event.currentTarget);
  };

  const handleCloseLightColor = () => {
    setAnchorElLightColor(null);
  };
  const handleCloseBgColor = () => {
    setAnchorElBgColor(null);
  };
  var textures = [];

  const getMaterials = async () => {
    return new Promise((resolve) => {

      apiRef.current.getMaterialList(function (err, materials) {
        myMaterials = materials;
        for (var i = 0; i < myMaterials.length; i++) {
          var m = myMaterials[i];
          textures[m.name] = m.channels.AlbedoPBR.texture;
        }
        console.log("my materials:", myMaterials)
        resolve(materials)
      });

    })
  }
  const loadMaterial = async () => {
    return new Promise((resolve) => {
      apiRef.current.addEventListener('viewerready', function () {
        getMaterials().then((materials) => {
          myMaterials = materials
          resolve(myMaterials)
        })
      }
      )
    })
  }

  // sketchfab api
  var firstTime = true
  const removeTextures = () => {
    if (firstTime) {
      firstTime = false
      loadMaterial().then((myMaterials) => {
        console.log("test", myMaterials)
        for (var i = 0; i < myMaterials?.length; i++) {
          var m = myMaterials[i];
          m.channels.AlbedoPBR.enable = true;
          console.log('Material: ', m);
          if (!texturesAreEnabled) {
            console.log(textures[m.name]);
            m.channels.AlbedoPBR.texture = textures[m.name];
            m.channels.AlbedoPBR.color = false;
          }
          else {
            m.channels.AlbedoPBR.texture = false;
            m.channels.AlbedoPBR.color = [1, 1, 1, 0];
          }
          apiRef.current.setMaterial(m);
        }
        texturesAreEnabled = !texturesAreEnabled;
      })
    }
    else {
      for (var i = 0; i < myMaterials?.length; i++) {
        var m = myMaterials[i];
        m.channels.AlbedoPBR.enable = true;
        console.log('Material: ', m);
        if (!texturesAreEnabled) {
          console.log(textures[m.name]);
          m.channels.AlbedoPBR.texture = textures[m.name];
          m.channels.AlbedoPBR.color = false;
        }
        else {
          m.channels.AlbedoPBR.texture = false;
          m.channels.AlbedoPBR.color = [1, 1, 1, 0];
        }
        apiRef.current.setMaterial(m);
      }
      texturesAreEnabled = !texturesAreEnabled;
    }
  }
  const handleSetLightNumber = async (lightNumber) => {
    setLightNumber(lightNumber)
    var mat = await lightFetch(lightNumber)
    console.log("mat:", mat)
    setLightX(mat[12])
    setLightY(mat[13])
    setLightZ(mat[14])
  }
  const changeLightColor = async (color, lightNumber) => {
    var mat = await lightFetch(lightNumber)
    apiRef.current.setLight(lightNumber, {
      matrix: mat,
      enabled: true,
      color: color,
    }, function (err) {
      if (!err) {
        window.console.log('Set light');
        console.log("lightNumber: ", lightNumber)
        console.log("matrix: ", mat)
      }
      else {
        console.log('err: ', err);
      }
    });
  }
  const handleSetLight = async (e, axis) => {
    if (axis == 1) {
      setLightX(e.target.value)
    }
    else if (axis == 2) {
      setLightY(e.target.value)
    }
    else {
      setLightZ(e.target.value)
    }
    var mat = await lightFetch(lightNumber)
    console.log("old-mat: ", mat)
    if (lightNumber == 0) {
      mat[axis + 11] = e.target.value
      console.log("new-mat-x", mat)
      setLight0(mat)
      lightSet(0, mat)
    }
    if (lightNumber == 1) {
      mat[axis + 11] = e.target.value
      console.log("new-mat-y", mat)
      setLight1(mat)
      lightSet(1, mat)
    }
    if (lightNumber == 2) {
      mat[axis + 11] = e.target.value
      console.log("new-mat-z", mat)
      setLight2(mat)
      lightSet(2, mat)
    }
  }
  const lightFetch = (lightNumber) => {
    if (!apiRef.current) {
      return;
    }
    console.log("lightNumber", lightNumber)
    var lightMatrix = null
    var lightPromiseResolver;
    var lightPromise = new Promise((resolve) => {
      lightPromiseResolver = resolve
    })
    apiRef.current.getLight(lightNumber, (err, light) => {
      console.log("error is :", err);
      console.log("light is :", light);
      lightMatrix = light['matrix'];
      console.log("Light Matrix:", lightMatrix)
      if (lightNumber == 0) {
        setLight0(lightMatrix)
      }
      if (lightNumber == 1) {
        setLight1(lightMatrix)
      }
      if (lightNumber == 2) {
        setLight2(lightMatrix)
      }
      lightPromiseResolver(lightMatrix)
    })
    return lightPromise
  }

  const lightSet = (lightNumber, mat) => {
    if (!apiRef.current) {
      return;
    }
    apiRef.current.setLight(lightNumber, {
      matrix: mat,
      enabled: true,
    }, function (err) {
      if (!err) {
        window.console.log('Set light');
        console.log("lightNumber: ", lightNumber)
        console.log("matrix: ", mat)
      }
      else {
        console.log('err: ', err);
      }
    });
  }

  const changeBgColor = (color) => {
    apiRef.current.setBackground({
      color: color
    });
  };
  const getTexturesList = () => {
    apiRef.current.getTextureList((err, textures) => {
      if (!err) {
        console.log("textures: ", textures)
      }
      else {
        console.log("error: ", err)
      }
    });
  }
  const changeObjectColor = (color) => {
    apiRef.current.getMaterialList((err, materials) => {
      const plasticMaterial = materials[0]
      plasticMaterial.channels.AlbedoPBR.color = color
      apiRef.current.setMaterial(plasticMaterial, () => {
        console.log("Updated Object color");
      });
    });
  };

  const takeScreenshot = () => {
    apiRef.current.getScreenShot(3840, 2160, 'image/png', function (err, result) {
      var anchor = document.createElement('a');
      anchor.href = result;
      anchor.download = 'screenshot.png';
      anchor.innerHTML = '<img width="100" height="100" src=' + result + '>download';
      anchor.click();
    });
  }
  const increaseLightIntensity = () => {
    prevLightIntensity = prevLightIntensity * 1.5;
    console.log("Our Intensity is :", prevLightIntensity);

    if (prevLightIntensity > 0) {
      apiRef.current.setEnvironment({
        lightIntensity: prevLightIntensity,
      }, function () {
        window.console.log('Environment changed');
      });
      apiRef.current.getEnvironment(function (err, envInfo) {
        if (!err) {
          window.console.log('Current environment:', envInfo);
        }
      });
    }

    console.log("Lights On requested");
    for (var iL = 0; iL < 3; iL++) {
      apiRef.current.setLight(iL, {
        intensity: prevLightIntensity
        //color: [1,0,0]
      });
    }
  }
  const decreaseLightIntensity = () => {
    prevLightIntensity = prevLightIntensity / 1.5;
    if (prevLightIntensity > 0) {
      apiRef.current.setEnvironment({
        lightIntensity: prevLightIntensity,
      }, function () {
        window.console.log('Environment changed');
      });

      apiRef.current.getEnvironment(function (err, envInfo) {
        if (!err) {
          window.console.log('Current environment:', envInfo);
        }
      });
    }
    console.log("Lights Off requested");
    for (var iL = 0; iL < 3; iL++) {
      apiRef.current.setLight(iL, {
        intensity: prevLightIntensity
      });
    }
  }
  const increaseExposure = () => {
    prevExposure = prevExposure * 1.2;
    console.log("Our Exposure is :", prevExposure);

    if (prevExposure > 0) {
      apiRef.current.setEnvironment({
        exposure: prevExposure,
      },);
    }
  }
  const decreaseExposure = () => {
    prevExposure = prevExposure / 1.2;
    console.log("Our Exposure is :", prevExposure);

    if (prevExposure > 0) {
      apiRef.current.setEnvironment({
        exposure: prevExposure,
      },);
    }
  }

  const toggleTextures = () => {
    for (var i = 0; i < myMaterials.length; i++) {
      var m = myMaterials[i];
      m.channels.AlbedoPBR.enable = true;
      console.log('Material: ', m);
      if (!texturesAreEnabled) {
        console.log(textures[m.name]);
        m.channels.AlbedoPBR.texture = textures[m.name];
        // m.channels.AlbedoPBR.color = false; 
      }
      else {
        m.channels.AlbedoPBR.texture = false;
        m.channels.AlbedoPBR.color = [1, 1, 1, 0];
      }
      apiRef.current.setMaterial(m);
    }
    texturesAreEnabled = !texturesAreEnabled;
  }
  const ambientLight = (e) => {
    ambientShadows = e.target.checked;
    apiRef.current.setEnvironment({
      shadowEnabled: ambientShadows
    },
    );
  }

  return (
    <Box style={{ display: 'flex', width: '100%', height: '100vh', background: 'black' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, }}>
        <Viewer apiRef={apiRef} UID={uid} />
      </Box>
      <Box sx={{ width: '360px' }} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between" padding="15px 30px">
          <Typography id="modal-modal-title" variant="h6" component="h2" fontSize={28} fontWeight={700} style={{ color: "white" }} >
            Model Viewer
          </Typography>
          <Button style={{ padding: 0, minWidth: "auto" }} onClick={props.goToBack} >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Button>
        </Box>
        <Box padding="0 30px" marginBottom="12px">
          <SelectedListItem setSketchfabUID={setUid} items={props.items} value={uid} />
        </Box>
        <Grid item md={12} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: '12px 30px', display: 'flex', justifyContent: 'space-between' }}>
            <Box display="flex" style={{ color: 'white', fontSize: '14px', fontWeight: 500 }} alignItems="center" gap="12px">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15.9997 11L1.9997 11M9.9997 4L7.9997 2M13.9997 22L1.9997 22M21.9997 16C21.9997 17.1046 21.1043 18 19.9997 18C18.8951 18 17.9997 17.1046 17.9997 16C17.9997 14.8954 19.9997 13 19.9997 13C19.9997 13 21.9997 14.8954 21.9997 16ZM8.9997 3L15.8683 9.86863C16.2643 10.2646 16.4624 10.4627 16.5365 10.691C16.6018 10.8918 16.6018 11.1082 16.5365 11.309C16.4624 11.5373 16.2643 11.7354 15.8683 12.1314L11.2624 16.7373C10.4704 17.5293 10.0744 17.9253 9.61773 18.0737C9.21605 18.2042 8.78335 18.2042 8.38166 18.0737C7.92501 17.9253 7.52899 17.5293 6.73696 16.7373L3.26244 13.2627C2.4704 12.4707 2.07439 12.0747 1.92601 11.618C1.7955 11.2163 1.7955 10.7837 1.92601 10.382C2.07439 9.92531 2.47041 9.52929 3.26244 8.73726L8.9997 3Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Background color
            </Box>
            <Button variant="contained" style={{ background: bgColor, minHeight: '24px', minWidth: '24px', borderRadius: '50%', padding: 0, border: '1px solid rgba(255, 255, 255, 0.8)' }} onClick={handleClickBgColor}>
            </Button>
            <Popover
              open={openBg}
              anchorEl={anchorElBgColor}
              onClose={handleCloseBgColor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <SketchPicker color={bgColor} onChange={handleBgColorChange} />
            </Popover>
          </Grid>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: '12px 30px', display: 'flex', justifyContent: 'space-between' }}>
            <Box display="flex" style={{ color: 'white', fontSize: '14px', fontWeight: 500 }} alignItems="center" gap="12px">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M8.99997 11.2214L12.7778 14.9992M7.97485 20.9741C6.60801 22.3409 4 21.9992 2 21.9992C3.0251 19.9992 1.65827 17.3912 3.0251 16.0243C4.39194 14.6575 6.60801 14.6575 7.97485 16.0243C9.34168 17.3912 9.34168 19.6072 7.97485 20.9741ZM11.9216 15.9238L21.0587 6.05574C21.8635 5.18657 21.8375 3.83679 20.9999 2.9992C20.1624 2.1616 18.8126 2.13565 17.9434 2.94044L8.07534 12.0775C7.5654 12.5497 7.31043 12.7858 7.16173 13.0376C6.80514 13.6413 6.79079 14.3877 7.12391 15.0048C7.26283 15.2621 7.50853 15.5078 7.99995 15.9992C8.49136 16.4906 8.73707 16.7363 8.99438 16.8752C9.6114 17.2083 10.3578 17.194 10.9616 16.8374C11.2134 16.6887 11.4494 16.4337 11.9216 15.9238Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Object color
            </Box>
            <Button variant="contained" style={{ background: objColor, minHeight: '24px', minWidth: '24px', borderRadius: '50%', padding: 0, border: '1px solid rgba(255, 255, 255, 0.8)' }} onClick={handleClickObjColor}>
            </Button>
            <Popover
              open={openObj}
              anchorEl={anchorElObjColor}
              onClose={handleCloseObjColor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <SketchPicker color={objColor} onChange={handleObjColorChange} />
            </Popover>
          </Grid>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: 0, display: 'flex', justifyContent: 'space-between' }}>
            <Box display="flex" style={{ color: 'white', fontSize: '14px', fontWeight: 500, marginLeft: '30px' }} alignItems="center" gap="12px">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.5 22H14.5M15 15.3264C17.3649 14.2029 19 11.7924 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 11.7924 6.63505 14.2029 9 15.3264V16C9 16.9319 9 17.3978 9.15224 17.7654C9.35523 18.2554 9.74458 18.6448 10.2346 18.8478C10.6022 19 11.0681 19 12 19C12.9319 19 13.3978 19 13.7654 18.8478C14.2554 18.6448 14.6448 18.2554 14.8478 17.7654C15 17.3978 15 16.9319 15 16V15.3264Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Global lighting
            </Box>
            <Box display="flex">
              <Button variant="contained" style={{ background: 'transparent', minHeight: '48px', minWidth: '48px', padding: 0, borderLeft: '1px solid rgba(255, 255, 255, 0.08)' }} onClick={decreaseLightIntensity}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                  <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Button>
              <Button variant="contained" style={{ background: 'transparent', minHeight: '48px', minWidth: '48px', padding: 0, borderLeft: '1px solid rgba(255, 255, 255, 0.08)' }} onClick={increaseLightIntensity}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                  <path d="M12 5V19M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Button>
            </Box>
          </Grid>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: 0, display: 'flex', justifyContent: 'space-between' }}>
            <Box display="flex" style={{ color: 'white', fontSize: '14px', fontWeight: 500, marginLeft: '30px' }} alignItems="center" gap="12px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_370_2836)">
                  <path d="M14 16H20V18H14V16Z" fill="white" />
                  <path d="M22 0H2C1.46976 0.000628541 0.961417 0.211544 0.586481 0.586481C0.211544 0.961417 0.000628541 1.46976 0 2L0 22C0.000628541 22.5302 0.211544 23.0386 0.586481 23.4135C0.961417 23.7885 1.46976 23.9994 2 24H22C22.5302 23.9994 23.0386 23.7885 23.4135 23.4135C23.7885 23.0386 23.9994 22.5302 24 22V2C23.9994 1.46976 23.7885 0.961417 23.4135 0.586481C23.0386 0.211544 22.5302 0.000628541 22 0ZM4 6H6V4H8V6H10V8H8V10H6V8H4V6ZM22 22H2L22 2V22Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_370_2836">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Exposure
            </Box>
            <Box display="flex">
              <Button variant="contained" style={{ background: 'transparent', minHeight: '48px', minWidth: '48px', padding: 0, borderLeft: '1px solid rgba(255, 255, 255, 0.08)' }} onClick={decreaseExposure}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Button>
              <Button variant="contained" style={{ background: 'transparent', minHeight: '48px', minWidth: '48px', padding: 0, borderLeft: '1px solid rgba(255, 255, 255, 0.08)' }} onClick={increaseExposure}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path d="M12 5V19M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Button>
            </Box>
          </Grid>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: '12px 30px', display: 'flex', justifyContent: 'space-between' }}>
            <Box display="flex" style={{ color: 'white', fontSize: '14px', fontWeight: 500 }} alignItems="center" gap="12px">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 8.5C16 12.6421 12.6421 16 8.5 16C7.88534 16 7.28795 15.9261 6.71623 15.7866C7.89585 17.4297 9.82294 18.5 12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 9.82294 17.4297 7.89585 15.7866 6.71623C15.9261 7.28795 16 7.88534 16 8.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Shadows
            </Box>
            <CustomSwitch label="" value={ambientShadows} onChange={ambientLight} />
          </Grid>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: '12px 30px', display: 'flex', justifyContent: 'space-between' }}>
            <Box display="flex" style={{ color: 'white', fontSize: '14px', fontWeight: 500 }} alignItems="center" gap="12px">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 20.4722C13.0615 21.4223 14.4633 22 16 22C19.3137 22 22 19.3137 22 16C22 13.2331 20.1271 10.9036 17.5798 10.2102M6.42018 10.2102C3.87293 10.9036 2 13.2331 2 16C2 19.3137 4.68629 22 8 22C11.3137 22 14 19.3137 14 16C14 15.2195 13.851 14.4738 13.5798 13.7898M18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Model materials
            </Box>
            <Button variant="contained" style={{ background: '#297DF6', minHeight: '24px', minWidth: '24px', borderRadius: '50%', padding: 0, border: '1px solid rgba(255, 255, 255, 0.08)' }} onClick={removeTextures}>
              <CachedOutlined color="white" />
            </Button>
          </Grid>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: '8px 30px' }}>
            <Tabs value={lightNumber} sx={{
              background: 'transparent',
              '& .MuiButtonBase-root.MuiTab-root': {
                color: 'white !important',
                borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
                borderRadius: '0 !important',
              },
              '& .MuiTabs-indicator': {
                background: 'transparent !important',
                borderBottom: '1px solid #C24E4E',
                borderRadius: '0 !important',
                boxShadow: 'none !important'
              }
            }}>
              <Tab value={0} label="Light A" id='simple-tab-0' onClick={() => handleSetLightNumber(0)} />
              <Tab value={1} label="Light B" id='simple-tab-1' onClick={() => handleSetLightNumber(1)} />
              <Tab value={2} label="Light C" id='simple-tab-2' onClick={() => handleSetLightNumber(2)} />
            </Tabs>
            <Grid item style={{ padding: '12px 0', display: 'flex', justifyContent: 'space-between' }}>
              <Box display="flex" style={{ color: 'white', fontSize: '14px', fontWeight: 500 }} alignItems="center" gap="12px">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M15.9997 11L1.9997 11M9.9997 4L7.9997 2M13.9997 22L1.9997 22M21.9997 16C21.9997 17.1046 21.1043 18 19.9997 18C18.8951 18 17.9997 17.1046 17.9997 16C17.9997 14.8954 19.9997 13 19.9997 13C19.9997 13 21.9997 14.8954 21.9997 16ZM8.9997 3L15.8683 9.86863C16.2643 10.2646 16.4624 10.4627 16.5365 10.691C16.6018 10.8918 16.6018 11.1082 16.5365 11.309C16.4624 11.5373 16.2643 11.7354 15.8683 12.1314L11.2624 16.7373C10.4704 17.5293 10.0744 17.9253 9.61773 18.0737C9.21605 18.2042 8.78335 18.2042 8.38166 18.0737C7.92501 17.9253 7.52899 17.5293 6.73696 16.7373L3.26244 13.2627C2.4704 12.4707 2.07439 12.0747 1.92601 11.618C1.7955 11.2163 1.7955 10.7837 1.92601 10.382C2.07439 9.92531 2.47041 9.52929 3.26244 8.73726L8.9997 3Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Light {lightNumber === 0 ? 'A' : (lightNumber === 1 ? 'B' : 'C')} color
              </Box>
              <Button variant="contained" style={{ background: lightColor, minHeight: '24px', minWidth: '24px', borderRadius: '50%', padding: 0, border: '1px solid rgba(255, 255, 255, 0.8)' }} onClick={handleClickLightColor}>
              </Button>
              <Popover
                open={openLightColor}
                anchorEl={anchorElLightColor}
                onClose={handleCloseLightColor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <SketchPicker color={lightColor} onChange={handleLightColorChange} />
              </Popover>
            </Grid>
            <Box display="flex" gap="6px" alignItems="center">
              <Typography style={{ color: "white", marginTop: '4px' }} fontSize="12px">
                X
              </Typography>
              <Slider
                min={-500}
                max={500}
                marks={[{ value: 0, label: 0 }]}
                size="small"
                defaultValue={lightX}
                value={lightX}
                onChange={(e) => handleSetLight(e, 1)}
                aria-label="Small"
                valueLabelDisplay="auto"
              />
            </Box>
            <Box display="flex" gap="6px" alignItems="center">
              <Typography style={{ color: "white", marginTop: '4px' }} fontSize="12px">
                Y
              </Typography>
              <Slider
                min={-500}
                max={500}
                marks={[{ value: 0, label: 0 }]}
                size="small"
                defaultValue={lightY}
                value={lightY}
                onChange={(e) => handleSetLight(e, 2)}
                aria-label="Small"
                valueLabelDisplay="auto"
              />
            </Box>
            <Box display="flex" gap="6px" alignItems="center">
              <Typography style={{ color: "white", marginTop: '4px' }} fontSize="12px">
                Z
              </Typography>
              <Slider
                min={-500}
                max={500}
                marks={[{ value: 0, label: 0 }]}
                size="small"
                defaultValue={lightZ}
                value={lightZ}
                onChange={(e) => handleSetLight(e, 3)}
                aria-label="Small"
                valueLabelDisplay="auto"
              />
            </Box>
          </Grid>
          <Grid item style={{ borderTop: '1px solid rgba(255, 255, 255, 0.08)', padding: '12px 30px', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'end' }}>
            <Button variant="contained" style={{ border: '4px', width: '100%', color: 'white', background: '#383E45', display: 'flex', justifyContent: 'start', gap: '6px', alignItems: 'center' }} onClick={takeScreenshot}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M22 11.5V14.6C22 16.8402 22 17.9603 21.564 18.816C21.1805 19.5686 20.5686 20.1805 19.816 20.564C18.9603 21 17.8402 21 15.6 21H8.4C6.15979 21 5.03969 21 4.18404 20.564C3.43139 20.1805 2.81947 19.5686 2.43597 18.816C2 17.9603 2 16.8402 2 14.6V9.4C2 7.15979 2 6.03969 2.43597 5.18404C2.81947 4.43139 3.43139 3.81947 4.18404 3.43597C5.03969 3 6.15979 3 8.4 3H12.5M19 8V2M16 5H22M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Take Screenshot
            </Button>
            <Box display="flex" alignItems="center" gap="8px" style={{ color: 'white', fontSize: '14px', cursor: 'pointer' }} my={2} onClick={() => setShowHelpModal(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              How to use the Model Viewer?
            </Box>
          </Grid>
        </Grid>
      </Box>
      {showHelpModal && <Box position="fixed" zIndex={100000} top={0} bottom={0} left={0} right={0} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ background: 'rgba(0, 0, 0, 0.80)', backdropFilter: 'blur(4px)' }}>
        <Typography style={{ color: 'white', marginBottom: '60px', fontSize: '24px', fontWeight: 500 }}>
          How to use the Model Viewer?
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="start" gap="80px" style={{ color: 'white' }}>
          <Box display="flex" flexDirection="column" alignItems="center" maxWidth="200px">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
              <circle cx="60" cy="60" r="59" stroke="url(#paint0_linear_1_4238)" stroke-width="2" />
              <path d="M49 40.9474L82 60L49 79.0526L49 40.9474Z" stroke="url(#paint1_linear_1_4238)" stroke-width="2" />
              <defs>
                <linearGradient id="paint0_linear_1_4238" x1="0" y1="0" x2="120" y2="120" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_4238" x1="84" y1="79.875" x2="39.75" y2="36" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
            <Typography style={{ marginTop: '24px', fontWeight: 500 }} align="center">
              ENGAGE
            </Typography>
            <Typography style={{ marginTop: '32px', fontSize: '14px' }} align="center">
              Start interacting <br /> by pressing a play icon
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" maxWidth="200px">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
              <path d="M119 60C119 61.4992 117.76 63.2138 114.776 64.9778C111.859 66.7022 107.57 68.2898 102.184 69.6365C91.4261 72.3259 76.5114 74 60 74C43.4886 74 28.5739 72.3259 17.8161 69.6365C12.4296 68.2898 8.14138 66.7022 5.22393 64.9778C2.23953 63.2138 1 61.4992 1 60C1 58.5008 2.23953 56.7862 5.22393 55.0222C8.14138 53.2978 12.4296 51.7102 17.8161 50.3635C28.5739 47.6741 43.4886 46 60 46C76.5114 46 91.4261 47.6741 102.184 50.3635C107.57 51.7102 111.859 53.2978 114.776 55.0222C117.76 56.7862 119 58.5008 119 60Z" stroke="url(#paint0_linear_1_4237)" stroke-width="2" />
              <circle cx="60" cy="60" r="59" stroke="url(#paint1_linear_1_4237)" stroke-width="2" />
              <path d="M60 119C61.4992 119 63.2138 117.76 64.9778 114.776C66.7022 111.859 68.2898 107.57 69.6365 102.184C72.3259 91.4261 74 76.5114 74 60C74 43.4886 72.3259 28.5739 69.6365 17.8161C68.2898 12.4296 66.7022 8.14138 64.9778 5.22393C63.2138 2.23953 61.4992 0.999999 60 0.999999C58.5008 0.999999 56.7862 2.23953 55.0222 5.22393C53.2978 8.14138 51.7102 12.4296 50.3635 17.8161C47.6741 28.5739 46 43.4886 46 60C46 76.5114 47.6741 91.4261 50.3635 102.184C51.7102 107.57 53.2978 111.859 55.0222 114.776C56.7862 117.76 58.5008 119 60 119Z" stroke="url(#paint2_linear_1_4237)" stroke-width="2" />
              <defs>
                <linearGradient id="paint0_linear_1_4237" x1="60" y1="45" x2="60" y2="75" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_4237" x1="0" y1="0" x2="120" y2="120" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_4237" x1="45" y1="60" x2="75" y2="60" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
              </defs>
            </svg>
            <Typography style={{ marginTop: '24px', fontWeight: 500 }} align="center">
              ORBIT AROUND
            </Typography>
            <Typography style={{ marginTop: '32px', fontSize: '14px' }} align="center">
              Left click + drag or <br /> One finger drag (touch)
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" maxWidth="200px">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
              <circle cx="60" cy="60" r="59" stroke="url(#paint0_linear_1_4236)" stroke-width="2" stroke-dasharray="4 4" />
              <circle cx="60" cy="60" r="15" stroke="url(#paint1_linear_1_4236)" stroke-width="2" />
              <path d="M92 93C92.5523 93 93 92.5523 93 92L93 83C93 82.4477 92.5523 82 92 82C91.4477 82 91 82.4477 91 83L91 91L83 91C82.4477 91 82 91.4477 82 92C82 92.5523 82.4477 93 83 93L92 93ZM75.2929 76.7071L91.2929 92.7071L92.7071 91.2929L76.7071 75.2929L75.2929 76.7071Z" fill="white" />
              <path d="M27 26C26.4477 26 26 26.4477 26 27L26 36C26 36.5523 26.4477 37 27 37C27.5523 37 28 36.5523 28 36L28 28L36 28C36.5523 28 37 27.5523 37 27C37 26.4477 36.5523 26 36 26L27 26ZM44.7071 43.2929L27.7071 26.2929L26.2929 27.7071L43.2929 44.7071L44.7071 43.2929Z" fill="white" />
              <defs>
                <linearGradient id="paint0_linear_1_4236" x1="0" y1="0" x2="120" y2="120" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_4236" x1="44" y1="44" x2="76" y2="76" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
              </defs>
            </svg>
            <Typography style={{ marginTop: '24px', fontWeight: 500 }} align="center">
              ZOOM IN/OUT
            </Typography>
            <Typography style={{ marginTop: '32px', fontSize: '14px' }} align="center">
              Double click on model or <br /> scroll anywhere or <br /> Pinch (touch) drag (touch)
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" maxWidth="200px">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
              <path d="M1 9V1H9" stroke="white" stroke-width="2" />
              <path d="M111 1L119 1L119 9" stroke="white" stroke-width="2" />
              <path d="M1 111V119H9" stroke="white" stroke-width="2" />
              <path d="M111 119L119 119L119 111" stroke="white" stroke-width="2" />
              <circle cx="95" cy="60" r="15" stroke="url(#paint0_linear_1_4247)" stroke-width="2" />
              <path d="M0.999999 44.5C3.03549 44.5 5.05105 44.9009 6.93159 45.6799C8.81214 46.4588 10.5208 47.6005 11.9602 49.0398C13.3995 50.4792 14.5412 52.1879 15.3201 54.0684C16.0991 55.949 16.5 57.9645 16.5 60C16.5 62.0355 16.0991 64.051 15.3201 65.9316C14.5412 67.8121 13.3995 69.5208 11.9602 70.9602C10.5208 72.3995 8.81214 73.5412 6.93159 74.3201C5.05105 75.0991 3.03549 75.5 0.999999 75.5" stroke="url(#paint1_linear_1_4247)" stroke-dasharray="4 4" />
              <path d="M35.2933 59.2929C34.9027 59.6834 34.9027 60.3166 35.2933 60.7071L41.6572 67.0711C42.0477 67.4616 42.6809 67.4616 43.0714 67.0711C43.462 66.6805 43.462 66.0474 43.0714 65.6569L37.4146 60L43.0714 54.3431C43.462 53.9526 43.462 53.3195 43.0714 52.9289C42.6809 52.5384 42.0477 52.5384 41.6572 52.9289L35.2933 59.2929ZM60.042 59L36.0004 59L36.0004 61L60.042 61L60.042 59Z" fill="white" />
              <defs>
                <linearGradient id="paint0_linear_1_4247" x1="79" y1="44" x2="111" y2="76" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_4247" x1="17" y1="44" x2="-15" y2="76" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
              </defs>
            </svg>
            <Typography style={{ marginTop: '24px', fontWeight: 500 }} align="center">
              PAN
            </Typography>
            <Typography style={{ marginTop: '32px', fontSize: '14px' }} align="center">
              Right click + drag or <br /> Two fingers drag (touch)
            </Typography>
          </Box>
        </Box>
        <Button onClick={() => setShowHelpModal(false)} variant="contained" style={{ background: 'white', borderRadius: '50px', marginTop: '50px', display: 'flex', alignItems: 'center', gap: '4px', fontSize: '15px', padding: '16px 38px' }}>
          OK, LET'S GO!
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24" fill="none">
            <path d="M5.5 12H19.5M19.5 12L12.5 5M19.5 12L12.5 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </Button>
      </Box>}
    </Box>
  );
}