import { useState } from 'react'

import { useAuth } from 'context/auth'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { prefixUrl } from 'utils/constant'

export const req = async (url, options) => {
    try {
        const response = await fetch(url, options)
        let data

        if (options.blob) {
            data = await response?.blob()
            return data
        }

        data = await response?.text()
        try {
            data = JSON.parse(data)
        } catch (e) { }

        if (!response.ok) {
            throw {
                server: true,
                data: data || null,
                url,
                code: response.status,
            }
        }

        // note: is response code required for success data
        return data
    } catch (e) {
        if (e.server) {
            throw e
        } else {
            throw {
                server: false,
                data: e,
                url,
            }
        }
    }
}

// request context for default headers and bodies or it can a callback set for all reqs or only a hook

const useReq = (opt) => {
    const [auth, setAuth] = useAuth()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()

    let defaultResponse = opt?.defaultResponse

    const [state, setState] = useState({
        loading: false,
        error: false,
        data: defaultResponse,
    })

    // note: add retry in general as well
    const mReq = async (url, options = {}, onData = null, authRetry = 0) => {
        if (authRetry > 1) {
            setAuth({})
            setReqWindow({})
            // localStorage.removeItem('auth', null)
            // sessionStorage.removeItem('auth', null)
            history.push('/')
            return
        }

        setState((state) => {
            if (onData) {
                return { ...state }
            } else {
                return {
                    loading: true,
                    error: false,
                    data: defaultResponse,
                }
            }
        })

        try {
            if (!url?.includes?.('http')) {
                url = prefixUrl + url
            }

            if (options.proxy) {
                url = prefixUrl + url
            }

            if (options.params) {
                url = url + '?' + new URLSearchParams(options.params)?.toString()
            }

            if (typeof options.body == 'object' && !(options.body instanceof FormData)) {
                options.body = JSON.stringify(options.body)
                options.headers = {
                    'Content-Type': 'application/json',
                    ...options.headers,
                }
            }

            options.headers = {
                ...window?.req?.headers,
                ...options.headers,
            }

            const data = await req(url, options)

            setState({
                loading: false,
                error: false,
                data: typeof onData == 'function' ? onData(data) : data,
            })

            return data
        } catch (e) {
            // refresh token logic
            // note: move this to the context level with the pre- and post hooks
            if (e.code == 401 && (!url.includes('/accounts/') || url.includes('/accounts/cart-items'))) {
                // return
                try {
                    const refreshToken = await mReq(
                        '/accounts/refresh/',
                        {
                            method: 'POST',
                            body: {
                                refresh: auth?.refreshToken,
                            },
                            headers: {
                                // Authorization: null,
                            },
                        },
                        null,
                        authRetry + 1
                    )
                    if (refreshToken?.access) {
                        // note: merge these two also with some logic in the context layer
                        setAuth({
                            ...auth,
                            accessToken: refreshToken?.access,
                        })
                        setReqWindow({
                            headers: {
                                Authorization: `Bearer ${refreshToken?.access}`,
                            },
                        })
                        delete options?.headers?.Authorization
                        return mReq(url, options, onData, authRetry + 1)
                    }
                }
                catch (err) { }
                setAuth({})
                setReqWindow({})
                if(url === '/me') {
                    enqueueSnackbar('Token is expired', {
                        variant: 'error',
                    })
                }
                history.push('/')
                return
            } else if (url.includes('/auth/')) {
                if (e.data.errors === 'Token is invalid or expired') {
                    setAuth({})
                    setReqWindow({})
                    // localStorage.removeItem('auth', null)
                    // sessionStorage.removeItem('auth', null)
                    history.push('/')
                    return
                }
            }

            setState({
                loading: false,
                error: true,
                data: e?.data,
                code: e.code,
            })
            throw e
        }
    }

    return [state, mReq]
}

export const setReqWindow = (options = {}) => {
    if (!window.req) window.req = {}
    window.req.proxy = options?.proxy || window.req.proxy
    window.req.prefix = options?.prefix || window.req.prefix
    window.req.headers = options?.headers || window.req.headers
    window.req.media = options?.media || window.req.media
    window.req.backend = options?.backend || window.req.backend
}

export default useReq
