import { Grid, Card } from '@mui/material'

import SuiBox from 'components/SuiBox'
import { Typography } from '@mui/material'
import Lottie from 'lottie-react'

import Logo from 'examples/Logo'

import colorAnimation from 'assets/color.json'
import useMediaQuery from '@mui/material/useMediaQuery'
import pixelArt1 from 'assets/images/gallery/img (1).jpg'
import pixelArt2 from 'assets/images/gallery/img (2).jpg'
import pixelArt3 from 'assets/images/gallery/img (3).jpg'
import pixelArt4 from 'assets/images/gallery/img (4).jpg'
import pixelArt5 from 'assets/images/gallery/img (5).jpg'
import pixelArt6 from 'assets/images/gallery/img (6).jpg'
import pixelArt7 from 'assets/images/gallery/img (7).jpg'
import pixelArt8 from 'assets/images/gallery/img (8).jpg'
import pixelArt9 from 'assets/images/gallery/img (9).jpg'
import pixelArt10 from 'assets/images/gallery/img (10).jpg'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Box from '@mui/material/Box'
function MasonryImageList() {
    const isMobile = window.innerWidth < 1200

    return (
        <Box sx={{ overflowY: 'scroll' }}>
            <ImageList variant="masonry" cols={isMobile ? 2 : 4} gap={10}>
                {masonryItemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            src={item}
                            srcSet={`${item}?w=248&fit=crop&auto=format`}
                            style={{ borderRadius: 10 }}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
    }
}

function QuiltedImageList() {
    const isDownMd = useMediaQuery('(max-width: 600px)')
    return (
        <Box sx={{ overflowY: 'scroll' }}>
            <ImageList variant="quilted" cols={isDownMd ? 1 : 10} rowHeight={isDownMd ? 350 : 170} gap={14}>
                {isDownMd
                    ? itemDataMobile.map((item, index) => (
                          <ImageListItem key={index} cols={item.cols || 1} rows={item.rows || 1}>
                              <img
                                  {...srcset(item.img, 100, item.rows, item.cols)}
                                  alt={item.title}
                                  loading="lazy"
                                  m={5}
                                  style={{ borderRadius: 10 }}
                              />
                          </ImageListItem>
                      ))
                    : itemData.map((item, index) => (
                          <ImageListItem key={index} cols={item.cols || 1} rows={item.rows || 1}>
                              <img
                                  {...srcset(item.img, 100, item.rows, item.cols)}
                                  alt={item.title}
                                  loading="lazy"
                                  m={5}
                                  style={{ borderRadius: 10 }}
                              />
                          </ImageListItem>
                      ))}
            </ImageList>
        </Box>
    )
}

function Gallery() {
    return (
        <>
            <MasonryImageList />
            {/* <QuiltedImageList /> */}
        </>
    )
}
const masonryItemData = [
    pixelArt1,
    pixelArt6,
    pixelArt7,
    pixelArt5,
    pixelArt9,
    pixelArt2,
    pixelArt4,
    pixelArt8,
    pixelArt10,
    pixelArt3,
]
const itemData = [
    {
        img: pixelArt4,
        title: 'Breakfast',
        rows: 2,
        cols: 2,
    },
    {
        img: pixelArt7,
        title: 'Camera',
        rows: 2,
        cols: 2,
    },
    {
        img: pixelArt8,
        title: 'Burger',
        rows: 2,
        cols: 2,
    },

    {
        img: pixelArt10,
        title: 'Honey',
        author: '@arwinneil',
        rows: 1,
        cols: 1,
    },
    {
        img: pixelArt9,
        title: 'Hats',
        cols: 2,
        rows: 2,
    },

    {
        img: pixelArt6,
        title: 'Coffee',
        rows: 1,
        cols: 1,
    },
    {
        img: pixelArt1,
        title: 'Fern',
        rows: 2,
        cols: 3,
    },

    {
        img: pixelArt5,
        title: 'Basketball',
        rows: 1,
        cols: 1,
    },
    {
        img: pixelArt3,
        title: 'Mushrooms',
        rows: 1,
        cols: 1,
    },

    {
        img: pixelArt4,
        title: 'Tomato basil',
        rows: 1,
        cols: 1,
    },
    {
        img: pixelArt8,
        title: 'Sea star',
        rows: 1,
        cols: 1,
    },
]
const itemDataMobile = [
    {
        img: pixelArt4,
        title: 'Breakfast',
    },
    {
        img: pixelArt7,
        title: 'Camera',
    },
    {
        img: pixelArt8,
        title: 'Burger',
    },

    {
        img: pixelArt10,
        title: 'Honey',
        author: '@arwinneil',
    },
    {
        img: pixelArt9,
        title: 'Hats',
    },

    {
        img: pixelArt6,
        title: 'Coffee',
    },
    {
        img: pixelArt2,
        title: 'Fern',
    },

    {
        img: pixelArt5,
        title: 'Basketball',
    },
    {
        img: pixelArt3,
        title: 'Mushrooms',
    },

    {
        img: pixelArt4,
        title: 'Tomato basil',
    },
    {
        img: pixelArt8,
        title: 'Sea star',
    },
]
export default Gallery
