import { useState, useEffect, useMemo } from 'react'

import { useAuth } from 'context/auth'

import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import Sidenav from 'examples/Sidenav'

import theme from 'assets/theme'
import themeRTL from 'assets/theme/theme-rtl'

import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import useRoutes from 'hooks/useRoutes'
import useApi from 'hooks/useApi'

import { useSoftUIController, setMiniSidenav /* setOpenConfigurator */ } from 'context'

// import brand from "assets/images/logo-image.png";

// import { LocalizationProvider } from "@mui/x-date-pickers";
// import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

export default function App() {
    const [controller, dispatch] = useSoftUIController()
    const [auth] = useAuth()
    const [routes] = useRoutes()
    const { miniSidenav, direction, layout, /* openConfigurator, */ sidenavColor } = controller
    const [onMouseEnter, setOnMouseEnter] = useState(false)
    const [rtlCache, setRtlCache] = useState(null)
    const { pathname, search } = useLocation()
    const [subscription, CreateSubscription] = useApi()

    const history = useHistory()

    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: 'rtl',
            stylisPlugins: [rtlPlugin],
        })

        setRtlCache(cacheRtl)
    }, [])

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false)
            setOnMouseEnter(true)
        }
    }

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true)
            setOnMouseEnter(false)
        }
    }

    // Change the openConfigurator state
    // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction)
    }, [direction])

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
    }, [pathname])

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const sessionId = searchParams.get('session_id');
        if(sessionId) {
            CreateSubscription('/subscription/create-subscription/', {
                method: 'POST',
                body: {
                    subscriptionId: sessionId
                },
            })
        }
    }, [search])

    useEffect(() => {
        if(subscription.data) {
            window.location.href = '/subscription';
        }
    }, [subscription.data])

    const getRoutes = () =>
        routes
            .filter((route) => {
                if (route.type == 'title') return false
                if (!route.auth) {
                    if (route.noAuth && auth?.accessToken) return false
                    else return true
                }
                if (route.auth) {
                    if (auth?.accessToken) return true
                    else return false
                }
            })
            .map((route) => {
                if (route.collapse) {
                    return getRoutes(route.collapse)
                }

                if (route.route) {
                    return <Route exact path={route.route} component={route.component} key={route.key} />
                }

                return null
            })

    const filteredRoutes = useMemo(() => getRoutes(), [auth])

    // useEffect(() => {
    //     if (!auth?.accessToken && !pathname.includes('pay') && !pathname.includes('authentication')) {
    //         history.push('/authentication/sign-in')
    //     }
    // }, [auth, pathname])

    return direction === 'rtl' ? (
        // <LocalizationProvider dateAdapter={AdapterMoment}>
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={themeRTL}>
                <CssBaseline />
                <Switch>
                    {filteredRoutes}
                    <Redirect from="*" to="/" />
                </Switch>
            </ThemeProvider>
        </CacheProvider>
    ) : (
        // </LocalizationProvider>
        // <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
                {filteredRoutes}
                <Redirect from="*" to="/" />
            </Switch>
        </ThemeProvider>
        // </LocalizationProvider>
    )
}
