import { useState } from 'react'
import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { Button, Modal, Skeleton, Tabs, Typography, useMediaQuery } from '@mui/material'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import SuiTypography from 'components/SuiTypography'
import useApi from 'hooks/useApi'
import CircularProgress from '@mui/material/CircularProgress'

import PersonIcon from '@mui/icons-material/Person'

// Tabs
import Box from '@mui/material/Box'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'


import { useSnackbar } from 'notistack'
import { useAuth } from 'context/auth'

import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import BundleComponent from 'components/BundleComponent'
import { useProfile } from 'context/profile'
import { useCart } from 'context/cart'
import { mediaUrl } from 'utils/constant'

function Profile() {
    // fetch assets
    const [userBundles, getUserBundles] = useApi()
    const [userBooks, getUserBooks] = useApi()
    const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
    const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false);
    const [profile, getProfileEffect] = useProfile()
    const [openCart, setOpenCart] = useCart()

    const [modelItems, setModelItems] = useState([])
    const [modelId, setModelId] = useState(null)

    const handleShowModel = (models, model_id) => {
        setModelItems(models)
        if (model_id) {
            setModelId(model_id)
        }
    }

    // TabList
    const [selectedTab, setSelectedTab] = useState('1')

    const handleSelectTab = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const { enqueueSnackbar } = useSnackbar()
    const [, setAuth] = useAuth()

    const [businessName, setBusinessName] = useState()
    const [email, setEmail] = useState()
    const [newPassword, setNewPassword] = useState()
    const [repeatNewPassword, setRepeatNewPassword] = useState()
    const [avatar, setAvatar] = useState(null)
    const [preview, setPreview] = useState()
    const [editProfileResponse, postEditprofile] = useApi()
    const [cancelSubscription, cancelSubscriptionReq] = useApi()
    const [addCartItemState, addCartItem] = useApi()

    const isDownMd = useMediaQuery('(max-width: 900px)')

    const handleCancelSubscription = () => {
        try {
            cancelSubscriptionReq('/subscription/cancel-subscription/')
        } catch (err) {
            setFlag(false)
            enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 2000, })
            return
        }
        getProfileEffect();
        enqueueSnackbar('Subscription cancelled successfully', { variant: 'success' })
        handleCancelSubscriptionModalClose()
    }

    const onBusinessName = (e) => {
        setBusinessName(e.target?.value)
    }

    const onEmail = (e) => {
        setEmail(e.target?.value)
    }

    const onNewPassword = (e) => {
        setNewPassword(e.target?.value)
    }
    const onRepeatNewPassword = (e) => {
        setRepeatNewPassword(e.target?.value)
    }

    useEffect(() => {
        getUserBundles('/products/product-list-auth/')
        getUserBooks('/digital-books/book-list-auth/')
    }, [])

    useEffect(() => {
        if (profile?.data?.data) {
            setBusinessName(profile?.data?.data?.business_name)
            setEmail(profile?.data?.data?.email)
        }
    }, [profile])

    const updateUser = async () => {
        const formData = new FormData()
        if (preview) {
            formData.append('file', preview)
        }
        if (!businessName) {
            enqueueSnackbar('Please enter your name', {
                variant: 'info',
                autoHideDuration: 2000,
            })
            return
        }
        formData.append('business_name', businessName)
        if (!email) {
            enqueueSnackbar('Please enter your email', {
                variant: 'info',
                autoHideDuration: 2000,
            })
            return
        }
        formData.append('email', email)
        if (newPassword || repeatNewPassword) {
            if (!newPassword || !repeatNewPassword) {
                enqueueSnackbar('Please enter your new password.', {
                    variant: 'info',
                    autoHideDuration: 2000,
                })
                return
            }
            if (newPassword != repeatNewPassword) {
                enqueueSnackbar('Password confirmation is not matching.', {
                    variant: 'info',
                    autoHideDuration: 2000,
                })
                return
            }
            formData.append('password', newPassword)
        }
        try {
            const data = await postEditprofile('/accounts/update/', {
                method: 'POST',
                body: formData,
            })
            enqueueSnackbar('Your account has been updated', {
                variant: 'success',
                autoHideDuration: 2000,
            })
            getProfileEffect();
        } catch (e) {
            enqueueSnackbar(e?.data?.errors || 'Something went wrong!', {
                variant: 'error',
            })
        }
    }
    
    const handlePurchaseBook = (book) => {
        if (book.user?.filter(user => user.email === profile?.data?.data?.email)?.length) return openBook(book)
        if (book.cart_users?.filter(user => user.email === profile?.data?.data?.email)?.length) return setOpenCart(true)
        addCartItem('/accounts/cart-items/', {
            method: 'POST',
            body: {
                item_id: book.id,
                item_type: 'digital_book'
            }
        })
    }
    
    const openBook = (book) => {
        window.open(book.googl_drive_url, '_blank')
    }

    useEffect(() => {
        if (addCartItemState?.data?.ok) {
            setOpenCart(true)
            enqueueSnackbar('Book is added to cart successfully!', {
                variant: 'success',
            })
        }
    }, [addCartItemState])

    const handleEditProfileModalOpen = () => setEditProfileModalOpen(true);
    const handleEditProfileModalClose = () => setEditProfileModalOpen(false);

    const handleCancelSubscriptionModalOpen = () => setCancelSubscriptionModalOpen(true)
    const handleCancelSubscriptionModalClose = () => setCancelSubscriptionModalOpen(false)

    return (
        <WidgetLandingLayout hideFooter={true} modelItems={modelItems} setModelItems={setModelItems} model_id={modelId} >
            <Box height="100px" />
            <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 3,
                    position: 'relative',
                    maxWidth: '1350px',
                    margin: 'auto',

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                <Box display="flex" justifyContent="space-between" alignItems={isDownMd ? "center" : "end"} marginTop={4} marginBottom={isDownMd ? 4 : 0}>
                    <Typography fontSize={32} fontWeight={700}>
                        {profile?.data?.data?.business_name}
                    </Typography>
                    <Button style={{ color: 'black' }} onClick={handleEditProfileModalOpen} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: "6px" }}>
                            <path d="M12 20.0002H21M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        {isDownMd ? '' : 'EDIT PROFILE'}
                    </Button>
                </Box>
                <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.20)', padding: isDownMd ? '0' : '0 24px', marginTop: '32px' }}>
                        <Tabs value={selectedTab} onChange={handleSelectTab} aria-label="lab API tabs example" sx={{
                            background: 'transparent',
                            padding: 0,
                            '& .MuiTabs-flexContainer': {
                                gap: '16px',
                            },
                            '& .MuiTabs-indicator': {
                                background: 'transparent',
                                borderBottom: '1px solid',
                                borderColor: '#297DF6',
                                borderRadius: 0,
                            }
                        }} >
                            <Tab label="My Bundles" value="1" style={{ fontSize: isDownMd ? 14 : 20, flexGrow: 0, }} />
                            <Tab label="My Digital Books" value="2" style={{ fontSize: isDownMd ? 14 : 20, flexGrow: 0, }} />
                        </Tabs>
                    </Box>
                    <TabPanel value="1">
                        <Box paddingY="32px">
                            {userBundles.loading && [0, 1].map(i => <Skeleton variant="rectangular" width="100%" height={300} key={i} style={{ margin: "24px 0", borderRadius: "8px" }} />)}
                            {!userBundles.loading && <>
                                {userBundles.data?.data?.map((bundle, index) =>
                                    <BundleComponent key={index} bundle={bundle} is_superuser={profile?.data?.data?.is_superuser} hideButton={true} setModelItems={handleShowModel} />
                                )}
                                {!userBundles.data?.data?.length && <Box display="flex" flexDirection="column" alignItems="center">
                                    <Typography align='center' variant="h3" component="h4" mb={5}>
                                        You still don’t have any bundles?
                                    </Typography>
                                    <Link to="/bundles">
                                        <SuiButton
                                            variant="contained"
                                            color="primary"
                                            style={{ background: "#436AF5", borderRadius: '50px' }}
                                        >
                                            EXPLORE BUNDLES
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginLeft: '4px' }}>
                                                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </SuiButton>
                                    </Link>
                                </Box>}
                            </>}
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box my={8}>
                            {userBooks.loading ?
                                <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                                    <CircularProgress />
                                </Box> : <Grid container spacing={isDownMd ? 2 : 4} alignItems='stretch'>
                                    {userBooks?.data?.data?.map((book, index) => <Grid item xs={12} lg={6} key={index} marginY={2}>
                                        <Box onClick={() => handlePurchaseBook(book)} style={{ background: 'white', boxShadow: '0 0 20px 4px rgba(0,0,0,0.2)', cursor: 'pointer' }} borderRadius="8px" padding="20px" paddingBottom={2} height="100%">
                                            <Box display="flex" gap="10px" justifyContent='space-between'>
                                                <Box>
                                                    <Typography fontSize="26px" fontWeight={700}>
                                                        {book.title}
                                                    </Typography>
                                                    <Typography fontSize="18px" fontWeight={600}>
                                                        ${book.price} {book.user?.filter(u => u.email === profile?.data?.data?.email)?.length ? '(You owned)' : ''}
                                                    </Typography>
                                                    {!isDownMd && <Typography fontSize="14px" fontWeight={400} style={{ opacity: '.6' }}>
                                                        {book.summary}
                                                    </Typography>}
                                                </Box>
                                                <img src={book?.link?.replace(
                                                    mediaUrl,
                                                    'https://digiartforever.com'
                                                )} alt={"book" + index} width={isDownMd ? 150 : 200} height={isDownMd ? 175 : 280} style={{ objectFit: 'conver', transform: isDownMd ? 'translateY(-30px)' : 'translateY(-50px)' }} />
                                            </Box>
                                            {isDownMd && <Typography fontSize="14px" fontWeight={400} style={{ opacity: '.6' }} marginTop={-2.5}>
                                                {book.summary}
                                            </Typography>}
                                        </Box>
                                    </Grid>)}
                                </Grid>}
                        </Box>
                    </TabPanel>
                </TabContext>
                <Modal
                    open={editProfileModalOpen}
                    onClose={handleEditProfileModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: isDownMd ? '30px' : '0',
                        right: '0',
                        width: isDownMd ? '100%' : '640px',
                        bgcolor: '#EDF3FA',
                        p: isDownMd ? 2 : 8,
                        bottom: '0',
                    }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography id="modal-modal-title" variant="h6" component="h2" fontSize={28} fontWeight={700} >
                                Edit profile details
                            </Typography>
                            <Button style={{ padding: 0, minWidth: "auto" }} onClick={handleEditProfileModalClose} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </Button>
                        </Box>
                        <Card sx={{ padding: isDownMd ? 0 : 5, paddingBottom: 5, background: 'transparent', boxShadow: 'none', height: '100%' }}>
                            <SuiBox component="form" role="form" display="flex" flexDirection="column" height="100%">
                                <Box flex={1}>
                                    <SuiBox mb={2}>
                                        <SuiBox mb={1} ml={0.5}>
                                            <SuiTypography component="label" variant="title" style={{ color: 'rgba(0,0,0,.6)', fontSize: '14px' }}>
                                                Name
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput type="text" value={businessName} onChange={onBusinessName} sx={{ backgroundColor: 'transparent !important' }} />
                                    </SuiBox>
                                    <SuiBox mb={2}>
                                        <SuiBox mb={1} ml={0.5}>
                                            <SuiTypography component="label" variant="title" style={{ color: 'rgba(0,0,0,.6)', fontSize: '14px' }}>
                                                Email
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput type="text" value={email} onChange={onEmail} disbled sx={{ backgroundColor: 'transparent !important' }} />
                                    </SuiBox>
                                    <SuiTypography component="label" variant="title" fontWeight="bold" mb={2}>
                                        Set new password
                                    </SuiTypography>
                                    <Box display="flex" gap="8px">
                                        <SuiBox>
                                            <SuiBox mb={1} ml={0.5}>
                                                <SuiTypography component="label" variant="title" style={{ color: 'rgba(0,0,0,.6)', fontSize: '14px' }}>
                                                    New password
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiInput type="text" value={newPassword} onChange={onNewPassword} sx={{ backgroundColor: 'transparent !important' }} />
                                        </SuiBox>
                                        <SuiBox>
                                            <SuiBox mb={1} ml={0.5}>
                                                <SuiTypography component="label" variant="title" style={{ color: 'rgba(0,0,0,.6)', fontSize: '14px' }}>
                                                    Repeat new password
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiInput type="text" value={repeatNewPassword} onChange={onRepeatNewPassword} sx={{ backgroundColor: 'transparent !important' }} />
                                        </SuiBox>
                                    </Box>
                                    <SuiTypography component="p" variant="title" fontWeight="bold" mb={2} style={{ marginTop: '16px' }}>
                                        My subscription
                                    </SuiTypography>
                                    <SuiTypography component="p" style={{ marginTop: '16px', fontSize: '12px', opacity: '.6' }}>
                                        CURRENT PLAN
                                    </SuiTypography>

                                    <Card style={{ background: 'white', margin: '8px 0', padding: '12px 6px', paddingBottom: '24px', flexDirection: 'row', alignItems: 'start', justifyContent: 'space-between' }} >
                                        <Box padding="6px 12px">
                                            <Typography fontSize="16px" fontWeight="700" >
                                                {profile?.data?.data?.is_active ? 'Paid' : 'Free'}
                                            </Typography>
                                            <Typography fontSize="14px" fontWeight="400" style={{ opacity: '.6' }}>
                                                {profile?.data?.data?.subscription_period && (profile?.data?.data?.subscription_period === 'year' ? 'Annual Plan' : 'Monthly Plan')}
                                            </Typography>
                                        </Box>
                                        {profile?.data?.data?.is_active ? <Button style={{ color: '#C01048' }} onClick={() => {
                                            handleCancelSubscriptionModalOpen()
                                        }}>
                                            Cancel Subscription
                                        </Button> : <Link to="/subscription">
                                            <Button style={{ color: '#436AF5' }}>
                                                Upgrade to Paid
                                            </Button>
                                        </Link>}
                                    </Card>
                                </Box>
                                <SuiBox mt={1} mb={1} display="flex" justifyContent="center" gap="16px">
                                    <SuiButton variant="outlined" style={{ color: 'black', borderColor: '#00000044', minWidth: '150px', borderRadius: '50px' }} onClick={handleEditProfileModalClose}>
                                        {editProfileResponse.loading ? (
                                            <CircularProgress color="white" size={20} />
                                        ) : (
                                            'CANCEL'
                                        )}
                                    </SuiButton>
                                    <SuiButton color="primary" style={{ background: '#436AF5', minWidth: '150px', borderRadius: '50px' }} onClick={updateUser}>
                                        {editProfileResponse.loading ? (
                                            <CircularProgress color="white" size={20} />
                                        ) : (
                                            'SAVE CHANGES'
                                        )}
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Box>
                </Modal>
                <Modal
                    open={cancelSubscriptionModalOpen}
                    onClose={handleCancelSubscriptionModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        bottom: isDownMd ? '0' : '50%',
                        left: isDownMd ? 0 : '50%',
                        transform: isDownMd ? '0' : 'translate(-50%, 50%)',
                        width: isDownMd ? '100%' : '620px',
                        bgcolor: '#EDF3FA',
                        borderRadius: '8px',
                        borderBottomLeftRadius: isDownMd ? '0' : '8px',
                        borderBottomRightRadius: isDownMd ? '0' : '8px',
                        p: 4,
                    }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography id="modal-modal-title" variant="h6" component="h2" fontSize={28} fontWeight={700} >
                                Cancel subscription
                            </Typography>
                            <Button style={{ padding: 0, minWidth: "auto" }} onClick={handleCancelSubscriptionModalClose} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </Button>
                        </Box>
                        <Box my={6}>
                            <Typography align='center' fontSize={20}>
                                Are you sure you want to cancel your Paid subscription?
                                <br />
                                You can’t undo this action.
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="end" gap="18px">
                            <SuiButton variant="outlined" style={{ color: 'black', borderColor: '#00000044', minWidth: '150px', borderRadius: '50px' }} onClick={handleCancelSubscriptionModalClose}>
                                {editProfileResponse.loading ? (
                                    <CircularProgress color="white" size={20} />
                                ) : (
                                    'GO BACK'
                                )}
                            </SuiButton>
                            <SuiButton color="primary" style={{ background: '#C01048', minWidth: '150px', borderRadius: '50px' }} onClick={handleCancelSubscription}>
                                {editProfileResponse.loading ? (
                                    <CircularProgress color="white" size={20} />
                                ) : (
                                    'YES, CANCEL'
                                )}
                            </SuiButton>
                        </Box>
                    </Box>
                </Modal>
            </SuiBox>
        </WidgetLandingLayout>
    )
}

export default Profile
