import React, { memo, useEffect } from 'react'

// import { PREFIX } from './use_api'
import useApi from 'hooks/useApi'

import { useMediaQuery } from '@mui/material'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import pixelArt1 from '../../../assets/images/p1.webp'
import pixelArt2 from '../../../assets/images/p2.webp'
import pixelArt3 from '../../../assets/images/p3.webp'
import pixelArt4 from '../../../assets/images/p4.png'
import pixelArt5 from '../../../assets/images/p5.webp'

export default memo(() => {
    const isDownMd = useMediaQuery('(max-width: 600px)')

    const [slides, get_slides] = useApi()
    useEffect(() => {
        get_slides('/slider/')
    }, [])

    let mapped_slides = [
        // {
        //     title: '',
        //     target: '',
        //     link: '',
        //     image_desktop: pixelArt1,
        // },
        // {
        //     title: 'Get skull learning packages.',
        //     target: '',
        //     link: '',
        //     image_desktop: pixelArt2,
        // },
        {
            title: 'The power, The art, Forever.',
            target: '',
            link: '',
            image_desktop: pixelArt3,
        },
        {
            title: '',
            target: '',
            link: '',
            image_desktop: pixelArt4,
        },
        {
            title: 'Get to know the world of Amelie...',
            target: '',
            link: '',
            image_desktop: pixelArt5,
        },
    ]

    // mapped_slides.map((slide) => {
    //     return {
    //         title: slide.title,
    //         target: slide.target,
    //         link: slide.link,
    //         image_desktop: PREFIX + "/" + slide.desktop_image,
    //         mobile_image: PREFIX + '/' + slide.mobile_image,
    //     }
    // })

    return (
        <div dir="ltr" style={{ maxWidth: '100%' }}>
            <Carousel dynamicHeight infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                {slides &&
                    slides?.data?.data?.map((slide) => {
                        const HOC = ({ children }) => (
                            // slide?.media?.link?.includes('http') ? (
                            //     <a
                            //         // target={slide.target || '_blank'}
                            //         rel="norefferer noopener"
                            //         href={slide.media?.link.replace(
                            //             'http://127.0.0.1:8001',
                            //             'https://digiartforever.com'
                            //         )}
                            //         style={{ zIndex: -1 }}
                            //     >
                            //         {children}
                            //     </a>
                            // ) : (
                            //     <div style={{ zIndex: -1 }}>
                            //         <div style={{ cursor: 'pointer' }}>{children}</div>
                            //     </div>
                            // )

                            <div style={{ zIndex: -1 }}>
                                <div style={{ cursor: 'pointer' }}>{children}</div>
                            </div>
                        )

                        return (
                            <HOC
                                key={slide?.media?.link.replace('http://127.0.0.1:8001', 'https://digiartforever.com')}
                            >
                                <div style={{ position: 'relative', zIndex: -1 }}>
                                    <h1
                                        style={{
                                            color: 'white',
                                            position: 'absolute',
                                            left: '7%',
                                            top: '15%',
                                            maxWidth: '50%',
                                            ...(isDownMd ? { fontSize: '24px' } : { fontSize: '45px' }),
                                        }}
                                    >
                                        {slide.title}
                                    </h1>
                                    <img
                                        src={slide?.media?.link.replace(
                                            'http://127.0.0.1:8001',
                                            'https://digiartforever.com'
                                        )}
                                        alt={slide.title}
                                        style={{
                                            zIndex: -1,
                                            borderRadius: 10,
                                            maxHeight: '700px',
                                            objectFit: 'cover',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </div>
                            </HOC>
                        )
                    })}
            </Carousel>
        </div>
    )
})
