import { useMemo, useState } from 'react'
import { useLang } from 'context/language'

// import POS from 'layouts/pos'
import SignIn from 'layouts/authentication/sign-in'
import SignUp from 'layouts/authentication/sign-up'
import WidgetLanding from 'layouts/widgetLanding'
import Packages from 'layouts/packages'
import DigitalBooks from 'layouts/digitalbooks'
import Contests from 'layouts/contests'
import Contest from 'layouts/contest'
import Subscription from 'layouts/subscription'
import PaymentMethod from 'layouts/parymentMethod'
import Viewer from 'layouts/viewer'
import SocialMedia from 'layouts/socialMedia'
import Document from 'examples/Icons/Document'
import SpaceShip from 'examples/Icons/SpaceShip'
import Profile from 'layouts/profile'
import ForgotPassword from 'layouts/authentication/forgot'

const useRoutes = () => {
    const [{ strings }] = useLang()

    const ROUTES = [
        {
            type: 'collapse',
            name: 'Landing',
            key: 'landing',
            route: '/',
            icon: <SpaceShip size="12px" />,
            component: WidgetLanding,
            noCollapse: true,
            noMenuRender: true,
            auth: false,
        },
        {
            type: 'collapse',
            name: 'Profile',
            key: 'profile',
            route: '/profile',
            icon: <SpaceShip size="12px" />,
            component: Profile,
            noCollapse: true,
            noMenuRender: true,
            auth: false,
        },
        {
            type: 'collapse',
            name: 'Bundles',
            key: 'bundles',
            route: '/bundles',
            icon: <SpaceShip size="12px" />,
            component: Packages,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'Digital Books',
            key: 'digitalbooks',
            route: '/digital-books',
            icon: <SpaceShip size="12px" />,
            component: DigitalBooks,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'Contests',
            key: 'contests',
            route: '/contests',
            icon: <SpaceShip size="12px" />,
            component: Contests,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'Contest',
            key: 'contest',
            route: '/contest/:id',
            icon: <SpaceShip size="12px" />,
            component: Contest,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'Viewer',
            key: 'viewer',
            route: '/viewer',
            component: Viewer,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'Socal Media',
            key: 'socialMedia',
            route: '/social-media',
            component: SocialMedia,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'Subscription',
            key: 'subscription',
            route: '/subscription',
            icon: <SpaceShip size="12px" />,
            component: Subscription,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'paymentMethod',
            key: 'paymentMethod',
            route: '/paymentMethod/:mode/:id',
            icon: <SpaceShip size="12px" />,
            component: PaymentMethod,
            noCollapse: true,
            noMenuRender: true,
            auth: true,
        },
        {
            type: 'collapse',
            name: 'signin',
            key: 'sign-in',
            route: '/authentication/sign-in',
            icon: <Document size="12px" />,
            component: SignIn,
            noCollapse: true,
            noMenuRender: true,
            auth: false,
            noAuth: true,
        },
        {
            type: 'collapse',
            name: strings.SIGN_UP,
            key: 'sign-up',
            route: '/authentication/sign-up',
            icon: <SpaceShip size="12px" />,
            component: SignUp,
            noCollapse: true,
            noMenuRender: true,
            auth: false,
            noAuth: true,
        },
        {
            type: 'collapse',
            name: strings.FORGOT_PASSWORD,
            key: 'forgot',
            route: '/authentication/forgot-password',
            icon: <SpaceShip size="12px" />,
            component: ForgotPassword,
            noCollapse: true,
            noMenuRender: true,
            auth: false,
            noAuth: true,
        },
    ]

    const [routes, setRoutes] = useState(ROUTES)

    useMemo(() => {
        setRoutes(ROUTES)
    }, [strings])

    return [routes]
}

export default useRoutes
