import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SuiBox from 'components/SuiBox'

import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'

import useApi from 'hooks/useApi'
import { useHistory, useLocation } from 'react-router-dom'

import { Box, Button, CircularProgress, Skeleton, useMediaQuery } from '@mui/material'
import { useCart } from 'context/cart'
import { useProfile } from 'context/profile'
import { useSnackbar } from 'notistack'
import { mediaUrl } from 'utils/constant'

function DigitalBooks() {
    const [books, getAllBooks] = useApi()
    const [checkoutBundleState, checkoutBundle] = useApi()
    const [confirmCheckoutState, confirmCheckout] = useApi()
    const [addCartItemState, addCartItem] = useApi()
    const history = useHistory()
    const location = useLocation();
    const [openCart, setOpenCart] = useCart()
    const [profile] = useProfile()

    const [token, setToken] = useState(null)
    let searchParams = new URLSearchParams(location.search);
    const paramToken = searchParams.get('token')
    const isDownMd = useMediaQuery('(max-width: 900px)')
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        if (paramToken && paramToken != token) {
            setToken(paramToken)
        }
        history.push({ ...location, search: '' })
    }, [paramToken])

    useEffect(() => {
        if (token) {
            confirmCheckout('/subscription/confirm-checkout/', {
                method: 'POST',
                body: {
                    token: token
                }
            })
        }
    }, [token])

    useEffect(() => {
        getAllBooks('/digital-books/books-list/')
    }, [openCart])

    // useEffect(() => {
    //     getAllBooks('/digital-books/books-list/')
    // }, [])

    const handlePurchaseBook = (book) => {
        if (book.user?.filter(user => user.email === profile?.data?.data?.email)?.length) return openBook(book)
        if (book.cart_users?.filter(user => user.email === profile?.data?.data?.email)?.length) return setOpenCart(true)
        addCartItem('/accounts/cart-items/', {
            method: 'POST',
            body: {
                item_id: book.id,
                item_type: 'digital_book'
            }
        })
    }

    const openBook = (book) => {
        window.open(book.googl_drive_url, '_blank')
    }

    useEffect(() => {
        if (addCartItemState?.data?.ok) {
            setOpenCart(true)
            enqueueSnackbar('Book is added to cart successfully!', {
                variant: 'success',
            })
        }
    }, [addCartItemState])

    useEffect(() => {
        if (checkoutBundleState.data?.ok) {
            window.location.href = checkoutBundleState.data?.data?.redirect_url
        }
    }, [checkoutBundleState])

    const checkUserhasBook = (book) => {
        const users = book.user;
        return !!users.some(item => item.email === profile?.data?.data?.email);
    }

    return (
        <WidgetLandingLayout hideFooter={true}>
            <Box height="120px" />
            <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 3,
                    position: 'relative',
                    maxWidth: '1350px',
                    margin: 'auto',

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={4} marginBottom={6}>
                    <Typography fontSize={32} fontWeight={700}>
                        Digital books
                    </Typography>
                </Box>
                {books.loading ?
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        <CircularProgress />
                    </Box> : <Grid container spacing={isDownMd ? 2 : 4} alignItems='stretch'>
                        {books?.data?.data?.map((book, index) => <Grid item xs={12} lg={6} key={index} marginY={2}>
                            {/*{ checkUserhasBook(book) ?*/}

                                <Box onClick={() => handlePurchaseBook(book)} style={{ background: 'white', boxShadow: '0 0 20px 4px rgba(0,0,0,0.2)', cursor: 'pointer' }} borderRadius="8px" padding="20px" paddingBottom={2} height="100%">
                                    <Box display="flex" gap="10px" justifyContent='space-between'>
                                        <Box>
                                            <Typography fontSize="26px" fontWeight={700}>
                                                {book.title}
                                            </Typography>
                                            <Typography fontSize="18px" fontWeight={600}>
                                                ${book.price} {book.user?.filter(u => u.email === profile?.data?.data?.email)?.length ? '(You owned)' : ''}
                                            </Typography>
                                            {!isDownMd && <Typography fontSize="14px" fontWeight={400} style={{ opacity: '.6' }}>
                                                {book.summary}
                                            </Typography>}
                                        </Box>
                                        <img src={book?.link?.replace(
                                            mediaUrl,
                                            'https://digiartforever.com'
                                        )} alt={"book" + index} width={isDownMd ? 150 : 200} height={isDownMd ? 175 : 280} style={{ objectFit: 'conver', transform: isDownMd ? 'translateY(-30px)' : 'translateY(-50px)' }} />
                                    </Box>
                                    {isDownMd && <Typography fontSize="14px" fontWeight={400} style={{ opacity: '.6' }} marginTop={-2.5}>
                                        {book.summary}
                                    </Typography>}
                                </Box>
                                {/*: null }*/}

                        </Grid>)}
                    </Grid>}
            </SuiBox>
            <Box height="120px" />
        </WidgetLandingLayout>
    )
}

export default DigitalBooks
