import { useEffect } from 'react'

import { useLocation, NavLink } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'

import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse'

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot'
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav'

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from 'context'

function Sidenav({ color, brand, brandName, routes, ...rest }) {
    const [controller, dispatch] = useSoftUIController()
    const { miniSidenav, transparentSidenav } = controller
    const location = useLocation()
    const { pathname } = location
    const collapseName = pathname.split('/').slice(1)[0]

    const closeSidenav = () => setMiniSidenav(dispatch, true)

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200)
        }

        /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
        window.addEventListener('resize', handleMiniSidenav)

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleMiniSidenav)
    }, [dispatch, location])

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href, noMenuRender }) => {
        let returnValue

        if (noMenuRender) return null

        if (type === 'collapse') {
            returnValue = href ? (
                <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                    <SidenavCollapse
                        color={color}
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        noCollapse={noCollapse}
                    />
                </Link>
            ) : (
                <NavLink to={route} key={key}>
                    <SidenavCollapse
                        color={color}
                        key={key}
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        noCollapse={noCollapse}
                    />
                </NavLink>
            )
        } else if (type === 'title') {
            returnValue = (
                <SuiTypography
                    key={key}
                    display="block"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="uppercase"
                    opacity={0.6}
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                >
                    {title}
                </SuiTypography>
            )
        } else if (type === 'divider') {
            returnValue = <Divider key={key} />
        }

        return returnValue
    })

    return (
        <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
            <SuiBox pt={0} pb={1} px={0} textAlign="center">
                <SuiBox
                    display={{ xs: 'block', xl: 'none' }}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: 'pointer' }}
                >
                    <SuiTypography variant="h6" color="secondary">
                        <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
                    </SuiTypography>
                </SuiBox>
                <SuiBox component={NavLink} to="/" display="flex" alignItems="center">
                    {brand && <SuiBox component="img" src={brand} alt="logo" height="200px" />}
                    <SuiBox width={!brandName && '100%'} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
                        <SuiTypography component="h1" variant="subtitle" fontWeight="medium">
                            Digiartforever
                        </SuiTypography>
                    </SuiBox>
                </SuiBox>
            </SuiBox>
            {/* <Divider /> */}
            <div style={{ margin: 10 }} />
            <List>{renderRoutes}</List>
            <SuiBox pt={2} my={2} mx={2} mt="auto">
                {/* <SuiBox mt={2}>
          <SuiButton
            component="a"
            href="https://creative-tim.com/product/soft-ui-dashboard-pro-react"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            color={color}
            fullWidth
          >
            upgrade to pro
          </SuiButton>
        </SuiBox> */}
            </SuiBox>
        </SidenavRoot>
    )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: 'info',
    brand: '',
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Sidenav
