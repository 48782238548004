import { useState, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import { Grid, Card, Box, Button, Typography, Modal, Avatar, IconButton } from '@mui/material'
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import SuiButton from 'components/SuiButton'
import DownloadIcon from '@mui/icons-material/Download'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

// Dialog imports
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import ViewInArIcon from '@mui/icons-material/ViewInAr'
import ReactPlayer from 'react-player'
// hooks
import useApi from 'hooks/useApi'
// utils
import getMonthAndYear from '../../utils/getMonthAndYear'

// sketchfab imports
import SketchFab from '../../components/SketchFab'
import WistiaPlayer from 'components/WitsiaPlayer'
import { CameraAltOutlined, VideocamOutlined } from '@mui/icons-material'
import ViewerModal from 'layouts/viewer/ViewerModal'
import SuiBox from 'components/SuiBox'
import { useProfile } from 'context/profile'
import { mediaUrl } from 'utils/constant'

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxWidth: '95%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
}

const SinglePackage = ({ key, id, product_id, name, date, image, active, in_repo, is_monthly, uid, ...props }) => {
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const isDownMd = useMediaQuery('(max-width: 600px)')
    const [inRepoState, setInRepoState] = useState(in_repo)
    const [openHelp, setOpenHelp] = useState(false)

    const [product, getProduct] = useApi()
    const [inRepoReq, addToRepo] = useApi()
    const [showVideo, setShowVideo] = useState(false)
    const hideVideo = () => {
        setShowVideo(false)
    }
    const handleShowVideo = () => {
        setShowVideo(true)
    }
    useEffect(() => {
        setInRepoState(in_repo)
        getProduct('/products/' + id + '/')
    }, [])
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [open3D, setOpen3D] = useState(null)
    const [openWitsia, setOpenWitsia] = useState(null)
    const open3DModal = (id) => {
        setOpen3D(id)
    }
    const close3DModal = () => {
        setOpen3D(null)
    }
    const openWitsiaModal = (id) => {
        setOpenWitsia(id)
    }
    const closeWitsiaModal = () => {
        setOpenWitsia(null)
    }
    const handleAddToRepo = (id) => {
        try {
            addToRepo(`/products/add-to-repo/${id}/`)
            enqueueSnackbar('Added to repo', {
                variant: 'success',
            })
            setInRepoState(true)
        } catch (err) {
            enqueueSnackbar('Error adding to repo', {
                variant: 'error',
            })
        }
    }
    const handleAction = (is_monthly, in_repo) => {
        if (!profile?.data?.data?.is_superuser && !profile?.data?.data?.is_active) {
            enqueueSnackbar('Please subscribe to a plan to access this content', {
                variant: 'info',
            })
            history.push('/subscription')
            return
        }
        if (inRepoState) {
            handleOpen()
        } else if (profile?.data?.data?.is_superuser || is_monthly) {
            handleAddToRepo(id)
        } else {
            history.push('/paymentMethod/buy/' + product_id + '/')
        }
    }

    const [profile, getProfileEffect] = useProfile()

    return (
        <>
            {inRepoState != 'undefined' ? (
                <>
                    <Button disabled={!active} key={key}>
                        <Card
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyItems: 'center',
                                padding: 10,
                                ...(!active ? { filter: 'blur(2px),grayscale(1)' } : {}),
                            }}
                            onClick={() => handleAction(is_monthly, in_repo)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {name}
                                <span style={{ color: 'gray', fontSize: 10 }}> {getMonthAndYear(date)}</span>
                            </div>
                            <div style={{ margin: 5 }} />
                            <div onMouseOver={handleShowVideo} onMouseOut={hideVideo}>
                                <Box style={{ minHeight: 200, width: showVideo ? 300 : undefined }}>
                                    {!showVideo ? (
                                        <img
                                            src={image}
                                            style={{ width: 300, height: 300, borderRadius: 10, margin: '0px auto' }}
                                        />
                                    ) : (
                                        <ReactPlayer
                                            playing={true}
                                            width={350}
                                            url={product?.data?.data?.video?.media?.link.replace(
                                                mediaUrl,
                                                'https://digiartforever.com'
                                            )}
                                        />
                                    )}
                                </Box>
                            </div>
                            <div style={{ margin: 5 }} />
                            <SuiButton
                                variant="gradient"
                                color="secondary"
                                style={{ width: '95%', margin: '0px auto' }}
                            >
                                {inRepoReq.loading ? (
                                    <>Loading...</>
                                ) : active ? (
                                    inRepoState ? (
                                        'View'
                                    ) : (is_monthly || profile?.data?.data?.is_superuser) ? (
                                        'Add to repo'
                                    ) : (
                                        'Buy'
                                    )
                                ) : (
                                    'Coming Soon'
                                )}
                            </SuiButton>
                        </Card>
                    </Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <Box style={{ maxHeight: "80vh", overflowY: "auto", padding: "0 4px",}}>
                                <Grid container>
                                    <Grid item xs={12} xl={3} style={{ minHeight: 200 }}>
                                        <img
                                            src={product?.data?.data?.major_media?.media?.link.replace(
                                                mediaUrl,
                                                'https://digiartforever.com'
                                            )}
                                            style={{ width: 150, height: 200, objectFit: 'cover', borderRadius: 10 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} xl={9} sx={{ ...(isDownMd ? { pt: 0 } : { pt: 15 }) }}>
                                        <Typography id="modal-modal-title" variant="h2" component="h2">
                                            {name}
                                        </Typography>
                                        <Typography id="modal-modal-title" variant="h4" component="h2" color="secondary">
                                            {getMonthAndYear(date)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {product?.data?.data?.video?.witsia_uid && (
                                    <Grid style={{ justifyContent: 'center', margin: '10px auto', alignItems: 'center' }}>
                                        <WistiaPlayer
                                            videoId={product?.data?.data?.video?.witsia_uid}
                                            wrapper="wistia-player-container-1"
                                        />
                                    </Grid>
                                )}
                                {!product?.data?.data?.video?.witsia_uid && product?.data?.data?.video?.media?.link && (
                                    <Grid style={{ justifyContent: 'center', margin: '10px auto', alignItems: 'center' }}>
                                        <ReactPlayer
                                            controls={true}
                                            style={{ margin: '0px auto' }}
                                            url={product?.data?.data?.video?.media?.link.replace(
                                                mediaUrl,
                                                'https://digiartforever.com'
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Card>
                                    <List dense={false} spacing={5}>
                                        {product?.data?.data?.other_media.map((item, index) => {
                                            return (
                                                <>
                                                    <ListItem
                                                        key={index}
                                                        secondaryAction={
                                                            <Box
                                                                style={{ display: 'flex', justifyContent: 'space-between' }}
                                                            >
                                                                {item.media && (
                                                                    <a
                                                                        href={item?.media?.link.replace(
                                                                            mediaUrl,
                                                                            'https://digiartforever.com'
                                                                        )}
                                                                        download={item.title}
                                                                    >
                                                                        <Grid item xs={6} mx={1}>
                                                                            <IconButton edge="end">
                                                                                <DownloadIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </a>
                                                                )}
                                                                {item?.model_uid && (
                                                                    <Grid item xs={6} mx={1} onClick={() => open3DModal(item?.model_uid)}>
                                                                        <IconButton edge="end">
                                                                            <ViewInArIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                )}
                                                                {item?.witsia_uid && (
                                                                    <Grid item xs={6} mx={1} onClick={() => openWitsiaModal(item?.witsia_uid)}>
                                                                        <IconButton edge="end">
                                                                            <VideocamOutlined />
                                                                        </IconButton>
                                                                    </Grid>
                                                                )}
                                                            </Box>
                                                        }
                                                        sx={{ my: 4 }}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar alt="Cindy Baker" src={image} />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={item.title} />
                                                    </ListItem>                                                                                                        
                                                </>
                                            )
                                        })}
                                        <Dialog
                                            fullWidth={true}
                                            maxWidth="100%"                                                    
                                            open={Boolean(open3D)}
                                            onClose={close3DModal}
                                            style={{maxHeight: "100% !important"}}
                                        >
                                            <DialogTitle>3D View</DialogTitle>
                                            <SuiBox
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                    paddingRight: 3,
                                                    flexDirection: 'columns',
                                                }}
                                            >
                                                <SuiButton
                                                    variant="outlined"
                                                    color="primary"
                                                    sx={{ width: 200, height: 'fit-content' }}
                                                    onClick={() => {
                                                        setOpenHelp(true)
                                                    }}
                                                >
                                                    How to use Viewer?
                                                </SuiButton>
                                            </SuiBox>
                                            <DialogContent>
                                                <SketchFab UID={open3D} items={product?.data?.data?.other_media.filter(e => Boolean(e.model_uid))} />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={close3DModal}>Close</Button>
                                            </DialogActions>
                                        </Dialog>
                                        <ViewerModal open={openHelp} setOpen={setOpenHelp} />
                                        <Dialog
                                            fullWidth={true}
                                            maxWidth="100%"                                                    
                                            open={Boolean(openWitsia)}
                                            onClose={closeWitsiaModal}
                                            style={{maxHeight: "100% !important"}}
                                        >
                                            <DialogTitle>Video</DialogTitle>
                                            <DialogContent>
                                                <WistiaPlayer
                                                videoId={openWitsia}
                                                wrapper="wistia-player-container-1" />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={closeWitsiaModal}>Close</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </List>
                                </Card>
                            </Box>
                        </Box>
                    </Modal>
                </>
            ) : (
                <>Loading..</>
            )}
        </>
    )
}

export default SinglePackage
