import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'

import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'

import Lottie from 'lottie-react'
import colorAnimation from 'assets/color.json'

import useApi from 'hooks/useApi'

import SinglePackage from '../../components/SinglePackage'
import { Box, Button, Skeleton, useMediaQuery } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import BundleComponent from 'components/BundleComponent'
import { useProfile } from 'context/profile'
import { useCart } from 'context/cart'

function WidgetLanding() {
    const [userPackages, getUserPackages] = useApi()
    const [allPackages, getAllPackages] = useApi()
    const [thisMonthProduct, getThisMonthProduct] = useApi()
    const [packagesList, setPackagesList] = useState(null)
    const [profile, getProfileEffect] = useProfile()
    const [openCart] = useCart()

    const isDownMd = useMediaQuery('(max-width: 900px)')

    const [modelItems, setModelItems] = useState([])
    const [modelId, setModelId] = useState(null)

    const handleShowModel = (models, model_id) => {
        setModelItems(models)
        if (model_id) {
            setModelId(model_id)
        }
    }

    useEffect(() => {
        if (profile) {
            refreshPackages()
        }
    }, [profile])

    useEffect(() => {
        if(!openCart) {
            refreshPackages()
        }
    }, [openCart])

    const refreshPackages = () => {
        getAllPackages('/products/product-list/')
    }

    useEffect(() => {
        if (allPackages?.data?.data?.length > 0) {
            getUserPackages('/products/product-list-auth/')
        }
    }, [allPackages?.data])

    useEffect(() => {
        console.log('userPackages', userPackages)
        var pList = allPackages.data?.data
        if (userPackages.data != 'undefined') {
            var pList = allPackages.data?.data?.map((item) => {
                item.in_repo = userPackages.data?.data?.map((uitem) => uitem.id).includes(item.id)
                return item
            })
            setPackagesList(pList)
        } else {
            setPackagesList(pList)
        }
    }, [userPackages?.data])
    useEffect(() => {
        if (packagesList?.length > 0) {
            var thisMonth = packagesList?.filter((item) => item.is_this_monthly_product)[0]
            if (!!thisMonth) {
                getThisMonthProduct('/products/' + thisMonth?.id)
            }
        }
    }, [packagesList?.length])
    return (
        <WidgetLandingLayout modelItems={modelItems} setModelItems={setModelItems} model_id={modelId}>
            <Box height="120px" />
            <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 3,
                    position: 'relative',
                    maxWidth: '1350px',
                    margin: 'auto',

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={4} marginBottom={isDownMd ? 4 : 0}>
                    <Typography fontSize={32} fontWeight={700}>
                        Bundles
                    </Typography>
                </Box>
                {allPackages.loading && [0, 1].map(i => <Skeleton variant="rectangular" width="100%" height={300} key={i} style={{ margin: "24px 0", borderRadius: "8px" }} />)}
                {!allPackages.loading && <>
                    {packagesList?.map((bundle, index) =>
                        <BundleComponent key={index} bundle={bundle} is_superuser={profile?.data?.data?.is_superuser} setModelItems={handleShowModel} refreshPackages={refreshPackages} />
                    )}
                </>}
            </SuiBox>
        </WidgetLandingLayout>
    )
}

export default WidgetLanding
