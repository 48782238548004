import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'
import { setReqWindow } from 'hooks/useApi'

import { SoftUIControllerProvider } from 'context'
import { AuthProvider } from 'context/auth'
import { SnackbarProvider } from 'notistack'
import { ProfileProvider } from 'context/profile'
import { LanguageProvider } from 'context/language'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

import * as serviceWorker from './serviceWorker'
// setReqWindow({
//     // prefix: 'https://digiartforever.com/api/V0.0.0',
//     // prefix: 'http://13.58.220.173:8000/api/V0.0.0',
//     prefix: 'http://127.0.0.1:8000/api/V0.0.0',
//     // media: 'https://digiartforever.com',
//     media: 'http://127.0.0.1:8000',
//     backend: 'https://digiartforever.com/api/V0.0.0',
//     // backend: 'http://13.58.220.173:8000/api/V0.0.0',
//     backend: 'http://127.0.0.1:8000/api/V0.0.0',
// })

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import "@fontsource/dm-sans";
import { CartProvider } from 'context/cart'

const stripePromise = loadStripe(
    // 'pk_live_51H5x25FAsiL0hSnjporKnXskd8iGwPRbbUmZlSo1NW5a7nOwzC1fWjixIU5ROO9sfgj9zfsyLlj7glEXmafRBSGg00iL7CQ4a3'
    'pk_test_51H5x25FAsiL0hSnjG2LrAyNls2zgZZjda0091XPtvxAAyNqxH52qLbHI24mQ3yiR0aZeNTKupNPA22fiscJUmlMm00H9ryn9Sk'
)
const options = {
    clientSecret: 'pi_3NxNRWFAsiL0hSnj0K7bKZBy_secret_Wy4pwTTCjmvOBEnP9NclfdoIs',
}
const paypalinitialOptions = {
    'client-id': 'AeBudGkTVFnOnP6hAJQorSg54eFm7MpbIKtIIPM-tEsudGD35MAriIce9LjdZ_hJNVuRzHw7_-7Vy_Na',
    currency: 'USD',
    intent: 'subscription',
    vault: true,
}
ReactDOM.render(
    <BrowserRouter>
        <LanguageProvider>
            <Elements stripe={stripePromise} options={options}>
                <SoftUIControllerProvider>
                    <SnackbarProvider>
                        <AuthProvider>
                            <ProfileProvider>
                                <CartProvider>
                                    <PayPalScriptProvider options={paypalinitialOptions}>
                                        <App />
                                    </PayPalScriptProvider>
                                </CartProvider>
                            </ProfileProvider>
                        </AuthProvider>
                    </SnackbarProvider>
                </SoftUIControllerProvider>
            </Elements>
        </LanguageProvider>
    </BrowserRouter>,
    document.getElementById('root')
)
// serviceWorker.register()
