import React, { useEffect, useRef, useState } from "react";

// Our wonderful chair model
const MODEL_UID = "c632823b6c204797bd9b95dbd9f53a06";

const useSketchfabViewer = (UID) => {
  // This ref will contain the actual iframe object
  const viewerIframeRef = useRef(null);
  const [api, setApi] = useState();
  const isMobile = window.innerWidth < 600

  const ViewerIframe = (
    <iframe
      // We feed the ref to the iframe component to get the underlying DOM object
      ref={viewerIframeRef}
      title="sketchfab-viewer"
      style={{ height: 'auto', width: '100%', minHeight: "60vh" }}
    />
  );
  useEffect(
    () => {
      // Initialize the viewer
      let client = new window.Sketchfab(viewerIframeRef.current);
      client.init(UID, {
        success: setApi,
        error: () => {
          console.log("Viewer error");
        },
      });
    },
    []
  );
  useEffect(()=>{
    console.log("uid",UID)
    let client = new window.Sketchfab(viewerIframeRef.current);
    client.init(UID, {
      success: setApi,
      error: () => {
        console.log("Viewer error");
      },
    });
  }, [UID])
  return [ViewerIframe, api];
};

export const Viewer = ({ apiRef, UID }) => {
  const [ViewerIframe, api] = useSketchfabViewer(UID);

  console.log(api, "aefrtsefgs");
  console.log(UID, "aefrtsefgs");

  apiRef.current = api;

  return ViewerIframe;
};
