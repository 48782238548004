import SuiBox from 'components/SuiBox'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Gallery from './Gallery'

import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'
import { Typography } from '@mui/material'

function SocialMedia() {
    return (
        <WidgetLandingLayout>
            <Grid>
                <Card style={{ padding: 25 }}>
                    <Typography variant="h3" px={4}>
                        Social Media
                    </Typography>
                    <Typography variant="body1" px={4} py={1}>
                        Share, like, network and build collections with our art-centric social media site, coming soon!
                    </Typography>
                    <SuiBox p={4}>
                        <Gallery />
                    </SuiBox>
                </Card>
            </Grid>
        </WidgetLandingLayout>
    )
}

export default SocialMedia
