import { createContext, useEffect, useContext, useState, } from 'react'

import useApi from 'hooks/useApi'
import { useAuth } from './auth'
import { useSnackbar } from 'notistack'

const Cart = createContext()

function CartProvider({ ...props }) {
    const [openCart, setOpenCart] = useState(false)

    return <Cart.Provider value={[openCart, setOpenCart]} {...props} />
}

function useCart() {
    return useContext(Cart)
}

export {
    CartProvider,
    useCart,
}