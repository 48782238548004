import Slider from '../Slider'
import useMediaQuery from '@mui/material/useMediaQuery';
export default () => {
    const isDownMd = useMediaQuery('(max-width: 600px)')
    return (
        <>
            <div
                style={{
                    ...isDownMd ? {marginLeft: 0} : {marginLeft: -200},
                    marginTop: 50,
                    borderRadius: 10,
                    boxShadow: '0 0 100px #bdbdbd',
                }}
            >
                <Slider />
            </div>
        </>
    )
}
