

import { forwardRef, useState } from "react";

import PropTypes from "prop-types";

// Custom styles for the SuiBadge
import SuiBadgeRoot from "components/SuiBadge/SuiBadgeRoot";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material";
import WistiaPlayer from "components/WitsiaPlayer";
import { mediaUrl } from "utils/constant";

const MediaTypes = {
  "3D MODELS": {
    label: "OBJ",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.5 7.27783L12 12.0001M12 12.0001L3.49997 7.27783M12 12.0001L12 21.5001M21 16.0586V7.94153C21 7.59889 21 7.42757 20.9495 7.27477C20.9049 7.13959 20.8318 7.01551 20.7354 6.91082C20.6263 6.79248 20.4766 6.70928 20.177 6.54288L12.777 2.43177C12.4934 2.27421 12.3516 2.19543 12.2015 2.16454C12.0685 2.13721 11.9315 2.13721 11.7986 2.16454C11.6484 2.19543 11.5066 2.27421 11.223 2.43177L3.82297 6.54288C3.52345 6.70928 3.37369 6.79248 3.26463 6.91082C3.16816 7.01551 3.09515 7.13959 3.05048 7.27477C3 7.42757 3 7.59889 3 7.94153V16.0586C3 16.4013 3 16.5726 3.05048 16.7254C3.09515 16.8606 3.16816 16.9847 3.26463 17.0893C3.37369 17.2077 3.52345 17.2909 3.82297 17.4573L11.223 21.5684C11.5066 21.726 11.6484 21.8047 11.7986 21.8356C11.9315 21.863 12.0685 21.863 12.2015 21.8356C12.3516 21.8047 12.4934 21.726 12.777 21.5684L20.177 17.4573C20.4766 17.2909 20.6263 17.2077 20.7354 17.0893C20.8318 16.9847 20.9049 16.8606 20.9495 16.7254C21 16.5726 21 16.4013 21 16.0586Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  },
  "PHOTOS": {
    label: "PHOTOS",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  },
  "VIDEO LESSON": {
    label: "VIDEO",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.5 8.96533C9.5 8.48805 9.5 8.24941 9.59974 8.11618C9.68666 8.00007 9.81971 7.92744 9.96438 7.9171C10.1304 7.90525 10.3311 8.03429 10.7326 8.29239L15.4532 11.3271C15.8016 11.551 15.9758 11.663 16.0359 11.8054C16.0885 11.9298 16.0885 12.0702 16.0359 12.1946C15.9758 12.337 15.8016 12.449 15.4532 12.6729L10.7326 15.7076C10.3311 15.9657 10.1304 16.0948 9.96438 16.0829C9.81971 16.0726 9.68666 15.9999 9.59974 15.8838C9.5 15.7506 9.5 15.512 9.5 15.0347V8.96533Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  },
  "PDF GUIDE": {
    label: "PDF",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  },
  "BRUSHES": {
    label: "BRUSHES",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  },
}

const MediaItem = forwardRef(
  ({ children, media, onViewModel, ...rest }, ref) => {

    const isDownMd = useMediaQuery('(max-width: 900px)')

    const mediaType = MediaTypes[media?.asset_type?.media_type];
    const [openWitsia, setOpenWitsia] = useState(false)

    const openWitsiaModal = () => {
      setOpenWitsia(true)
    }
    const closeWitsiaModal = () => {
      setOpenWitsia(false)
    }
    // const getEmbedUrl = async () => {
    //   if (media.model_uid) {
    //     const response = await fetch(`https://sketchfab.com/oembed?url=https://sketchfab.com/models/${media.model_uid}`, {
    //       method: 'GET',
    //     })
    //     const data = await response?.text();
    //     setImage(JSON.parse(data).thumbnail_url);
    //   }
    // }

    // getEmbedUrl();

    const handleClickAsset = () => {
      if (mediaType?.label === "OBJ") {
        onViewModel()
        return
      }
      if (mediaType?.label === "VIDEO") {
        openWitsiaModal()
        return
      }
      window.open(media.value, "_blank")
    }

    return (
      <Box width="100%" marginBottom="4px" display="flex" justifyContent="space-between" alignItems="center" >
        <Box display="flex" gap={isDownMd ? "8px" : "16px"} alignItems="center" maxWidth={isDownMd ? "200px" : "250px"} >
          <img src={media?.link?.replace(
            mediaUrl,
            'https://digiartforever.com'
          ) ?? "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"} alt="media" width={70} height={70} style={{ objectFit: "cover" }} />
          <Typography fontSize={14} fontWeight={400}>{media.name}</Typography>
        </Box>
        <Box display="flex" gap={isDownMd ? "0" : "12px"} alignItems="center">
          <Typography fontSize={12} fontWeight={500} textTransform="uppercase" color="rgba(0, 0, 0, 0.60)">{mediaType?.label ?? ""}</Typography>
          <Button onClick={handleClickAsset}>
            {mediaType?.icon}
          </Button>
        </Box>
        {mediaType?.label == 'VIDEO' && <Dialog
          fullWidth={true}
          maxWidth="100%"
          open={openWitsia}
          onClose={closeWitsiaModal}
          style={{ maxHeight: "100% !important" }}
        >
          <DialogTitle>Video</DialogTitle>
          <DialogContent>
            <WistiaPlayer
              videoId={media.value}
              wrapper="wistia-player-container-1" />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeWitsiaModal}>Close</Button>
          </DialogActions>
        </Dialog>}
      </Box>
    )
  }
);

export default MediaItem;
