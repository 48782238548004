    // const [supportedCoins, getSupportedCoins] = useApi()

    // useEffect(() => {
    //     const supportedCoinsEffect = async () => {
    //         try {
    //             await getDashboardData('/general/pair', {
    //                 method: 'GET',
    //             })
    //         } catch (e) {
    //             enqueueSnackbar(e?.data?.errors || strings.ERROR_SNACKBAR, {
    //                 variant: 'error',
    //             })
    //         }
    //     }
    //     supportedCoinsEffect()
    // }, [])
    
export const coins = {
    BTC: {
        name: 'Bitcoin',
        logo: 'https://bitcoin.org/img/icons/opengraph.png?1657703267',
        symbol: 'BTC',
        gradient: 'linear-gradient(45deg, rgb(255,35,1), rgb(255,166,7))',
        price: '28000',
        minimumExchange: '0.00001',
    },
    USDT: {
        name: 'Tether',
        logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
        symbol: 'USDT',
        gradient: 'linear-gradient(45deg, rgb(40	160	124	), rgb(10	122	67	))',
        price: '1',
        minimumExchange: '50',
    },
    LTC: {
        name: 'Litecoin',
        logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png',
        symbol: 'LTC',
        gradient: 'linear-gradient(45deg, rgb(51	90	151	), rgb(57, 64, 77))',
        price: '560',
        minimumExchange: '50',
    },
    ETH: {
        name: 'Ethereum',
        logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
        symbol: 'ETH',
        gradient: 'linear-gradient(45deg, rgb(31, 31, 31), rgb(145, 145, 145))',
        price: '1000',
        minimumExchange: '50',
    },
    SOL: {
        name: 'Solana',
        logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
        symbol: 'SOL',
        gradient: 'linear-gradient(45deg, rgb(145	74	250	), rgb(31	239	168	))',
        price: '120',
        minimumExchange: '50',
    },
    ADA: {
        name: 'Cardano',
        logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png',
        symbol: 'ADA',
        gradient: 'linear-gradient(45deg, rgb(46, 35, 196), rgb(20, 20, 112))',
        price: '56',
        minimumExchange: '50',
    },
    WAVES: {
        name: 'Waves',
        logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1274.png',
        symbol: 'WAVES',
        gradient: 'linear-gradient(45deg, rgb(5	80	242), rgb(46, 35, 196))',
        price: '23',
        minimumExchange: '50',
    },
}
