import aboutIllustration from 'assets/images/about-illustration2.png'

function Help() {
    return (
        <>
            <div style={{ display: 'flex', placeItems: 'center' }}>
                <img src={aboutIllustration} style={{ width: 200 }} />
                <div style={{ padding: 10 }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>Have a question?</div>
                    <div style={{ fontWeight: 'bold', fontSize: 28 }}>support@{window.config.domain}</div>
                </div>
            </div>
        </>
    )
}

export default Help
