import React, { useState, useEffect } from 'react'

import { Backdrop, Box, CircularProgress, Fade, Modal, Typography } from '@mui/material'
import SketchFab from '../../components/SketchFab'
import {useHistory} from 'react-router-dom'

import useApi from 'hooks/useApi'
import { useProfile } from 'context/profile'

function Viewer() {
    const [userAssets, getUserAssets] = useApi()
    const isMobile = window.innerWidth < 900
    const [open, setOpen] = useState(false)
    const [openInitModal, setOpenInitModal] = React.useState(false);
    const [profile, getProfileEffect] = useProfile()

    const history = useHistory();

    const handleCloseInitModal = () => setOpenInitModal(false);

    useEffect(() => {
        // getUserAssets('/products/product-list-auth/')
        getUserAssets('/products/other-medias-list-auth/')
    }, [])

    useEffect(() => {
        if (profile?.data?.data && !profile?.data?.data?.is_superuser && !profile?.data?.data?.is_active) {
            setOpenInitModal(true)
        }
    }, [profile])

    return (
        <Box style={{ backgroundColor: "black" }} minHeight="100vh" display="flex" justifyContent="center" alignItems="center" >
            {userAssets?.loading ? (
                <Box>
                    <CircularProgress />
                </Box>
            ) : (
                <SketchFab
                    UID={userAssets?.data?.data?.[0]?.value ?? '469393b90a084dd7bfae6f6e247109a9'}
                    // items={[{ title: 'test', uid: 'test' }]}
                    items={userAssets?.data?.data || []}
                    goToBack={() => history.goBack()}
                />
            )}
        </Box>
    )
}

export default Viewer
