import { createContext, useEffect, useContext, useState, useMemo } from 'react'

import { setReqWindow } from 'hooks/useApi'
import { useSnackbar } from 'notistack'

const Auth = createContext()

function AuthProvider({ ...props }) {
    const { enqueueSnackbar } = useSnackbar()

    const [auth, setAuth] = useState({
        accessToken: null,
        refreshToken: null,
        rememberMe: true,
    })

    useMemo(() => {
        try {
            const storageAuth = sessionStorage.getItem('auth')?.access
                ? sessionStorage.getItem('auth')
                : localStorage.getItem('auth')
            const parsedAuth = JSON.parse(storageAuth)
            setAuth(parsedAuth)
        } catch (e) {
            enqueueSnackbar('There was a problem in authentication.', {
                variant: 'error',
            })
        }
    }, [])

    useMemo(() => {
        if (!auth?.accessToken) {
            localStorage.removeItem('auth', null)
            sessionStorage.removeItem('auth', null)
            setReqWindow({
                headers: {
                    Authorization: null,
                },
            })
            return
        }
        setReqWindow({
            headers: {
                Authorization: `Bearer ${auth?.accessToken}`,
            },
        })
        if (auth?.rememberMe) {
            localStorage.setItem('auth', JSON.stringify(auth))
        } else {
            sessionStorage.setItem('auth', JSON.stringify(auth))
        }
    }, [auth])

    return <Auth.Provider value={[auth, setAuth]} {...props} />
}

function useAuth() {
    return useContext(Auth)
}

export { AuthProvider, useAuth }
