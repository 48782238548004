import { useEffect, useState } from 'react'

import { useLocation, NavLink, Link } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'

import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import useApi from 'hooks/useApi'
import CircularProgress from '@mui/material/CircularProgress'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useSoftUIController, setLayout } from 'context'
import { useAuth } from '../../../context/auth'
import { Box, TextField, Typography } from '@mui/material'
import SketchFab from 'components/SketchFab'
import { ShoppingBagOutlined } from '@mui/icons-material'
import { useCart } from 'context/cart'
import CartModal from 'components/CartModal'

const StyledTextField = styled(TextField)`
    background: transparent !important;
    margin: 5px 0;
    .MuiInputBase-root {
        background-color: rgba(0, 0, 0, 0.20) !important;
        border: none;
        border-radius: 8px;
    }
`;

const StyledMeunItem = styled(MenuItem)`
    border-bottom: 1px solid #E0E0E0;
    border-radius: 0 !important;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 500;
`

function WidgetLandingLayout({ children, hideFooter = false, modelItems, setModelItems, model_id }) {
    const isDownMd = useMediaQuery('(max-width: 600px)')
    const [controller, dispatch] = useSoftUIController()
    const { miniSidenav } = controller
    const { pathname } = useLocation()
    const [auth, setAuth] = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)
    const [isViewerPage, setIsViewerPage] = useState(false);

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        if (open) {
            setAnchorEl(null)
        }
        else {
            setAnchorEl(event.currentTarget)
        }
    }
    const handleSignOut = () => {
        setAuth({})
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    useEffect(() => {
        setLayout(dispatch, 'page')
    }, [pathname])

    useEffect(() => {
        if (location.pathname === '/viewer') {
            setIsViewerPage(true);
        }
        else {
            setIsViewerPage(false);
        }
    }, [])

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(2),
            width: isDownMd ? 'calc(100% - 48px)' : '180px',
            left: isDownMd ? '24px !important' : 'inherit',
            padding: 0,
            color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: isDownMd ? '0' : '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
        },
    }))

    const handleContactSupport = () => {
        window.location.href = 'mailto:support@rrhpublishing.com';
    };

    return modelItems?.length ? <SketchFab
        UID={model_id ?? (modelItems?.[0]?.value ?? '469393b90a084dd7bfae6f6e247109a9')}
        items={modelItems || []}
        goToBack={() => setModelItems([])}
    /> : (
        <Box minHeight="100vh" display="flex" flexDirection="column">
            {isDownMd ? <SuiBox sx={{
                width: 'calc(100% - 48px)',
                position: 'fixed',
                top: '16px',
                zIndex: 1000,
                background: 'white',
                borderRadius: '8px',
                left: '24px',
            }}>
                <SuiBox display="flex" alignItems="center" justifyContent="space-between" height="100%" paddingRight="20px">
                    <SuiBox component={NavLink} to="/" height="100%" borderRight="1px solid rgba(0, 0, 0, 0.12)" display="flex" alignItems="center">
                        <SuiBox
                            component="img"
                            src={process.env.PUBLIC_URL + window.config.logo}
                            alt="logo"
                            height="45px"
                            style={{ padding: "0 20px" }}
                        />
                    </SuiBox>

                    {auth?.accessToken ? (
                        <div>
                            <SuiButton
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="outlined"
                                color="dark"
                                style={{ border: 'none', background: "rgba(0, 9, 39, 0.40)", borderRadius: "40px", color: "white", padding: "0", minWidth: "42px", margin: '12px 0' }}
                                onClick={handleClick}
                                disableElevation
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M3 20C5.33579 17.5226 8.50702 16 12 16C15.493 16 18.6642 17.5226 21 20M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </SuiButton>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <Link to="/profile">
                                    <MenuItem disableRipple>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }} >
                                            <path d="M2.5 16.6667C4.44649 14.6021 7.08918 13.3333 10 13.3333C12.9108 13.3333 15.5535 14.6021 17.5 16.6667M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        View profile
                                    </MenuItem>
                                </Link>
                                    <MenuItem disableRipple onClick={handleContactSupport}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                                            <g clip-path="url(#clip0_55_9285)">
                                                <path d="M7.05306 7.05308L4.10679 4.10681M4.10679 15.8919L7.05308 12.9456M12.9456 12.9456L15.8919 15.8919M15.8919 4.10677L12.9456 7.05306M18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39698 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39698 5.39698 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39698 18.3327 9.99935ZM14.166 9.99935C14.166 12.3005 12.3005 14.166 9.99935 14.166C7.69816 14.166 5.83268 12.3005 5.83268 9.99935C5.83268 7.69816 7.69816 5.83268 9.99935 5.83268C12.3005 5.83268 14.166 7.69816 14.166 9.99935Z" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_55_9285">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Contact Support
                                    </MenuItem>

                                <MenuItem onClick={handleSignOut} disableRipple>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                                        <path d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Log Out
                                </MenuItem>
                            </StyledMenu>
                            <SuiButton
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="outlined"
                                color="dark"
                                style={{ border: 'none', background: "rgba(0, 9, 39, 0.40)", borderRadius: "40px", color: "white", padding: "0", minWidth: "42px", margin: '12px 0', marginLeft: '8px' }}
                                disableElevation
                                onClick={handleClick}
                            >
                                {open ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M3 8.5H21M3 15.5H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}
                            </SuiButton>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                style={{
                                    width: '100%',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <Link to="/bundles">
                                    <StyledMeunItem disableRipple style={{ color: pathname.startsWith("/bundles") ? "#436AF5" : "#67748e" }}>
                                        Bundles
                                    </StyledMeunItem>
                                </Link>
                                <Link to="/digital-books">
                                    <StyledMeunItem disableRipple style={{ color: pathname.startsWith("/digital-books") ? "#436AF5" : "#67748e" }}>
                                        Digital Books
                                    </StyledMeunItem>
                                </Link>
                                <Link to="/contests">
                                    <StyledMeunItem disableRipple style={{ color: pathname.startsWith("/contests") ? "#436AF5" : "#67748e" }}>
                                        Contests
                                    </StyledMeunItem>
                                </Link>
                                <Link to="/pricing">
                                    <StyledMeunItem disableRipple style={{ color: pathname.startsWith("/pricing") ? "#436AF5" : "#67748e" }}>
                                        Pricing
                                    </StyledMeunItem>
                                </Link>
                                <Link to="/media-viewer">
                                    <StyledMeunItem disableRipple style={{ color: pathname.startsWith("/media-viewer") ? "#436AF5" : "#67748e" }}>
                                        Media Viewer
                                    </StyledMeunItem>
                                </Link>
                            </StyledMenu>
                        </div>
                    ) : (
                        <Link to="/authentication/sign-in">
                            <SuiButton
                                variant="gradient"
                                color="info"
                                style={{ ...(isDownMd ? { marginLeft: -18 } : { marginLeft: 0 }) }}
                            >
                                Auth <span style={{ padding: 10 }} /> →
                            </SuiButton>
                        </Link>
                    )}
                </SuiBox>
            </SuiBox>
                :
                <SuiBox
                    sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                        position: 'fixed',
                        maxWidth: '1350px',
                        width: 'calc(100% - 48px)',
                        height: '80px',
                        top: "8px",
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        background: "white",
                        borderRadius: "8px",

                        [breakpoints.up('xl')]: {
                            transition: transitions.create(['margin-left', 'margin-right'], {
                                easing: transitions.easing.easeInOut,
                                duration: transitions.duration.standard,
                            }),
                        },
                    })}
                >
                    <SuiBox display="flex" alignItems="center" justifyContent="space-between" height="100%" paddingRight="20px">
                        <SuiBox component={NavLink} to="/" height="100%" borderRight="1px solid rgba(0, 0, 0, 0.12)" display="flex" alignItems="center">
                            <SuiBox
                                component="img"
                                src={process.env.PUBLIC_URL + window.config.logo}
                                alt="logo"
                                height="45px"
                                style={{ padding: "0 20px" }}
                            />
                        </SuiBox>

                        {auth?.accessToken ? (
                            <Box display="flex">
                                <Link to="/bundles">
                                    <SuiButton variant="text" color={pathname.startsWith("/bundles") ? "primary" : "black"} style={{ fontWeight: '500', letterSpacing: '0.5px', fontSize: '14px' }}>
                                        Bundles <span style={{ padding: 10 }} />
                                    </SuiButton>
                                </Link>
                                <div style={{ margin: 5 }} />
                                <Link to="/digital-books">
                                    <SuiButton variant="text" color={pathname.startsWith("/digital-books") ? "primary" : "black"} style={{ fontWeight: '500', letterSpacing: '0.5px', fontSize: '14px' }}>
                                        DigitalBooks <span style={{ padding: 10 }} />
                                    </SuiButton>
                                </Link>
                                <div style={{ margin: 5 }} />
                                <Link to="/contests">
                                    <SuiButton variant="text" color={pathname.startsWith("/contests") ? "primary" : "black"} style={{ fontWeight: '500', letterSpacing: '0.5px', fontSize: '14px' }}>
                                        Contests <span style={{ padding: 10 }} />
                                    </SuiButton>
                                </Link>
                                <div style={{ margin: 5 }} />
                                <Link to="/subscription">
                                    <SuiButton
                                        variant="text"
                                        color={pathname.startsWith("/subscription") ? "primary" : "black"} style={{ fontWeight: '500', letterSpacing: '0.5px', fontSize: '14px' }}
                                    >
                                        Subscription <span style={{ padding: 10 }} />
                                    </SuiButton>
                                </Link>
                                <div style={{ margin: 5 }} />
                                <Link to="/viewer">
                                    <SuiButton
                                        variant="text"
                                        color={pathname.startsWith("/viewer") ? "primary" : "black"} style={{ fontWeight: '500', letterSpacing: '0.5px', fontSize: '14px' }}
                                    >
                                        Viewer <span style={{ padding: 10 }} />
                                    </SuiButton>
                                </Link>
                            </Box>
                        ) : (
                            <></>
                        )}
                        {auth?.accessToken ? (
                            <div>
                                <SuiButton
                                    id="demo-customized-button"
                                    aria-controls={open ? 'demo-customized-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="outlined"
                                    color="dark"
                                    style={{ border: 'none', background: "rgba(0, 9, 39, 0.40)", borderRadius: "40px", color: "white", padding: "0 20px" }}
                                    disableElevation
                                    onClick={handleClick}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" style={{ marginRight: 4 }}>
                                        <path d="M2.5 17.1667C4.44649 15.1021 7.08918 13.8333 10 13.8333C12.9108 13.8333 15.5535 15.1021 17.5 17.1667M13.75 6.75C13.75 8.82107 12.0711 10.5 10 10.5C7.92893 10.5 6.25 8.82107 6.25 6.75C6.25 4.67893 7.92893 3 10 3C12.0711 3 13.75 4.67893 13.75 6.75Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Your Profile
                                </SuiButton>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <Link to="/profile">
                                        <MenuItem disableRipple>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }} >
                                                <path d="M2.5 16.6667C4.44649 14.6021 7.08918 13.3333 10 13.3333C12.9108 13.3333 15.5535 14.6021 17.5 16.6667M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            View profile
                                        </MenuItem>
                                    </Link>
                                        <MenuItem disableRipple onClick={handleContactSupport}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                                                    <g clip-path="url(#clip0_55_9285)">
                                                        <path
                                                            d="M7.05306 7.05308L4.10679 4.10681M4.10679 15.8919L7.05308 12.9456M12.9456 12.9456L15.8919 15.8919M15.8919 4.10677L12.9456 7.05306M18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39698 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39698 5.39698 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39698 18.3327 9.99935ZM14.166 9.99935C14.166 12.3005 12.3005 14.166 9.99935 14.166C7.69816 14.166 5.83268 12.3005 5.83268 9.99935C5.83268 7.69816 7.69816 5.83268 9.99935 5.83268C12.3005 5.83268 14.166 7.69816 14.166 9.99935Z"
                                                            stroke="currentColor" stroke-width="1.66667"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_55_9285">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                Contact Support
                                        </MenuItem>

                                    <MenuItem onClick={handleSignOut} disableRipple>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                                            <path d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Log Out
                                    </MenuItem>
                                </StyledMenu>
                            </div>
                        ) : (
                            <Link to="/authentication/sign-in">
                                <SuiButton
                                    variant="gradient"
                                    color="info"
                                    style={{ ...(isDownMd ? { marginLeft: -18 } : { marginLeft: 0 }) }}
                                >
                                    Auth <span style={{ padding: 10 }} /> →
                                </SuiButton>
                            </Link>
                        )}
                        <Button size='small' width="40px" style={{ maxWidth: '40px', minWidth: '40px', padding: 0, }}>
                            <CartModal />
                        </Button>
                    </SuiBox>
                </SuiBox>
            }
            <Box height="100%" flex={1} background="#EDF3FA">
                {children}
            </Box>
            {/* <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 3,
                    position: 'relative',
                    minHeight: '100vh',
                    maxWidth: isViewerPage ? 'none' : '1350px',
                    margin: 'auto',

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                {children}
            </SuiBox> */}
            {!hideFooter && <Box sx={{ background: "linear-gradient(91deg, #B65DF9 0%, #428DFA 100%)", padding: "60px", marginTop: "48px" }}>
                <SuiBox
                    sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                        maxWidth: '1350px',
                        margin: 'auto',
                        width: "100%",

                        [breakpoints.up('xl')]: {
                            transition: transitions.create(['margin-left', 'margin-right'], {
                                easing: transitions.easing.easeInOut,
                                duration: transitions.duration.standard,
                            }),
                        },
                    })}
                    display="flex"
                    flexWrap="wrap"
                    gap="16px"
                >
                    <Box sx={{ color: "white" }} flex={1}>
                        <Typography sx={{ fontSize: '36px', fontWeight: 700, color: '#ffffff' }}>
                            Keeping you updated.
                        </Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#ffffff' }}>
                            Discover exclusives, advices from experts, new bundles and contest announcements.
                        </Typography>
                    </Box>
                    <Box display="flex" flex={1} flexWrap="wrap">
                        <Box>
                            <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#ffffff' }}>
                                Your email address
                            </Typography>
                            <StyledTextField sx={{ background: "rgba(0, 0, 0, 0.20)", width: isDownMd ? "100%" : "inherit" }} />
                            <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#ffffff', marginTop: "12px", }}>
                                By applying to our newsletter you agree we can process your data so we can keep sending you newsletters. Read more about Privacy Policy.
                            </Typography>
                        </Box>
                        <Button style={{ minWidth: isDownMd ? '100%' : '180px', background: "white", marginTop: "20px", borderRadius: "40px", color: "black", padding: "12px 24px", display: "flex", alignItems: 'center' }}>
                            SUBSCRIBE NOW
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "4px" }}>
                                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Button>
                    </Box>
                </SuiBox>
            </Box>}

            <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    maxWidth: '1350px',
                    margin: 'auto',
                    width: "100%",

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >

                <Box sx={{ display: 'flex', flexDirection: isDownMd ? 'column-reverse' : 'row', alignItems: 'center', width: "100%", justifyContent: 'space-between', borderTop: '1px solid rgba(0, 0, 0, 0.10)', width: '100%', background: '#EDF3FA' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', padding: '16px' }}>
                        <Typography sx={{ color: 'rgba(0,0,0,.6)', fontSize: '12px' }}>
                            © 2023 DigiArt Forever
                        </Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                            Terms
                        </Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                            Privacy
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', padding: '16px', borderBottom: '1px solid rgba(0, 0, 0, 0.10)', width: isDownMd ? '100%' : 'auto' }} justifyContent="center">

                        <a href='https://www.instagram.com/rrhpub/' target='_blank' style={{ minWidth: '32px' }}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_115_5902)">
                                    <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z" fill="black" />
                                </g>

                                <defs>
                                    <clipPath id="clip0_115_5902">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>

                        <a href='https://www.artstation.com/redrighthandstudios6' target="_blank" style={{ minWidth: '32px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 24 20" fill="none">
                                <path d="M0.728516 15.4221L2.63566 18.7221C3.02137 19.4721 3.7928 19.9864 4.67137 19.9864H17.3785L14.7428 15.4221H0.728516Z" fill="black" />
                                <path d="M23.3997 15.4434C23.3997 14.9934 23.2711 14.5648 23.0354 14.2005L15.5782 1.25768C15.1925 0.529112 14.4425 0.0362549 13.564 0.0362549H9.62109L21.1282 19.9648L22.9497 16.8148C23.2925 16.2148 23.3997 15.9577 23.3997 15.4434Z" fill="black" />
                                <path d="M12.8785 12.1647L7.75711 3.27185L2.61426 12.1647H12.8785Z" fill="black" />
                            </svg>
                        </a>

                        <a href='#' style={{ minWidth: '32px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 22 24" fill="none">
                                <path d="M8.88806 10.068C8.20406 10.068 7.66406 10.668 7.66406 11.4C7.66406 12.132 8.21606 12.732 8.88806 12.732C9.57206 12.732 10.1121 12.132 10.1121 11.4C10.1241 10.668 9.57206 10.068 8.88806 10.068ZM13.2681 10.068C12.5841 10.068 12.0441 10.668 12.0441 11.4C12.0441 12.132 12.5961 12.732 13.2681 12.732C13.9521 12.732 14.4921 12.132 14.4921 11.4C14.4921 10.668 13.9521 10.068 13.2681 10.068Z" fill="black" />
                                <path d="M19.1005 0H3.02055C1.66455 0 0.560547 1.104 0.560547 2.472V18.696C0.560547 20.064 1.66455 21.168 3.02055 21.168H16.6285L15.9925 18.948L17.5285 20.376L18.9805 21.72L21.5605 24V2.472C21.5605 1.104 20.4565 0 19.1005 0ZM14.4685 15.672C14.4685 15.672 14.0365 15.156 13.6765 14.7C15.2485 14.256 15.8485 13.272 15.8485 13.272C15.3565 13.596 14.8885 13.824 14.4685 13.98C13.8685 14.232 13.2925 14.4 12.7285 14.496C11.5765 14.712 10.5205 14.652 9.62055 14.484C8.93655 14.352 8.34855 14.16 7.85655 13.968C7.58055 13.86 7.28055 13.728 6.98055 13.56C6.94455 13.536 6.90855 13.524 6.87255 13.5C6.84855 13.488 6.83655 13.476 6.82455 13.464C6.60855 13.344 6.48855 13.26 6.48855 13.26C6.48855 13.26 7.06455 14.22 8.58855 14.676C8.22855 15.132 7.78455 15.672 7.78455 15.672C5.13255 15.588 4.12455 13.848 4.12455 13.848C4.12455 9.984 5.85255 6.852 5.85255 6.852C7.58055 5.556 9.22455 5.592 9.22455 5.592L9.34455 5.736C7.18455 6.36 6.18855 7.308 6.18855 7.308C6.18855 7.308 6.45255 7.164 6.89655 6.96C8.18055 6.396 9.20055 6.24 9.62055 6.204C9.69255 6.192 9.75255 6.18 9.82455 6.18C10.5565 6.084 11.3845 6.06 12.2485 6.156C13.3885 6.288 14.6125 6.624 15.8605 7.308C15.8605 7.308 14.9125 6.408 12.8725 5.784L13.0405 5.592C13.0405 5.592 14.6845 5.556 16.4125 6.852C16.4125 6.852 18.1405 9.984 18.1405 13.848C18.1405 13.848 17.1205 15.588 14.4685 15.672Z" fill="black" />
                            </svg>
                        </a>
                    </Box>
                </Box>
            </SuiBox>
        </Box>
    )
}

// Typechecking props for the DashboardLayout
WidgetLandingLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default WidgetLandingLayout
