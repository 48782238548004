import { motion } from 'framer-motion'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'

import Convert from './components/Convert'
import PriceTable from './components/PriceTable'
import Pricing from './components/Pricing'
import Help from './components/Help'

import { useEffect, useState } from 'react'
import useApi from 'hooks/useApi'
import { Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useProfile } from 'context/profile'
function Subscription() {
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const [flag, setFlag] = useState(true)
    const [cancelSubscription, cancelSubscriptionReq] = useApi()
    const [profile, getProfileEffect] = useProfile()

    const isDownMd = useMediaQuery('(max-width: 900px)')

    const handleCancelSubscription = () => {
        try {
            cancelSubscriptionReq('/subscription/cancel-subscription/')
            enqueueSnackbar('Your Subscription has been cancelled', { variant: 'success', autoHideDuration: 2000, })
        } catch (err) {
            setFlag(false)
            enqueueSnackbar(err.message, { variant: 'error' })
            return
        }
    }
    useEffect(() => {
        if (cancelSubscription?.data) {
            history.go(0)
            setFlag(false)
        }
    }, [cancelSubscription])
    return (
        <WidgetLandingLayout hideFooter={true}>
            <img src="triangles/triangle1.png" width={isDownMd ? "275" : "375"} height={isDownMd ? "275" : "375"} alt="triangle1" style={{ position: "fixed", left: isDownMd ? "-75px" : "-100px", top: "250px" }} />
            <img src="triangles/triangle2.png" width={isDownMd ? "160" : "240"} height={isDownMd ? "160" : "240"} alt="triangle1" style={{ position: "fixed", left: isDownMd ? "150px" : "325px", top: isDownMd ? "100px" : "80px" }} />
            <img src="triangles/triangle3.png" width={isDownMd ? "200" : "300"} height={isDownMd ? "200" : "300"} alt="triangle1" style={{ position: "fixed", right: isDownMd ? "-75px" : "0px", bottom: isDownMd ? "180px" : "120px" }} />
            <Box height="80px" />
            {profile?.data?.data?.is_active && flag ? (
                <SuiBox
                    sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                        px: 3,
                        position: 'relative',
                        maxWidth: '1350px',
                        margin: 'auto',

                        [breakpoints.up('xl')]: {
                            transition: transitions.create(['margin-left', 'margin-right'], {
                                easing: transitions.easing.easeInOut,
                                duration: transitions.duration.standard,
                            }),
                        },
                    })}
                >
                    <Box style={{ background: "white" }} margin="0 auto" marginTop="30px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="12px" borderRadius="8px" minHeight="260px">
                        <Typography variant="h3" component="h4" mb={5}>
                            You are already subscribed!
                        </Typography>
                        <SuiButton
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                handleCancelSubscription()
                            }}
                        >
                            {cancelSubscription.loading ? <CircularProgress /> : 'Cancel Subscription'}
                        </SuiButton>
                    </Box>
                </SuiBox>
            ) : (
                <SuiBox p={4} style={{ position: 'relative' }} zIndex={2}>
                    <Box style={{ background: "white" }} margin="0 auto" marginTop="30px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="12px" borderRadius="8px" minHeight="260px">
                        <Typography variant="h3" component="h4" mb={5}>
                        We're about to launch our subscription plans!
                        </Typography>
                    </Box>

                    {/* <Pricing /> */}
                    <Typography fontSize={15} fontWeight={400} color="#00000099" marginTop={2} textAlign="center" >
                        Have a question or need a support? Reach out to us.
                    </Typography>
                    <a href='mailto:support@rrhpublishing.com' >
                        <Typography fontSize={15} fontWeight={400} color="#436AF5" marginBottom={2} textAlign="center">
                            support@rrhpublishing.com
                            {/*support@digiartforever.com*/}
                        </Typography>
                    </a>

                </SuiBox>
            )}
        </WidgetLandingLayout>
    )
}

export default Subscription
