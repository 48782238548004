import { useEffect, useState } from 'react'
import Pricify from '@chargebee/atomicpricing';
import { makeStyles } from '@mui/styles'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import { useProfile } from 'context/profile';
const useStyles = makeStyles((theme) => ({
    section: {
        backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    cardHeader: {
        paddingTop: theme.spacing(3),
        fontSize: '4rem',
    },
}))

function Pricing({ pay, payAmount = '3' }) {
    const history = useHistory()
    const classes = useStyles()
    const [profile] = useProfile()

    useEffect(() => {
        // if(profile?.data?.data?.is_initial_subscriber) {
        //
        // }
        Pricify.init();

    }, [profile?.data?.data?.is_initial_subscriber]);
    const handleClickMonthly = () => {
        history.push('/paymentMethod/subscription/price_1MpCu6GU43GygouQ6ykq1eXX')
    }
    const handleClickYearly = () => {
        history.push('/paymentMethod/subscription/price_1MpJuuGU43GygouQKelIFjkp')
    }

    const content = {
        badge: 'Digiart Forever subscription',
        'header-p': 'Digiart Forever subscription',
        description: 'Unleash your creative potential with the best deal for artists on the entire Internet.',
        option1: 'Monthly',
        option2: 'Annual',
        '01_title': 'Monthly bundle',
        '01_price': '$5',
        '01_suffix': ' / mo',
        '01_benefit1': '',
        '01_benefit2': '',
        '01_benefit3': '',
        '01_benefit4': '',
        '01_primary-action': 'Get started',
        '01_secondary-action': 'Learn more',
        '02_title': 'Yearly bundle',
        '02_price': '$49',
        '02_suffix': ' / y',
        '02_benefit1': '',
        '02_benefit2': '',
        '02_benefit3': '',
        '02_benefit4': '',
        '02_primary-action': 'Get started',
        '02_secondary-action': 'Learn more',
    }

    const [state, setState] = useState({
        checkbox: true,
    })
    const handleChange = (event) => {
        setState({ ...state, checkbox: event.target.checked })
    }
    return (
        <section className={classes.section}>
            <Container maxWidth="lg">
                <Box py={3} textAlign="center">
                    <Box mb={1}>
                        <Container maxWidth="sm">
                            <Typography variant="h3" component="h2" gutterBottom={true}>
                                <Typography variant="h3" component="span">
                                    {content['header-p']}
                                </Typography>
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" paragraph={true} maxWidth={500}
                                        margin="auto">
                                {content['description']}
                            </Typography>

                        </Container>
                    </Box>
                    {/* <Grid container spacing={3} style={{ paddingTop: 15 }}>
                        <Grid item xs={12} md={6}>
                            <Card
                                variant="outlined"
                                sx={{
                                    borderRadius: 10,
                                    border: '1px solid #ccc',
                                    transition: '1s',
                                    '&:hover': {
                                        border: '1px dashed #111',
                                    },
                                }}
                            >
                                <CardHeader title={content['01_title']} className={classes.cardHeader}></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            {content['01_price']}
                                            <Typography variant="h6" color="textSecondary" component="span">
                                                {content['01_suffix']}
                                            </Typography>
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">
                                            {content['01_benefit1']}
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">
                                            {content['01_benefit2']}
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">
                                            {content['01_benefit3']}
                                        </Typography>
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle1"
                                            component="p"
                                            paragraph={true}
                                        >
                                            {content['01_benefit4']}
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        style={{ borderRadius: 20, color: 'black' }}
                                        color="black"
                                        onClick={() => handleClickMonthly()}
                                        className={classes.primaryAction}
                                    >
                                        {content['01_primary-action']}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card
                                variant="outlined"
                                sx={{
                                    borderRadius: 10,
                                    background: 'rgb(2,0,36)',
                                    background:
                                        'linear-gradient(33deg, rgba(219,214,111,1) 0%, rgba(117,121,9,0.31416316526610644) 35%, rgba(0,212,255,1) 100%)',
                                    backdropFilter: 'blur(10px)',
                                    border: '2px solid #888',
                                    transition: '1s',
                                    '&:hover': {
                                        border: '2px dashed #444',
                                    },
                                }}
                            >
                                <CardHeader title={content['02_title']} className={classes.cardHeader}></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            {content['02_price']}
                                            <Typography variant="h6" color="textSecondary" component="span">
                                                {content['02_suffix']}
                                            </Typography>
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">
                                            {content['02_benefit1']}
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">
                                            {content['02_benefit2']}
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">
                                            {content['02_benefit3']}
                                        </Typography>
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle1"
                                            component="p"
                                            paragraph={true}
                                        >
                                            {content['02_benefit4']}
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ borderRadius: 20, color: '#eee' }}
                                        onClick={() => handleClickYearly()}
                                    >
                                        {content['02_primary-action']}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid> */}


                    {profile?.data?.data?.account_status === 'new' &&
                        <div
                            id="pricify-hosted-pricing-page"
                            data-pricify-site="01HCX55YXPFCD7MGP4ZQ6328CW"
                            data-pricify-pricingpage="01HCX55ZM1C5YS94DDWKEB5FH2"
                            data-pricify-viewport-defaultheight="413.59375px"
                            data-pricify-autoselectlocalcurrency="true"
                            data-pricify-showcurrencydropdown="false"
                        ></div>
                    }


                    {profile?.data?.data?.account_status === 'old' &&
                        <>
                            {profile?.data?.data?.is_initial_subscriber === 'monthly' &&
                                <div
                                    id="pricify-hosted-pricing-page"
                                    data-pricify-site="01HCYR58RKX8VYD488Y5EPV8YH"
                                    data-pricify-pricingpage="01HNNA64KTNX07AC8FZT7SPJ8B"
                                    data-pricify-viewport-defaultheight="413.59375px"
                                    data-pricify-autoselectlocalcurrency="true"
                                    data-pricify-showcurrencydropdown="false"
                                ></div>
                            }
                            {profile?.data?.data?.is_initial_subscriber === 'annual' &&
                                <div
                                    id="pricify-hosted-pricing-page"
                                    data-pricify-site="01HCYR58RKX8VYD488Y5EPV8YH"
                                    data-pricify-pricingpage="01HNNDAWHFY23CER5T1SRC1Q9F"
                                    data-pricify-viewport-defaultheight="413.59375px"
                                    data-pricify-autoselectlocalcurrency="true"
                                    data-pricify-showcurrencydropdown="false"
                                ></div>
                            }
                        </>
                    }
                </Box>
            </Container>
        </section>
    )
}

export default Pricing
