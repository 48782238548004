

import { forwardRef, useState } from "react";

// Custom styles for the SuiBadge
import { Box, Button, Typography } from "@mui/material";
import { mediaUrl } from "utils/constant";

const MediaComponent = forwardRef(
  ({ children, media, ...rest }, ref) => {

    // const getEmbedUrl = async () => {
    //   if (media.model_uid) {
    //     const response = await fetch(`https://sketchfab.com/oembed?url=https://sketchfab.com/models/${media.model_uid}`, {
    //       method: 'GET',
    //     })
    //     const data = await response?.text();
    //     setImage(JSON.parse(data).thumbnail_url);
    //   }
    // }

    // getEmbedUrl();

    return (
      <Box width={170} marginBottom={1} position='relative'>
        <img src={media.link?.replace(
          mediaUrl,
          'https://digiartforever.com'
        ) ?? "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"} alt="media" width={170} height={170} style={{ objectFit: "cover", borderRadius: "8px", boxShadow: "0px 10.38px 20.76px 0px rgba(0, 0, 0, 0.20)" }} />
        {(media?.asset_count > 1 || media.assets?.length > 1) && <Box style={{ position: 'absolute', top: '8px', right: '8px', background: 'white', borderRadius: '40px', color: 'black', fontSize: '14px', fontWeight: 700, padding: '0 12px' }}>{media.asset_count ?? media.assets?.length}</Box>}
        <Box>
          <Typography fontSize={12} fontWeight={500} marginTop={2} textTransform="uppercase" color="rgba(0, 0, 0, 0.60)">{media.asset_type?.media_type ?? ""}</Typography>
          <Typography fontSize={18} fontWeight={500}>{media.title}</Typography>
        </Box>
      </Box >
    )
  }
);

export default MediaComponent;
