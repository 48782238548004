import React, { memo, useEffect } from 'react'

// import { PREFIX } from './use_api'
// import { use_slider_api } from './use_slider_api'

import { useMediaQuery } from '@mui/material'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import pixelArt1 from '../../../assets/images/p1.webp'
import pixelArt2 from '../../../assets/images/p2.webp'
import pixelArt3 from '../../../assets/images/p3.webp'
import pixelArt4 from '../../../assets/images/p4.png'
import pixelArt5 from '../../../assets/images/p5.webp'

export default memo(() => {
    const isDownMd = useMediaQuery('(max-width: 600px)')

    // const [slides, get_slides] = use_slider_api()

    // useEffect(() => {
    //     get_slides()
    // }, [])

    let mapped_slides = [
        // {
        //     title: '',
        //     target: '',
        //     link: '',
        //     image_desktop: pixelArt1,
        // },
        // {
        //     title: 'Get skull learning packages.',
        //     target: '',
        //     link: '',
        //     image_desktop: pixelArt2,
        // },
        {
            title: 'The power, The art, Forever.',
            target: '',
            link: '',
            image_desktop: pixelArt3,
        },
        {
            title: '',
            target: '',
            link: '',
            image_desktop: pixelArt4,
        },
        {
            title: 'Get to know the world of Amelie...',
            target: '',
            link: '',
            image_desktop: pixelArt5,
        },
    ]

    // mapped_slides.map((slide) => {
    //     return {
    //         title: slide.title,
    //         target: slide.target,
    //         link: slide.link,
    //         image_desktop: PREFIX + "/" + slide.desktop_image,
    //         mobile_image: PREFIX + '/' + slide.mobile_image,
    //     }
    // })

    return (
        <div dir="ltr">
            <Carousel dynamicHeight infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                {mapped_slides.map((slide) => {
                    const HOC = ({ children }) =>
                        slide.link.includes('http') ? (
                            <a
                                target={slide.target || '_blank'}
                                rel="norefferer noopener"
                                href={slide.link}
                                style={{ zIndex: -1 }}
                            >
                                {children}
                            </a>
                        ) : (
                            <div style={{ zIndex: -1 }}>
                                {/* <Link href={slide.link}> */}
                                <div style={{ cursor: 'pointer' }}>{children}</div>
                                {/* </Link> */}
                            </div>
                        )

                    return (
                        <HOC key={slide.image_desktop}>
                            <div style={{ position: 'relative', zIndex: -1 }}>
                                <h1
                                    style={{
                                        color: 'white',
                                        position: 'absolute',
                                        left: '7%',
                                        top: '15%',
                                        maxWidth: '50%',
                                    }}
                                >
                                    {slide.title}
                                </h1>
                                <img
                                    src={
                                        (isDownMd ? slide.mobile_image : slide.image_desktop) ||
                                        'https://www.onlygfx.com/wp-content/uploads/2017/12/grunge-yes-no-icon-6-1024x1022.png'
                                    }
                                    style={{ zIndex: -1, maxHeight: 800, borderRadius: 10, objectFit: 'cover' }}
                                />
                            </div>
                        </HOC>
                    )
                })}
            </Carousel>
        </div>
    )
})
