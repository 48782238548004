import * as React from 'react'
import { useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function ViewerModal({ open, setOpen }) {
    const handleClose = () => {
        setOpen(false)
    }
    const handleAccept = () => {
        setOpen(false)
    }

    return (
        <div>
            <Dialog
                fullWidth="lg"
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">How to use viewer</DialogTitle>
                <DialogContent dividers={'paper'}>
                    <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                        <li>
                            <span style={{ fontWeight: 'bold' }}>Orbit around: </span>Left click + drag or One finger
                            drag (touch)
                        </li>
                        <li>
                            <span style={{ fontWeight: 'bold' }}>Zoom: </span>Double click on model or scroll anywhere
                            or Pinch (touch) drag (touch)
                        </li>
                        <li>
                            <span style={{ fontWeight: 'bold' }}>Pan: </span>Right click + drag or Two fingers drag
                            (touch)
                        </li>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
