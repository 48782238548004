import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_test_51H5x25FAsiL0hSnjG2LrAyNls2zgZZjda0091XPtvxAAyNqxH52qLbHI24mQ3yiR0aZeNTKupNPA22fiscJUmlMm00H9ryn9Sk');
    // stripePromise = loadStripe('pk_live_51H5x25FAsiL0hSnjporKnXskd8iGwPRbbUmZlSo1NW5a7nOwzC1fWjixIU5ROO9sfgj9zfsyLlj7glEXmafRBSGg00iL7CQ4a3');
  }
  return stripePromise;
};

export default getStripe