

import { forwardRef, useEffect, useState } from "react";


import { useCart } from "context/cart";
import { Box, Button, Card, Modal, Typography, useMediaQuery } from "@mui/material";
import useApi from 'hooks/useApi'
import { useAuth } from "context/auth";
import { CloseOutlined, ShoppingBagOutlined } from "@mui/icons-material";
import SuiButton from "components/SuiButton";
import { useHistory, useLocation } from 'react-router-dom'
import { useProfile } from "context/profile";
import { mediaUrl } from "utils/constant";

const CartModal = forwardRef(
  ({ me }, ref) => {
    const [openCart, setOpenCart] = useCart()
    const isDownMd = useMediaQuery('(max-width: 900px)')
    const [cartItems, getCartItems] = useApi();
    const [removeStatus, removeCartItem] = useApi();
    const [checkoutCartState, checkoutCart] = useApi()
    const [confirmCheckoutState, confirmCheckout] = useApi()
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const location = useLocation();
    const [token, setToken] = useState(null)
    let searchParams = new URLSearchParams(location.search);
    const paramToken = searchParams.get('token')
    const [, getProfileEffect] = useProfile()

    const [auth] = useAuth();

    const handleCloseModal = () => {
      setOpenCart(false)
    }

    useEffect(() => {
      if (auth?.accessToken) {
        getCartItems('/accounts/cart-items/')
      }
    }, [auth])

    useEffect(() => {
      if (paramToken && paramToken != token) {
        setToken(paramToken)
      }
      history.push({ ...location, search: '' })
    }, [paramToken])

    const handleOpenCart = () => {
      setOpenCart(true)
    }

    const totalAmount = cartItems?.data?.data?.reduce((acc, item) => {
      return acc + item.price
    }, 0)

    useEffect(() => {
      if (token) {
        confirmCheckout('/subscription/confirm-checkout/', {
          method: 'POST',
          body: {
            token: token
          }
        })
      }
    }, [token])

    useEffect(() => {
      if (confirmCheckoutState.data?.ok) {
        getProfileEffect()
        getCartItems('/accounts/cart-items/')
      }
    }, [confirmCheckoutState])

    useEffect(() => {
      if (checkoutCartState.data?.ok) {
        window.location.href = checkoutCartState.data?.data?.redirect_url
      }
    }, [checkoutCartState])

    const handleRemoveCartItem = (item_id, item_type) => {
      removeCartItem(`/accounts/cart-items/`, {
        method: 'DELETE',
        body: {
          item_id,
          item_type
        }
      })
    }

    useEffect(() => {
      if (openCart) {
        getCartItems('/accounts/cart-items/')
      }
    }, [openCart])

    useEffect(() => {
      if (removeStatus?.data?.ok) {
        getCartItems('/accounts/cart-items/')
      }
    }, [removeStatus])

    const handleCreateCheckout = () => {
      setLoading(true)
      checkoutCart('/subscription/create-cart-checout/', {
        method: 'POST',
        body: {
          coupon: '',
          items: cartItems?.data?.data?.map(item => item.product_id),
          callbackUrl: `${window.location.origin}${location.pathname}`,
        },
      })
    }

    return (
      <Box position='relative'>
        <ShoppingBagOutlined style={{ width: '32px', height: '32px', color: '#000' }} onClick={handleOpenCart} />
        <Box onClick={handleOpenCart} position='absolute' bottom='4px' right='4px' width='14px' height="14px" fontSize='8px' borderRadius='50%' display='flex' justifyContent='center' alignItems='center' style={{ backgroundColor: '#333333', color: 'white' }}>
          {cartItems?.data?.data?.length ?? 0}
        </Box>
        <Modal
          open={openCart}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: isDownMd ? '30px' : '0',
            right: '0',
            width: isDownMd ? '100%' : '640px',
            bgcolor: '#EDF3FA',
            p: isDownMd ? 2 : 5,
            bottom: '0',
            display: 'flex',
            flexDirection: 'column',
          }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2" fontSize={28} fontWeight={700} >
                Your Cart ({cartItems?.data?.data?.length ?? 0})
              </Typography>
              <Button style={{ padding: 0, minWidth: "auto" }} onClick={handleCloseModal} >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Button>
            </Box>
            <Card sx={{ padding: '36px 18px', background: 'transparent', boxShadow: 'none', flex: 1, overflowY: 'auto' }}>
              {cartItems?.data?.data?.map((item, index) => (
                <Box key={index} display='flex' gap="12px" margin="12px 0">
                  <img src={item?.link?.replace(
                    mediaUrl,
                    'https://digiartforever.com'
                  )} alt={item?.title} width={120} height={160} />
                  <Box padding="0 12px" flex={1}>
                    <Typography fontSize={24} fontWeight={700}>{item?.title}</Typography>
                    <Typography fontSize={16} fontWeight={400} style={{ color: '#333' }}>${item?.price}</Typography>
                  </Box>
                  <Box>
                    <CloseOutlined style={{ cursor: 'pointer', color: '#333' }} onClick={() => handleRemoveCartItem(item.id, item.item_type)} />
                  </Box>
                </Box>
              ))}
            </Card>
            <Typography style={{ margin: '4px 0', marginTop: '16px', }} fontSize='20px' fontWeight={600}>
              Total: ${totalAmount ?? 0}
            </Typography>
            <SuiButton style={{ fontSize: '18px' }} disabled={!cartItems?.data?.data?.length || loading} color="primary" onClick={handleCreateCheckout}>Checkout</SuiButton>
          </Box>
        </Modal>
      </Box>
    )
  }
);

export default CartModal;
