import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SuiBox from 'components/SuiBox'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useState } from 'react'
import useApi from 'hooks/useApi'

import { useSnackbar } from 'notistack'
import { useAuth } from 'context/auth'
import { useLang } from 'context/language'

import { Link } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'
import { useHistory } from 'react-router-dom'
// Authentication layout components

import Cover from '../../../assets/images/cover.png'
import { InputLabel, useMediaQuery } from '@mui/material'
import { Apple, ArrowForward, Google } from '@mui/icons-material'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://digiartforever.com/">
                DigiartForever
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const theme = createTheme()

const SignInSide = () => {
    const hisotry = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [, setAuth] = useAuth()
    const [{ strings }] = useLang()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [rememberMe, setRememberMe] = useState(true)

    const [loginResponse, postLogin] = useApi()

    const isDownMd = useMediaQuery('(max-width: 600px)')

    const onEmail = (e) => {
        setEmail(e.target?.value)
    }

    const onPassword = (e) => {
        setPassword(e.target?.value)
    }

    const handleSubmit = async () => {
        if (!email) {
            enqueueSnackbar('Please enter your business email.', {
                variant: 'info',
                autoHideDuration: 2000,
            })
            return
        }
        if (!password) {
            enqueueSnackbar('Please enter your password.', {
                variant: 'info',
                autoHideDuration: 2000,
            })
            return
        }

        try {
            const data = await postLogin('/accounts/login/', {
                method: 'POST',
                body: {
                    email,
                    password,
                },
                headers: {
                    Authorization: null,
                },
            })
            console.log(data, rememberMe)
            setAuth({
                accessToken: data?.access,
                refreshToken: data?.refresh,
                rememberMe,
            })
            enqueueSnackbar(' Login successful!', {
                variant: 'success',
                autoHideDuration: 2000,
            })
            if(data?.is_initial_subscriber) {
                hisotry.push('/subscription')
                return
            }
            hisotry.push('/')
        } catch (e) {
            if (e) {
                console.log(e)
                enqueueSnackbar(e?.data?.detail || 'Something went wrong!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                })
            }
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <SuiBox style={{
                display: 'flex',
            }}>
                {!isDownMd && <SuiBox
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end',
                        paddingRight: 30,
                        alignItems: 'center',
                        height: '100vh',
                        backgroundImage: `url(${Cover})`,
                        objectFit: 'fill',
                        backgroundRepeat: 'space',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                </SuiBox>}
                <SuiBox
                    borderRadius={10}
                    style={{
                        flex: 1,
                        background: '#EDF3FA',
                        backdropFilter: 'blur(1px)',
                        borderRaduis: '10px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'relative',
                            height: '100vh',
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            right: '50%',
                            transform: 'translateX(50%)',
                            background: 'white',

                        }}>
                            <img src={process.env.PUBLIC_URL + window.config.logo} alt="logo" style={{ height: 70 }} />
                        </Box>
                        <Typography component="h2" variant="h6" sx={{marginTop: '90px', fontSize: '12px',fontWeight: 500, textTransform: 'uppercase', letterSpacing: '1.5px'}}>
                            Level up your skills today.
                        </Typography>
                        <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography align='center' component="h1" sx={{fontSize: '42px', fontWeight: 700}}>
                                Welcome back.
                            </Typography>
                            <Typography align='center' component="p" sx={{color: 'rgba(0, 0, 0, 0.60)', fontSize: '15px', fontWeight: 400,}}>
                                New to DigiArt Forever?
                                <Link to="/authentication/sign-up" style={{marginLeft: '4px', color: '#436AF5'}}>Sign up</Link>
                            </Typography>
                            <Box noValidate sx={{maxWidth: '400px', margin: '0 20px', marginTop: '20px'}}>
                                <InputLabel htmlFor="email" sx={{margin: '6px 0'}}>Email Address</InputLabel>
                                <TextField
                                    onChange={onEmail}
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    inputProps={{
                                        style: {
                                            padding: '8px 10px'
                                        }
                                    }}
                                    autoFocus
                                />
                                <InputLabel htmlFor="password" sx={{margin: '6px 0', marginTop: '20px'}}>Password</InputLabel>
                                <TextField
                                    onChange={onPassword}
                                    required
                                    fullWidth
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    inputProps={{
                                        style: {
                                            padding: '8px 10px'
                                        }
                                    }}
                                />
                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '12px 0'}}>
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" iconStyle={{opacity: .2}} />}
                                        label="Remember me"
                                        sx={{color: 'rgba(0,0,0,.6)'}}
                                    />
                                    <Link to="/authentication/forgot-password" style={{color: '#436AF5', fontSize: '14px'}}>
                                        Forgot password?
                                    </Link>
                                </Box>
                                <Button fullWidth variant="contained" onClick={handleSubmit} sx={{ borderRadius: '32px', background: '#436AF5', paddingY: '10px' }}>
                                    {loginResponse.loading ? 'Loading' : 'LOG IN'} <ArrowForward sx={{marginLeft: '4px'}} />
                                </Button>
                                <Typography align='center' sx={{margin: '24px 0', color: 'rgba(0, 0, 0, 0.60)'}}>
                                    Or log in with
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Button sx={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.20)', borderRadius: '32px', color: 'black'}}>
                                            <Google />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button sx={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.20)', borderRadius: '32px', color: 'black'}}>
                                            <Apple />
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/* <Copyright sx={{ mt: 5 }} /> */}
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', borderTop: '1px solid rgba(0, 0, 0, 0.10)', padding: '16px', width: '100%'}}>
                            <Box sx={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                                <Typography sx={{color: 'rgba(0,0,0,.6)', fontSize: '12px'}}>
                                    © 2023 DigiArt Forever
                                </Typography>
                                <Typography sx={{fontSize: '12px', fontWeight: 500}}>
                                    Terms
                                </Typography>
                                <Typography sx={{fontSize: '12px', fontWeight: 500}}>
                                    Privacy
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <a href='https://www.instagram.com/rrhpub/' target='_blank'
                                   style={{ minWidth: '32px', marginTop: '5px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 24 24"
                                         fill="none">
                                        <g clip-path="url(#clip0_115_5902)">
                                            <path
                                                d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                                                fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_115_5902">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                                <a href='https://www.artstation.com/redrighthandstudios6' target='_blank'
                                   style={{ minWidth: '32px', marginTop: '5px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 24 20"
                                         fill="none">
                                        <path
                                            d="M0.728516 15.4221L2.63566 18.7221C3.02137 19.4721 3.7928 19.9864 4.67137 19.9864H17.3785L14.7428 15.4221H0.728516Z"
                                            fill="black" />
                                        <path
                                            d="M23.3997 15.4434C23.3997 14.9934 23.2711 14.5648 23.0354 14.2005L15.5782 1.25768C15.1925 0.529112 14.4425 0.0362549 13.564 0.0362549H9.62109L21.1282 19.9648L22.9497 16.8148C23.2925 16.2148 23.3997 15.9577 23.3997 15.4434Z"
                                            fill="black" />
                                        <path d="M12.8785 12.1647L7.75711 3.27185L2.61426 12.1647H12.8785Z"
                                              fill="black" />
                                    </svg>
                                </a>
                                <a href='#' style={{ minWidth: '32px', marginTop: '5px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 22 24"
                                         fill="none">
                                        <path
                                            d="M8.88806 10.068C8.20406 10.068 7.66406 10.668 7.66406 11.4C7.66406 12.132 8.21606 12.732 8.88806 12.732C9.57206 12.732 10.1121 12.132 10.1121 11.4C10.1241 10.668 9.57206 10.068 8.88806 10.068ZM13.2681 10.068C12.5841 10.068 12.0441 10.668 12.0441 11.4C12.0441 12.132 12.5961 12.732 13.2681 12.732C13.9521 12.732 14.4921 12.132 14.4921 11.4C14.4921 10.668 13.9521 10.068 13.2681 10.068Z"
                                            fill="black" />
                                        <path
                                            d="M19.1005 0H3.02055C1.66455 0 0.560547 1.104 0.560547 2.472V18.696C0.560547 20.064 1.66455 21.168 3.02055 21.168H16.6285L15.9925 18.948L17.5285 20.376L18.9805 21.72L21.5605 24V2.472C21.5605 1.104 20.4565 0 19.1005 0ZM14.4685 15.672C14.4685 15.672 14.0365 15.156 13.6765 14.7C15.2485 14.256 15.8485 13.272 15.8485 13.272C15.3565 13.596 14.8885 13.824 14.4685 13.98C13.8685 14.232 13.2925 14.4 12.7285 14.496C11.5765 14.712 10.5205 14.652 9.62055 14.484C8.93655 14.352 8.34855 14.16 7.85655 13.968C7.58055 13.86 7.28055 13.728 6.98055 13.56C6.94455 13.536 6.90855 13.524 6.87255 13.5C6.84855 13.488 6.83655 13.476 6.82455 13.464C6.60855 13.344 6.48855 13.26 6.48855 13.26C6.48855 13.26 7.06455 14.22 8.58855 14.676C8.22855 15.132 7.78455 15.672 7.78455 15.672C5.13255 15.588 4.12455 13.848 4.12455 13.848C4.12455 9.984 5.85255 6.852 5.85255 6.852C7.58055 5.556 9.22455 5.592 9.22455 5.592L9.34455 5.736C7.18455 6.36 6.18855 7.308 6.18855 7.308C6.18855 7.308 6.45255 7.164 6.89655 6.96C8.18055 6.396 9.20055 6.24 9.62055 6.204C9.69255 6.192 9.75255 6.18 9.82455 6.18C10.5565 6.084 11.3845 6.06 12.2485 6.156C13.3885 6.288 14.6125 6.624 15.8605 7.308C15.8605 7.308 14.9125 6.408 12.8725 5.784L13.0405 5.592C13.0405 5.592 14.6845 5.556 16.4125 6.852C16.4125 6.852 18.1405 9.984 18.1405 13.848C18.1405 13.848 17.1205 15.588 14.4685 15.672Z"
                                            fill="black" />
                                    </svg>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </SuiBox>
            </SuiBox>
        </ThemeProvider>
    )
}
export default SignInSide
