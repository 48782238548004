import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SuiBox from 'components/SuiBox'

import WidgetLandingLayout from 'examples/LayoutContainers/WidgetLandingLayout'

import useApi from 'hooks/useApi'

import { Box, Button, CircularProgress, Skeleton, useMediaQuery } from '@mui/material'
import ContestComponent from 'components/ContestComponent'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useProfile } from 'context/profile'
import { mediaUrl } from 'utils/constant'

function Contest() {
    const { id } = useParams()
    const [contestData, getContestData] = useApi()
    const [joinedContest, joinToContestApi] = useApi()
    const [joinedState, setJoinedState] = useState(false)
    const [profile, getProfileEffect] = useProfile()

    const { enqueueSnackbar } = useSnackbar()
    const isDownMd = useMediaQuery('(max-width: 900px)')

    useEffect(() => {
        getContestData(`/contests/contest/${id}/`)
    }, [])

    const joinToContest = (url) => {
        // try {
        //     joinToContestApi(`/contests/join-to-contest/${id}/`)
        //     enqueueSnackbar('Joined successfully!', {
        //         variant: 'success',
        //     })
        //     setJoinedState(true)
        // } catch (err) {
        //     enqueueSnackbar('Error join to contest', {
        //         variant: 'error',
        //     })
        // }
        if(url) {
            window.open(url, '_blank');
        }
    }

    const contest = contestData?.data?.data;
    const isEnded = contest?.end_at ? (moment(contest?.end_at).diff(Date.now(), 'milliseconds') <= 0) : false

    useEffect(() => {
        if (contest) {
            console.log(contest?.user, profile?.data?.data)
            setJoinedState(contest?.user?.filter(e => e.email === profile?.data?.data?.email).length > 0)
        }
    }, [contest])

    return (
        <WidgetLandingLayout>
            <Box height="300px" display="flex" alignItems="center" justifyContent="center" paddingTop="80px" style={{
                background: `url(${contest?.link?.replace(
                    mediaUrl,
                    'https://digiartforever.com'
                )})`, backgroundSize: "cover", backgroundPosition: "center"
            }}>
                <SuiBox
                    sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                        position: 'relative',
                        maxWidth: '1350px',
                        display: 'flex',
                        justifyContent: isDownMd ? 'center' : 'space-between',
                        flex: 1,
                        flexDirection: isDownMd ? 'column' : 'row',
                        margin: '0 30px',
                        alignItems: 'center',

                        [breakpoints.up('xl')]: {
                            transition: transitions.create(['margin-left', 'margin-right'], {
                                easing: transitions.easing.easeInOut,
                                duration: transitions.duration.standard,
                            }),
                        },
                    })}
                >
                    <Typography fontSize={32} fontWeight={700} style={{ color: 'white' }} align="center">
                        {contest?.title}
                    </Typography>
                    {contest && profile && ((profile?.data?.data?.is_active || profile?.data?.data?.is_superuser) ? (
                    // joinedState ? <Button style={{ minWidth: '150px', margin: '16px 0', background: "white", borderRadius: "40px", color: "black", padding: "12px 24px", display: "flex", alignItems: 'center' }} disabled>
                    //     JOINED
                    // </Button> : 
                    <Button style={{ minWidth: '180px', margin: '16px 0', background: isEnded ? "white" : "#436AF5", borderRadius: "40px", color: isEnded ? "black" : "white", padding: "12px 24px", display: "flex", alignItems: 'center' }} onClick={() => joinToContest(contest.google_form_url)} disabled={isEnded}>
                        JOIN NOW
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "4px" }}>
                            <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke={isEnded ? "black" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Button>) : <Link to={isEnded ? "#" : "/subscription"}>
                        <Button style={{ minWidth: '180px', margin: '16px 0', background: isEnded ? "white" : "#436AF5", borderRadius: "40px", color: isEnded ? "black" : "white", padding: "12px 24px", display: "flex", alignItems: 'center' }} disabled={isEnded}>
                            SUBSCRIBE TO JOIN
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "4px" }}>
                                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke={isEnded ? "black" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Button>
                    </Link>)
                    }
                </SuiBox>
            </Box>
            <SuiBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    position: 'relative',
                    maxWidth: '1350px',
                    margin: 'auto',
                    padding: '30px',
                    display: 'flex',
                    gap: "30px",
                    flexDirection: isDownMd ? 'column' : 'row',

                    [breakpoints.up('xl')]: {
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                <Box width="300px">
                    <Typography fontSize={27} fontWeight={700} style={{ color: 'black', marginBottom: '12px' }}>
                        Duration
                    </Typography>
                    <Box display="flex" marginBottom={3} alignItems="center" gap={isDownMd ? "12px" : "12px"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        {!isEnded && <Box>
                            <Typography fontSize={isDownMd ? 10 : 10} fontWeight={500} textTransform="uppercase" style={{ color: 'black' }} >STARTS</Typography>
                            <Typography fontSize={isDownMd ? 15 : 14} fontWeight={700} style={{ color: 'black' }}>{
                                moment(contest?.start_at).format("MMM DD, YYYY")
                            }</Typography>
                        </Box>}
                        <Box>
                            <Typography fontSize={isDownMd ? 10 : 10} fontWeight={500} textTransform="uppercase" style={{ color: 'black' }} >{isEnded ? 'ENDED' : 'ENDS'}</Typography>
                            <Typography fontSize={isDownMd ? 15 : 14} fontWeight={700} style={{ color: 'black' }}>{
                                moment(contest?.end_at).format("MMM DD, YYYY")
                            }</Typography>
                        </Box>
                    </Box>
                    <Typography fontSize={27} fontWeight={700} style={{ color: 'black', marginBottom: '12px' }}>
                        Prizes
                    </Typography>
                    <Box display="flex" alignItems="start" gap="12px" flexDirection='column' width="100%">
                        {contest?.prizes?.map((prize, index) => (
                            <Box width="100%" borderRadius="8px" border="1px solid rgba(0, 0, 0, 0.20)">
                                <Box padding="12px 16px">
                                    <Typography fontSize={20} fontWeight={400} style={{ color: 'black' }}>{prize.title}</Typography>
                                    <Typography fontSize={10} fontWeight={700} style={{ color: '#436AF5' }}>{prize.type.title}</Typography>
                                </Box>
                                {prize.user?.[0] && <Box style={{ padding: '8px 16px', borderTop: "1px solid rgba(0, 0, 0, 0.20)" }}>
                                    <Typography fontSize={12} fontWeight={700} style={{ color: 'black' }}>{prize.user?.[0]?.business_name}</Typography>
                                </Box>}
                            </Box>
                        ))}
                    </Box>
                    <Box heigh='300px' />
                </Box>
                <Box maxWidth="625px">
                    {contest?.contest_challenge && <Box>
                        <Typography fontSize={32} fontWeight={700} style={{ color: 'black' }}>
                            Contest Challenge
                        </Typography>
                        <Typography fontSize={16} fontWeight={400} style={{ color: 'black', margin: "16px 0" }}>
                            {contest?.contest_challenge}
                        </Typography>
                    </Box>}
                    {contest?.rules && <Box>
                        <Typography fontSize={32} fontWeight={700} style={{ color: 'black' }}>
                            Rules
                        </Typography>
                        <Typography fontSize={16} fontWeight={400} style={{ color: 'black', margin: "16px 0" }}>
                            {contest?.rules}
                        </Typography>
                    </Box>}
                    {contest?.how_to_submit && <Box>
                        <Typography fontSize={32} fontWeight={700} style={{ color: 'black' }}>
                            How to submit?
                        </Typography>
                        <Typography fontSize={16} fontWeight={400} style={{ color: 'black', margin: "16px 0" }}>
                            {contest?.how_to_submit}
                        </Typography>
                    </Box>}
                </Box>
            </SuiBox>
        </WidgetLandingLayout>
    )
}

export default Contest
