import * as React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { MenuItem, Select } from '@mui/material'

export default function SelectedListItem(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    const handleListItemClick = (uid, index) => {
        setSelectedIndex(index)
        if (props.setSketchfabUID) {
            props.setSketchfabUID(uid)
            console.log(uid)
        }
    }

    React.useEffect(() => {
        if (!selectedIndex) {
            if (props.value) {
                setSelectedIndex(props.items?.findIndex((item) => item.value === props.value))
                props.setSketchfabUID(props.value)
            }
            else {
                props.setSketchfabUID(props.items?.[0]?.value)
            }
        }
    }, [props])

    return props.items?.length ? (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Typography style={{ color: 'white', fontSize: '14px', marginBottom: '8px' }}>
                Your assets
            </Typography>
            <Select sx={{
                background: '#383E45 !important',
                borderRadius: '4px !important',
                color: 'white !important',
                border: 'none !important',
                paddingRight: '12px !important',
                '& .MuiSelect-select': {
                    width: "100% !important",
                    paddingLeft: '4px !important',
                },
                '& svg': {
                    display: "block",
                }
            }}
                IconComponent={() => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M6 9L12 15L18 9" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}
                value={selectedIndex}
                onChange={(e) => handleListItemClick(props.items?.[e.target.value]?.value, e.target.value)}
            >
                {props.items?.map((item, index) => <MenuItem value={index}>{item.name}</MenuItem>)}
            </Select>
        </Box>
    ) : null;
}
