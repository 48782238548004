import Slider from '../Slider'

export default () => {
    return (
        <>
            <div
                style={{
                    marginLeft: -200,
                    marginTop: 50,
                    borderRadius: 10,
                    boxShadow: '0 0 100px #bdbdbd',
                }}
            >
                <Slider />
            </div>
        </>
    )
}
